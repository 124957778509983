import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useChartDimensions} from './utils';
import {useSimulationData} from '../../utils/useSimulationData';


const EventTab = ({children, span}) => {
    const isMobileSize = useMediaQuery('(max-width: 960px)');
    const {xAxisPaddingLeft, xAxisPaddingRight} = useChartDimensions();
    const gap = 2;
    const chartOffset = xAxisPaddingRight + xAxisPaddingLeft;
    return (
        <Box
            sx={{
                'width':
                    span === 'auto'
                        ? 'auto'
                        : `calc(${span}/12 * (100% - ${chartOffset}px) - ${gap}px)`,
                'flexGrow': span === 'auto' ? 1 : 0,
                'display': span === 0 ? 'none' : 'flex',
                'alignItems': 'center',
                'justifyContent': 'center',
                'textAlign': 'center',
                'py': 1,
                'backgroundColor': 'v2.amber.50',
                'fontSize': theme => theme.typography.pxToRem(10),
                'fontFamily': 'Barlow',
                'fontWeight': isMobileSize ? 500 : 700,
                'borderRadius': '4px',
                'letterSpacing': theme => theme.typography.pxToRem(0.4),
                '&:first-child': {
                    width: `calc(1/12 * (100% - ${chartOffset}px)/2 + ${xAxisPaddingLeft}px - ${gap}px)`,
                    backgroundColor: 'v2.blueGray.50',
                    borderBottomLeftRadius: isMobileSize ? '8px' : '16px',
                },
                '&:last-child': {
                    borderBottomRightRadius: isMobileSize ? '8px' : '16px',
                },
            }}
        >
            {children}
        </Box>
    );
};

EventTab.propTypes = {
    span: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export const EventTabs = () => {
    const isMobileSize = useMediaQuery('(max-width: 960px)');
    const simulation = useSimulationData();
    const {t} = useTranslation('revenueSimulator');
    const months = simulation.simulationInput.professionalInfo.monthsToRestoreTravelSubvention || 0;
    const shouldRenderPortage = !(isMobileSize && months === 1);

    return (
        <Box sx={{display: 'flex', gap: '2px', mt: isMobileSize ? '8px' : '2px', width: '100%'}}>
            <EventTab span={1}>{t('roadmap.CDI')}</EventTab>
            {shouldRenderPortage && <EventTab span={months}>{t('roadmap.portageSalarial')}</EventTab>}
            <EventTab span="auto">{t('roadmap.freelancing')}</EventTab>
        </Box>
    );
};
