import EuroIcon from '@mui/icons-material/Euro';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import {Box, useTheme} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {roadmapStepsBgColor, useChartDimensions} from './utils';
import {EurAmount} from '../../../../../../components/eur-amount/EurAmount';
import {useSimulationData} from '../../utils/useSimulationData';

const RoadmapStep = ({position, Icon, children}) => {
    const isMobileSize = useMediaQuery('(max-width: 960px)');
    const {pxToRem} = useTheme().typography;
    return (
        <Box
            sx={{
                'backgroundColor': 'white',
                'px': isMobileSize ? 0 : 1,
                'position': 'absolute',
                'left': (position / 12) * 100 + '%',
                'top': '50%',
                'transform': 'translateY(-50%) translateX(-50%)',
                'whiteSpace': 'nowrap',
                // roadmapStepsBgColor has opacity, adding white background behind makes it opaque
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: isMobileSize ? 'transparent' : roadmapStepsBgColor,
                },
            }}
        >
            <Box textAlign="center" fontSize={pxToRem(10)} lineHeight={1.2}>
                <Icon color="primary" sx={{fontSize: pxToRem(isMobileSize ? 16 : 20)}} />
                {!isMobileSize && <div>{children}</div>}
            </Box>
        </Box>
    );
};

RoadmapStep.propTypes = {
    position: PropTypes.number.isRequired,
    Icon: PropTypes.elementType.isRequired,
};

export const RoadmapSteps = () => {
    const {t} = useTranslation('revenueSimulator');
    const isMobileSize = useMediaQuery('(max-width: 960px)');
    const simulation = useSimulationData();
    const {stepsOffsetLeft, stepsOffsetRight} = useChartDimensions();
    const ARCE = simulation.simulationOutput.ACRE;

    const months = simulation.simulationInput.professionalInfo.monthsToRestoreTravelSubvention || 0;
    const revenuePercent = simulation.simulationOutput.revenueProjectionPercentAsFreelancer;

    return (
        <Box
            sx={{
                backgroundColor: isMobileSize ? 'transparent' : roadmapStepsBgColor,
                borderRadius: '8px',
                py: 1,
                px: 2,
            }}
        >
            <Box
                sx={{
                    position: 'relative',
                    height: theme => (isMobileSize ? 0 : theme.typography.pxToRem(50)),
                }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        borderBottom: isMobileSize ? 'none' : '1px solid',
                        borderBottomColor: 'v2.blueGray.100',
                        width: '100%',
                    }}
                />
                <Box sx={{position: 'absolute', height: '100%', left: stepsOffsetLeft, right: stepsOffsetRight}}>
                    {months > 0 && (
                        <RoadmapStep position={1} Icon={ShowChartIcon}>
                            {revenuePercent}% <br />
                            {t('roadmap.income')}
                        </RoadmapStep>
                    )}
                    <RoadmapStep position={1 + months} Icon={RocketLaunchIcon}>
                        <Trans t={t} i18nKey="roadmap.companyCreation.desktop" />
                    </RoadmapStep>
                    <RoadmapStep position={2 + months} Icon={EuroIcon}>
                        {t('roadmap.arce')} #1 <br />
                        <strong>
                            <EurAmount amount={ARCE / 2} />
                        </strong>
                    </RoadmapStep>
                    <RoadmapStep position={8 + months} Icon={EuroIcon}>
                        {t('roadmap.arce')} #2 <br />
                        <strong>
                            <EurAmount amount={ARCE / 2} />
                        </strong>
                    </RoadmapStep>
                </Box>
            </Box>
        </Box>
    );
};
