import {createSelector} from 'reselect';

const selectFileLoader = state => state.accounting.fileLoader;
const selectJournalAccounts = state => state.accounting.journalAccounts;
const selectFinancialStatements = state => state.accounting.financialStatements;
const selectBalanceSheetDetails = state => state.accounting.balanceSheetDetails;
const selectDocumentGenerationProgres = state => state.accounting.documentGenerationProgress;
const selectAllDocumentsGenerationProgress = state => state.accounting.allDocumentsGenerationProgress;
const selectINPISubmission = state => state.accounting.inpiSubmissionDetails;

const createFileLoaderSelectorByEventId = eventId => createSelector(
    [selectFileLoader],
    data => (data.find(el => el?.eventId === eventId)),
);

export const AccountingSelector = {
    selectFileLoader,
    createFileLoaderSelectorByEventId,
    selectJournalAccounts,
    selectFinancialStatements,
    selectBalanceSheetDetails,
    selectDocumentGenerationProgres,
    selectAllDocumentsGenerationProgress,
    selectINPISubmission,
};
