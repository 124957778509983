import Amplify, {Auth} from 'aws-amplify';
import {call} from 'redux-saga/effects';
import {CONFIG} from '../../../config';

export const initializeAuth = function* () {
    yield call([Amplify, Amplify.configure], {
        Auth: {
            mandatorySignIn: true,
            region: CONFIG.AWS_AMPLIFY.COGNITO.REGION,
            userPoolId: CONFIG.AWS_AMPLIFY.COGNITO.USER_POOL_ID,
            userPoolWebClientId: CONFIG.AWS_AMPLIFY.COGNITO.CLIENT_ID,
        },
    });
};

export const getIsLoggedIn = function* () {
    let isUserLoggedIn = false;

    try {
        const session = yield call([Auth, Auth.currentSession]);

        isUserLoggedIn = session && !!session.getIdToken().getJwtToken();
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return isUserLoggedIn;
};
