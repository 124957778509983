import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import React from 'react';

import {ButtonIcon} from './ButtonIcon';

const CloseButton = ({onClose, isDisabled, buttonStyles}) => (
    <ButtonIcon
        icon={<CloseIcon fontSize="large" />}
        onClick={onClose}
        sx={{
            'color': 'text_v2.primary',
            'backgroundColor': '#fff',
            'borderRadius': '8px',
            'zIndex': 1,
            '&:hover': {
                backgroundColor: '#fff',
                color: 'primary.main',
            },
            ...buttonStyles,
        }}
        disabled={isDisabled}
    />
);

CloseButton.propTypes = {
    onClose: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool,
    buttonStyles: PropTypes.objectOf(PropTypes.any),
};

CloseButton.defaultProps = {
    isDisabled: false,
    buttonStyles: {},
};

export default CloseButton;
