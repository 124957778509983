import moment from 'moment';
import {fromContactInfoDTO, toContactInfoDTO} from '../../user/api/user.dto';

export const fromUserDTO = userDTO => ({
    id: userDTO.id,
    key: userDTO?.id,
    firstName: userDTO.first_name,
    lastName: userDTO.last_name,
    fullName: `${userDTO.first_name} ${userDTO.last_name}`,
    email: userDTO.email,
    role: userDTO.role,
    status: userDTO.status,
    phone: userDTO.phone,
    active: !!userDTO.active,
    coachId: userDTO.coach_id,
    coachingInfo: userDTO.coaching_info,
    isFullyActive: userDTO.is_fully_active,
    fullCoachName: userDTO.coach_first_name ? `${userDTO.coach_first_name} ${userDTO.coach_last_name}` : null,
    hasBankAccess: userDTO?.additional_info?.can_access_bank_feature ?? false,
    contactInfo: fromContactInfoDTO(userDTO.contact_info),
});

export const toPayCalculationInfoDTO = PayCalculationInfo => ({
    calculation_type: PayCalculationInfo.calculationType,
    can_show_pay_calculation_value: PayCalculationInfo.canShowPayCalculationValue,
    last_calculation_requested_date: PayCalculationInfo.lastCalculationRequestedDate,
    last_delayed_calculation_request_date: PayCalculationInfo.lastDelayedCalculationRequestDate,
});

export const toUserDTO = userDTO => ({
    id: userDTO.id,
    first_name: userDTO.firstName,
    last_name: userDTO.lastName,
    email: userDTO.email,
    role: userDTO.role,
    status: userDTO.status,
    phone: userDTO.phone,
    ...(userDTO?.contactInfo?.address && {contact_info: toContactInfoDTO(userDTO.contactInfo)}),
    propagate_address_change: userDTO.propagateAddressChange,
});


export const transformUsersFromDTO = userDTOs => {
    const users = {};

    if (!userDTOs) {
        return users;
    }

    userDTOs.forEach(userDTO => {
        users[userDTO.id] = fromUserDTO(userDTO);
    });

    return users;
};

export const fromFreelancerDTO = freelancerDTO => ({
    id: freelancerDTO.id,
    firstName: freelancerDTO.first_name,
    lastName: freelancerDTO.last_name,
});

export const transformFreelancersFromDTO = freelancerDTOs => {
    const freelancers = {};

    if (!freelancerDTOs) {
        return freelancers;
    }

    freelancerDTOs.forEach(freelancerDTO => {
        freelancers[freelancerDTO.id] = fromFreelancerDTO(freelancerDTO);
    });

    return freelancers;
};

export const transformCoachingInfo = dto => {
    return {
        coach: dto.coaching_info?.coach_id ? {
            id: dto.coaching_info.coach_id,
        } : null,
        jobType: dto.coaching_info?.professions?.[0]?.job_type || undefined,
        contactSource: dto.coaching_info?.contact_source || undefined,
        startContractType: dto.coaching_info?.contract_start_type || undefined,
        lastDayOfWork: dto.coaching_info?.availability_date
            ? moment(dto.coaching_info.availability_date, 'DD/MM/YYYY')
            : null,
        contractStatus: dto.coaching_info?.contract_status || undefined,
        registrationDate: dto.coaching_info?.job_center_registration_date
            ? moment(dto.coaching_info.job_center_registration_date, 'DD/MM/YYYY')
            : null,
        estimatedRegistrationDate: dto.coaching_info?.expected_registration_date
            ? moment(dto.coaching_info.expected_registration_date, 'DD/MM/YYYY')
            : null,
        city: dto.coaching_info?.city_of_residence || undefined,
        note: dto.coaching_info?.comment || undefined,
    };
};

export const transformToCoachingInfoDTO = dto => {
    return {
        coach_id: dto.coach?.id || undefined,
        professions: dto.jobType ? [{
            job_type: dto.jobType,
            job_specializations: dto?.isCareUser ? undefined : [],
        }] : undefined,
        contact_source: dto.contactSource || undefined,
        contract_start_type: dto.startContractType || undefined,
        availability_date: dto.lastDayOfWork
            ? moment(dto.lastDayOfWork)
                .format('DD/MM/YYYY')
            : undefined,
        contract_status: dto.contractStatus || undefined,
        job_center_registration_date: dto.registrationDate
            ? moment(dto.registrationDate)
                .format('DD/MM/YYYY')
            : undefined,
        expected_registration_date: dto.estimatedRegistrationDate
            ? moment(dto.estimatedRegistrationDate)
                .format('DD/MM/YYYY')
            : undefined,
        city_of_residence: dto.city || undefined,
        comment: dto.note || undefined,
        offer_choice: dto.offerChoice || undefined, // Only for CARE users
    };
};
