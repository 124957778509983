import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import {UserActionTypes} from './user.action-type';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {USER_ROLES} from '../../../utils/user-roles';
import {storeUser} from '../../../v1/app/auth/auth.actions';
import {selectUser} from '../../../v1/app/auth/auth.selectors';
import {UserApi} from '../api/user.api';
import {createUserFormSaga} from '../modules/create-user-form';
import {LoggedInUserSelectors, loggedInUserSaga} from '../modules/logged-in-user';

const submitAdminUpdatesUsersPersonalAddressWorker = function* ({payload}) {
    try {
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const isAdmin = loggedInUser.role === USER_ROLES.ADMINISTRATOR;
        if (!isAdmin) {
            Toast.error('genericError');
            return;
        }

        const {userId, contactInfo} = payload;
        const user = yield select(selectUser);
        yield call(UserApi.putContactInfo, {userId, data: contactInfo});
        yield put(storeUser({
            ...user,
            contactInfo,
        }));
    } catch (e) {
        Debug.error('user', 'Error: ', {e});
        Toast.error('genericError');
    }
};

export const userSaga = function* () {
    yield all([
        takeEvery(UserActionTypes.ADMIN_UPDATES_USERS_PERSONAL_ADDRESS, submitAdminUpdatesUsersPersonalAddressWorker),
        fork(createUserFormSaga),
        fork(loggedInUserSaga),
    ]);
};
