import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

export const Title = ({children}) => {
    const isMobileSize = useMediaQuery('(max-width: 960px)');

    return (
        <Box
            sx={{
                fontWeight: 700,
                fontSize: theme => theme.typography.pxToRem(34),
                px: isMobileSize ? 3 : 0,
                mt: isMobileSize ? 3 : 0,
                mb: isMobileSize ? 3 : 4,
            }}
        >
            {children}
        </Box>
    );
};

