import LinkIcon from '@mui/icons-material/Link';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import {CTASection} from './common/CTASection';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {Toast} from '../../../../../lib/toast';
import {resolveRoute} from '../../../../../v1/navigation/resolveRoute';
import {useSimulationData} from '../utils/useSimulationData';

export const LinkSection = () => {
    const simulation = useSimulationData();
    const {t} = useTranslation('revenueSimulator');

    const handleCopyClick = () => {
        const relativeURL = resolveRoute(RoutePaths.REVENUE_SIMULATOR_RESULTS_PUBLIC, {simulationId: simulation.id});
        const absoluteURL = window.location.origin + relativeURL;
        navigator.clipboard
            .writeText(absoluteURL)
            .then(() => {
                Toast.success('copiedToClipboard');
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    return (
        <CTASection
            title={t('linkSection.title')}
            heading={t('linkSection.heading')}
            description={t('linkSection.description')}
            button={(
                <Button
                    color="primary_v2"
                    onClick={handleCopyClick}
                    variant="contained"
                    startIcon={<LinkIcon />}
                    sx={{px: 2, py: 1, lineHeight: 1, borderRadius: '8px'}}
                >
                    {t('linkSection.buttonText')}
                </Button>
              )}
        />
    );
};
