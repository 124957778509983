import React from 'react';
import {useSelector} from 'react-redux';
import {LoggedInUserSelectors} from '../../../user/modules/logged-in-user';
import {AdministratorSidebarCareNavigation, AdministratorSidebarNavigation} from '../../utils/constants';
import CommonSidebar from '../Common/CommonSidebar';
import {Search} from '../Common/Search';
import {SegmentSwitch} from '../Common/SegmentSwitch';
import {UserRoles} from "../../../../utils/user-roles";
import {SidebarSelectors} from "../../store/sidebar.selector";

export const AdministratorSidebar = () => {
    const user = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const selectedRole = useSelector(SidebarSelectors.selectSelectedRole);

    if (!user) {
        return;
    }

    const sidebarData = selectedRole === UserRoles.FREELANCER
        ? AdministratorSidebarNavigation
        : AdministratorSidebarCareNavigation;

    return (
        <>
            <CommonSidebar
                user={user}
                sidebarData={sidebarData}
                sx={{pb: 4}}
                SearchComponent={(<Search />)}
                SegmentSwitchComponent={(<SegmentSwitch />)}
            />
        </>
    );
};
