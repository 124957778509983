export const INPI_SUBMISSION_STATUS = {
    NOT_STARTED: 'NOT_STARTED',
    SUBMITTED: 'SUBMITTED',
    SIGNATURE_PENDING: 'SIGNATURE_PENDING',
    SIGNED: 'SIGNED',
    PAYMENT_PENDING: 'PAYMENT_PENDING',
    PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
    VALIDATION_PENDING: 'VALIDATION_PENDING',
    AMENDMENT_PENDING: 'AMENDMENT_PENDING',
    VALIDATED: 'VALIDATED',
    REJECTED: 'REJECTED',
    EXPIRED: 'EXPIRED',
    ERROR: 'ERROR',
};
