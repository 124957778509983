import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {LoadingButton} from '@mui/lab';
import {Alert, Box, Divider, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {Toast} from '../../../../../lib/toast';
import {copyToClipboard} from '../../../../../utils/copy-to-clipboard';
import {USER_ROLES} from '../../../../../utils/user-roles';
import {selectCompany} from '../../../../../v1/app/company/company.selectors';
import logoImage from '../../../../../v1/assets/images/logo.svg';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import ArchivingSidebarActions from '../../../components/archive/ArchivingSidebarActions';
import {BankActions} from '../../../store/bank.action';
import {BridgeActions} from '../../bridge-api/store/bridge-api.action';
import {BANK_TYPES, BankIntegrationStatus} from '../../bridge-api/utils/constants';
import {BankCardMenuItem} from '../../cards/components/details/BankCardMenuItem';

const BankDetails = ({isMobileSize, integration}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const isHiway = integration?.type === BANK_TYPES.hiway;

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const isUserAdmin = loggedInUser?.role === USER_ROLES.ADMINISTRATOR;
    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.DOWNLOAD_DOCUMENT,
    ));
    // For admin user
    const freelancerCompany = useSelector(selectCompany);

    const downloadRib = () => {
        dispatch(BankActions.downloadRib({
            freelancerId: isUserAdmin
                ? freelancerCompany?.userId
                : loggedInUser.id,
            companyId: isUserAdmin
                ? freelancerCompany?.id
                : loggedInUser.defaultCompanyId,
            integration,
        }));
    };

    const fixAccount = () => {
        dispatch(BridgeActions.fixBridgeAccount({status: integration?.status, id: integration?.id}));
    };

    const hasError = integration?.status === BankIntegrationStatus.COMPANY_VALIDATION_FORM_REQUIRED
        || integration?.status === BankIntegrationStatus.MULTI_FACTOR_AUTHENTICATION_REQUIRED
        || integration?.status === BankIntegrationStatus.USER_ACTION_REQUIRED
        || integration?.status === BankIntegrationStatus.ERROR
        || integration?.status === BankIntegrationStatus.IN_PROGRESS;

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: isMobileSize ? 'calc(100% - 64px)' : '100vh',
        }}
        >
            <Box sx={{px: 2}}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    py: 3,
                    px: 2,
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 2,
                        flexDirection: 'column',
                    }}
                    >
                        {isHiway ? (
                            <img
                                alt="Logo"
                                src={logoImage}
                                width="64px"
                                height="64px"
                            />
                        ) : (
                            <AccountBalanceTwoToneIcon sx={{
                                fontSize: '32px',
                            }}
                            />
                        )}

                        <Typography sx={{
                            fontWeight: 500,
                        }}
                        >
                            {integration?.name ?? t('accountSettingsSidebar.hiwayPro')}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    {!isHiway && hasError && (
                        <Alert
                            color="warning"
                            severity="warning"
                            sx={{
                                mb: 2,
                            }}
                            icon={(
                                <WarningAmberIcon
                                    sx={{
                                        color: 'warning_v2.text',
                                    }}
                                />
                            )}
                        >
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                            }}
                            >
                                <Typography sx={{fontWeight: 700}}>
                                    {t('accountSettingsSidebar.alertTitle')}
                                </Typography>

                                <Typography sx={{
                                    fontSize: theme => theme.typography.pxToRem(14),
                                }}
                                >
                                    {t('accountSettingsSidebar.alertText1')}
                                </Typography>

                                <Typography sx={{
                                    fontSize: theme => theme.typography.pxToRem(14),
                                }}
                                >
                                    {t('accountSettingsSidebar.alertText2')}
                                </Typography>

                                <Typography sx={{
                                    fontSize: theme => theme.typography.pxToRem(14),
                                }}
                                >
                                    {t('accountSettingsSidebar.alertText3')}
                                </Typography>
                            </Box>
                        </Alert>
                    )}

                    {isHiway && (
                        <Box sx={{
                            backgroundColor: 'rgba(25, 118, 210, 0.04)',
                            borderRight: '8px',
                            py: 0.5,
                            display: 'flex',
                            justifyContent: 'center',
                            color: 'primary_v2.main',
                            fontWeight: '500',
                            mb: 2,
                            borderRadius: '8px',
                            alignItems: 'center',
                            gap: 0.5,
                        }}
                        >
                            <CloudDoneIcon />
                            {t('accountSettingsSidebar.instantSync')}
                        </Box>
                    )}

                    <Box sx={{
                        borderRadius: '16px',
                        backgroundColor: '#FAFAFA',
                        px: 3,
                        py: 2,
                    }}
                    >
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        >
                            <Typography sx={{
                                color: 'rgba(0, 0, 0, 0.38)',
                            }}
                            >
                                {t('accountSettingsSidebar.IBAN')}
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                            >
                                <Typography>{integration?.iban}</Typography>
                                {integration?.iban && (
                                    <ContentCopyIcon
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'v2.light.action.active',
                                        }}
                                        onClick={() => {
                                            copyToClipboard(integration?.iban);
                                            Toast.success('copiedToClipboard');
                                        }}
                                    />
                                )}
                            </Box>
                            <Divider sx={{my: 2}} />
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        >
                            <Typography sx={{
                                color: 'rgba(0, 0, 0, 0.38)',
                            }}
                            >
                                {t('accountSettingsSidebar.BIC')}
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                            >
                                <Typography>{integration?.bic}</Typography>
                                {integration?.bic && (
                                    <ContentCopyIcon
                                        sx={{
                                            cursor: 'pointer',
                                            color: 'v2.light.action.active',
                                        }}
                                        onClick={() => {
                                            copyToClipboard(integration?.bic);
                                            Toast.success('copiedToClipboard');
                                        }}
                                    />
                                )}
                            </Box>
                        </Box>

                        {isHiway && (
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '100%',
                                flexDirection: 'column',
                                gap: 2,
                                mb: 1,
                                mt: 3,
                            }}
                            >
                                <LoadingButton
                                    onClick={downloadRib}
                                    variant="contained"
                                    color="primary"
                                    fullWidth={true}
                                    startIcon={<DownloadIcon />}
                                    loading={isLoading}
                                    loadingPosition="start"
                                    size="small"
                                    sx={{p: 0.75}}
                                >
                                    {t('accountSettingsSidebar.downloadButton')}
                                </LoadingButton>
                                {!isUserAdmin && (
                                    <LoadingButton
                                        component={Link}
                                        to={RoutePaths.BANK_RIB_AND_DOCUMENTS}
                                        variant="outlined"
                                        fullWidth={true}
                                        startIcon={<TextSnippetIcon />}
                                        loadingPosition="start"
                                        size="small"
                                        sx={{p: 0.75}}
                                    >
                                        {t('accountSettingsSidebar.goToRibPageButton')}
                                    </LoadingButton>
                                )}
                            </Box>
                        )}
                    </Box>
                    {!isHiway && hasError && (
                        <BankCardMenuItem
                            onClick={() => fixAccount()}
                            text={t('accountSettingsSidebar.reConnect')}
                            icon={<AutorenewIcon />}
                        />
                    )}
                </Box>
            </Box>

            <ArchivingSidebarActions
                isHiway={isHiway}
                integration={integration}
            />
        </Box>
    );
};

BankDetails.propTypes = {
    isMobileSize: PropTypes.bool.isRequired,
    integration: PropTypes.object.isRequired,
};

export default BankDetails;
