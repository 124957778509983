import {Box, useTheme} from '@mui/material';
import Paper from '@mui/material/Paper';
import merge from 'lodash.merge';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import {EurAmount} from '../../../../../../components/eur-amount/EurAmount';
import {useSimulationData} from '../../utils/useSimulationData';

const Cell = ({sx, isSelected, ...props}) => {
    const {pxToRem} = useTheme().typography;
    const regularStyles = {
        'px': 2,
        'height': pxToRem(52),
        'display': 'flex',
        'alignItems': 'center',
        'justifyContent': 'center',
        'borderBottom': '1px solid',
        'borderBottomColor': 'v2.blueGray.50',
        'fontSize': pxToRem(14),
        '&:first-child': {
            backgroundColor: 'v2.blueGray.50',
            textTransform: 'uppercase',
            fontWeight: 600,
            height: pxToRem(40),
            fontSize: pxToRem(11),
        },
        '&:last-child': {
            borderBottom: 'none',
        },
    };

    const selectedStyles = merge({}, regularStyles, {
        'backgroundColor': 'primary_v2.main',
        'color': 'white',
        'borderBottomColor': 'primary_v2.main',
        'fontWeight': 700,
        '&:first-child': {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            backgroundColor: 'primary_v2.dark',
            color: 'white',
            borderBottomColor: 'primary_v2.dark',
            fontWeight: 600,
            height: pxToRem(48),
        },
        '&:last-child': {
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            height: pxToRem(60),
        },
    });

    const styles = isSelected ? selectedStyles : regularStyles;

    return (
        <Box
            sx={{
                ...styles,
                ...sx,
            }}
            {...props}
        />
    );
};

Cell.propTypes = {
    sx: PropTypes.object,
    isSelected: PropTypes.bool,
};

Cell.defaultProps = {
    sx: undefined,
    isSelected: false,
};

const Column = ({heading, entries, isSelected}) => {
    return (
        <Paper
            elevation={isSelected ? 8 : 0}
            sx={{
                'flex': 1,
                'zIndex': isSelected ? 1 : 0,
                'textAlign': 'center',
                'border': isSelected ? 'none' : '1px solid',
                'borderColor': isSelected ? 'primary_v2.main' : 'v2.blueGray.50',
                'borderRightWidth': 0,
                'borderLeftWidth': 0,
                'borderRadius': isSelected ? '8px' : 0,
                'overflow': 'hidden',
                'minWidth': 'fit-content',
                '&:first-child': {
                    'borderLeftWidth': '1px',
                    'borderTopLeftRadius': '8px',
                    'borderBottomLeftRadius': '8px',
                    'flex': 'none',
                    'maxWidth': '45vw',
                    'minWidth': 0,
                    'textAlign': 'left',
                    'fontWeight': 600,
                    'color': 'v2.blueGray.500',
                    'backgroundColor': 'v2.gray.50',
                    '& > *': {
                        justifyContent: 'flex-start',
                    },
                },
                '&:last-child': {
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                    borderRightWidth: '1px',
                },
            }}
        >
            <Cell isSelected={isSelected}>{heading || <span>&nbsp;</span>}</Cell>
            {entries.map((entry, index) => (
                <Cell key={index} isSelected={isSelected}>
                    {entry}
                </Cell>
            ))}
        </Paper>
    );
};

Column.propTypes = {
    heading: PropTypes.string.isRequired,
    entries: PropTypes.array.isRequired,
    isSelected: PropTypes.bool,
};

Column.defaultProps = {
    isSelected: false,
};

export const ComparisonTable = () => {
    const {t} = useTranslation('revenueSimulator');
    const simulation = useSimulationData();
    const {netIncomeBeforeIR, restituationCA, netAfterIr, netDelta} = simulation.simulationOutput.comparisons;
    const hasSpouse = simulation.simulationInput.personalInfo.numberOfAdults === 2;
    const shouldShowMicroEnt = !hasSpouse;
    const shouldShowNetAfterIr = !hasSpouse;

    const getColumnData = key => ({
        heading: t(`comparisonTable.${key}`),
        entries: [
            <EurAmount key="nibir" amount={netIncomeBeforeIR[key]} />,
            <NumberFormat key="rca" decimalScale={2} value={restituationCA[key]} suffix="%" displayType="text" />,
            ...(shouldShowNetAfterIr ? [<EurAmount key="nai" amount={netAfterIr[key]} />] : []),
            key === 'precoHiway' ? '/' : <EurAmount key="nd" amount={netDelta[key]} />,
        ],
        isSelected: key === 'precoHiway',
    });

    const columns = [
        {
            heading: '',
            entries: [
                t('comparisonTable.netIncomeBeforeIR'),
                t('comparisonTable.restituationCA'),
                ...shouldShowNetAfterIr ? [t('comparisonTable.netAfterIr')] : [],
                hasSpouse ? t('comparisonTable.netDeltaBeforeIR') : t('comparisonTable.netDeltaAfterIR'),
            ],
        },
        getColumnData('precoHiway'),
        getColumnData('eurlIS'),
        getColumnData('sasuIS'),
        ...(shouldShowMicroEnt ? [getColumnData('microEnterprise')] : []),
        getColumnData('portageSalarie'),
    ];

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
            }}
        >
            {columns.map(column => (
                <Column key={column.heading} {...column} />
            ))}
        </Box>
    );
};
