import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {TabPanel} from '@mui/lab';
import {
    Box,
    CircularProgress,
    Fade,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import BankSidebar from './BankSidebar';
import CategorisationPage from './categorisation/components/CategorisationPage';
import CombinedTransactionList from './transaction-list/CombinedTransactionList';
import HiwayTransactionList from './transaction-list/HiwayTransactionList';
import TransactionListTabs from './transaction-list/TransactionListTabs';
import TransactionList from './TransactionList';
import {SmallHiwayLogo} from '../../../../../assets/wrapped-svg/small-hiway-logo';
import {selectRouterLocation} from '../../../../../lib/router/connected-router-saga';
import {LoadingSelectors, LoadingTypes, useLoadingState} from '../../../../loading';
import {LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import ArchiveIntegrationModal from '../../../components/archive/ArchiveIntegrationModal';
import CloseHiwayIntegrationModal from '../../../components/archive/CloseHiwayIntegrationModal';
import CloseHiwayIntegrationUnavailableModal from '../../../components/archive/CloseHiwayIntegrationUnavailableModal';
import DeleteHiwayIntegrationModal from '../../../components/archive/DeleteHiwayModal';
import DeleteIntegrationModal from '../../../components/archive/DeleteIntegrationModal';
import NewDefaultAccountModal from '../../../components/archive/NewDefaultAccountModal';
import ConnectProPage from '../../../components/connect/ConnectProPage';
import {BankSelector} from '../../../store/bank.selector';
import PickAdditionalAccountModal from '../../bridge-api/components/PickAdditionalAccountModal';
import PickDefaultBankModal from '../../bridge-api/components/PickDefaultBankModal';
import PickIntegrationAccountModal from '../../bridge-api/components/PickIntegrationAccountModal';
import {BridgeActions} from '../../bridge-api/store/bridge-api.action';
import {getIntegrationInfo} from '../../bridge-api/utils/bridge-util';
import {BANK_TYPES, BankAccountStatus} from '../../bridge-api/utils/constants';
import {TransactionsActions} from '../store/transactions.action';
import {TransactionsSelector} from '../store/transactions.selector';
import {
    ADMIN_TOP_OFFSET,
    BANK_ACCOUNT_MISSING, HIWAY_PRO_NAME,
    TOP_OFFSET,
} from '../util/constants';

// TODO This should be provided by BE
const bankIconColors = [
    '#03A9F4',
    '#9575CD',
    '#A5D6A7',
    '#F48FB1',
    '#FFCC80',
    '#88f403',
];

export const TransactionAccounts = ({isAdminInterface, user}) => {
    const dispatch = useDispatch();

    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const integrations = useSelector(BankSelector.selectIntegrations);
    const archivedIntegrations = useSelector(BankSelector.selectIntegrationsWithArchived);
    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const totalOverview = useSelector(TransactionsSelector.selectTransactionTotalOverview);
    const categoryList = useSelector(TransactionsSelector.selectCategories);
    const sourcesList = useSelector(TransactionsSelector.selectSources);
    const isDeletingIntegration = useLoadingState(LoadingTypes.DELETE_BANK);
    const categorizationScreenInfo = useSelector(TransactionsSelector.selectCategorizationScreenInfo);

    const location = useSelector(selectRouterLocation);

    const isLoading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.BANK_GET_INTEGRATIONS,
    ));

    const [hasBankSidebarOpen, setHasBankSidebarOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);

    const [hasHiwayPro, fullBankAccountList, bankAccountNumber, combinedTabIcons, hasArchived] = useMemo(() => {
        const combinedTabIcons = {};
        const accountList = integrations.map((integration, integrationIndex) => {
            const isHiway = integration?.type === BANK_TYPES.hiway;

            const bankAccountHolder = integration?.bankAccountHolders.find(holder => holder.type === 'COMPANY');
            const bankAccount = bankAccountHolder?.bankAccounts?.[0];
            const accountId = bankAccount?.id ?? BANK_ACCOUNT_MISSING;

            let amount = 0;
            let uncategorized = 0;
            const name = isHiway
                ? HIWAY_PRO_NAME
                : (integration.bankName ?? ' Anonyme '); // TODO Check what should be in this case

            if (totalOverview?.overviewPerAccount) {
                const overview = totalOverview.overviewPerAccount.find(
                    overview => overview.id === accountId,
                );

                if (overview) {
                    amount = overview.availableBalance;
                    uncategorized = overview.numberOfUncategorizedTransactions;
                } else if (bankAccount) {
                    amount = bankAccount?.availableBalance;
                }
            }
            const shouldAskForIntegrationAccount = integration?.bankAccountHolders?.length === 0
                && integration?.integrationAccounts?.length > 1;

            if (bankAccount?.id) {
                combinedTabIcons[bankAccount?.id] = {
                    id: integration?.id,
                    name,
                    icon: isHiway
                        ? <SmallHiwayLogo />
                        : (
                            <AccountBalanceIcon sx={{
                                color: bankIconColors?.[integrationIndex],
                            }}
                            />
                        ),
                };
            }

            return {
                ...integration,
                name,
                integrationId: bankAccountHolder?.bankIntegrationId,
                accountId,
                amount,
                uncategorized,
                iban: bankAccount?.iban,
                bic: bankAccount?.bic,
                shouldAskForIntegrationAccount,
                color: bankIconColors?.[integrationIndex],
            };
        });

        const hasHiwayPro = accountList.some(bank => bank?.type === BANK_TYPES.hiway);

        // Check if there are archived integrations
        const hasArchived = archivedIntegrations?.length > 0 || totalOverview?.overviewPerAccount?.some(
            overview => overview?.status === BankAccountStatus.ARCHIVED,
        );

        // Add icons and ids for archvied
        if (hasArchived) {
            totalOverview.overviewPerAccount?.forEach((overview, index) => {
                const archivedIntegration = archivedIntegrations.find(integration => {
                    const {account} = getIntegrationInfo(integration);
                    return account?.id === overview?.id;
                });

                const isHiway = archivedIntegration?.type === BANK_TYPES.hiway;

                const name = isHiway
                    ? HIWAY_PRO_NAME
                    : (archivedIntegration?.bankName ?? overview?.name);

                if (!combinedTabIcons?.[overview?.id]) {
                    combinedTabIcons[overview?.id] = {
                        id: overview?.id,
                        name: name,
                        icon: isHiway
                            ? <SmallHiwayLogo />
                            : (
                                <AccountBalanceIcon sx={{
                                    color: bankIconColors?.[index],
                                }}
                                />
                            ),
                    };
                }
            });
        }

        // Put hiway bank on first place if it is not
        if (hasHiwayPro && accountList[0]?.type !== BANK_TYPES.hiway) {
            const hiwayIndex = accountList.findIndex(account => account.type === BANK_TYPES.hiway);

            const oldFirstElement = {...accountList[0]};
            accountList.splice(0, 1, accountList[hiwayIndex]);
            accountList.splice(hiwayIndex, 1, oldFirstElement);
        }

        return [hasHiwayPro, accountList, accountList.length, combinedTabIcons, hasArchived];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrations, totalOverview]);

    const [tabValue, setTabValue] = useState(undefined);
    // Used to prevent multiple callings to get transactions on load
    const [isTabValueAuto, setTabValueAuto] = useState(false);
    const handleTabChange = newTab => {
        if (newTab === BANK_TYPES.new_bridge) {
            dispatch(BridgeActions.connectToBridge());
            return;
        }

        setTabValue(newTab);
        setTabValueAuto(false);
    };

    const topOffset = isAdminInterface ? ADMIN_TOP_OFFSET : TOP_OFFSET;

    const loadCategoriesList = () => {
        dispatch(TransactionsActions.getCategoryList());
        dispatch(TransactionsActions.getSourcesList());
    };

    // TODO Create more consistent way for this, there is probably no need for isLoaded variable
    // On delete/archive of integration we need to select new tab
    useEffect(() => {
        if (integrations?.length > 0) {
            setIsLoaded(false);
        } else if (archivedIntegrations?.length > 0) {
            setTabValue(BANK_TYPES.archived);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrations?.length]);

    useEffect(() => {
        if (!isLoaded) {
            if (fullBankAccountList?.length > 1) {
                setTabValue(BANK_TYPES.combined);
            } else if (fullBankAccountList?.length > 0) {
                setTabValue(fullBankAccountList[0].id);
            } else if (archivedIntegrations?.length > 0) {
                setTabValue(BANK_TYPES.archived);
            }
            setIsLoaded(true);
            setTabValueAuto(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullBankAccountList]);

    useEffect(() => {
        // HPD-4688 - Modify fetch categories logic on Admin bank interface
        if (
            ((!categoryList || categoryList?.length === 0)
            || (!sourcesList || sourcesList?.length === 0))
            && !isAdminInterface
        ) {
            loadCategoriesList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categoryList, integrations]);

    useEffect(() => {
        if (isAdminInterface) {
            loadCategoriesList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrations]);

    useEffect(() => {
        // We need to call this in any case on reaching the page: whether there is a combined tab or not
        if (!categorizationScreenInfo) {
            dispatch(TransactionsActions.getCombinedTransactionList(isAdminInterface ? {userId: user?.id} : {}));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integrations?.length]);

    useEffect(() => {
        if (loggedInUser.hasBankAccess) {
            dispatch(BridgeActions.checkBridgeOnboarding());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        if (isTabValueAuto) {
            setTabValueAuto(false);
        }
    }, [isTabValueAuto]);

    useEffect(() => {
        if (isDeletingIntegration) {
            setHasBankSidebarOpen(false);
            if (fullBankAccountList?.length > 0) {
                setTabValue(fullBankAccountList[0].id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeletingIntegration]);

    // Logic regarding closing/archiving Hiway bank, open hiway tab
    useEffect(() => {
        if (location && location?.query?.closeAccountId && integrations?.length > 0) {
            const hiwayIntegration = integrations?.find(
                integration => integration?.id === location.query.closeAccountId,
            );


            if (hiwayIntegration?.id && !tabValue) {
                setTabValue(hiwayIntegration.id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, integrations]);

    if (bankAccountNumber === 0 && !hasArchived && !isLoading && !isAdminInterface) {
        return (
            <>
                <DeleteHiwayIntegrationModal />
                <ConnectProPage />
            </>
        );
    }

    if (bankAccountNumber === 0 && isLoading) {
        return (
            <Box sx={{
                width: '100%',
                height: '500px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                <CircularProgress size={40} />
            </Box>
        );
    }

    return (
        <>
            <Fade
                timeout={300}
                in={!!categorizationScreenInfo}
            >
                <div>
                    {categorizationScreenInfo && (
                    <CategorisationPage
                        isAdminInterface={true}
                        user={user}
                    />
                    )}
                </div>
            </Fade>

            <Box sx={{
                opacity: categorizationScreenInfo ? '0' : '1',
                transition: 'opacity 0.3s ease-out',
            }}
            >
                <Box
                    sx={{
                        display: categorizationScreenInfo ? 'none' : 'block',
                    }}
                >
                    <TransactionListTabs
                        tabValue={tabValue}
                        handleTabChange={handleTabChange}
                        bankNumber={bankAccountNumber}
                        fullBankAccountList={fullBankAccountList}
                        hasHiwayPro={hasHiwayPro}
                        combinedTotal={totalOverview?.totalAvailableBalance ?? 0}
                        combinedUncategorized={totalOverview?.totalUncategorizedTransactions ?? 0}
                        setHasBankSidebarOpen={setHasBankSidebarOpen}
                        hasBankSidebarOpen={hasBankSidebarOpen}
                        isAdminInterface={isAdminInterface}
                        hasArchived={hasArchived}
                        isTabValueAuto={isTabValueAuto}
                    >
                        {bankAccountNumber > 0 && (
                        <TabPanel
                            value={BANK_TYPES.combined}
                            sx={{p: 0}}
                        >
                            <CombinedTransactionList
                                topOffset={topOffset}
                                isAdminInterface={isAdminInterface}
                                balance={totalOverview?.totalAvailableBalance ?? 0}
                                isOpened={tabValue === BANK_TYPES.combined}
                                user={user}
                                hasBankSidebarOpen={hasBankSidebarOpen}
                                combinedTabIcons={combinedTabIcons}
                                isTabValueAuto={isTabValueAuto}
                            />
                        </TabPanel>
                        )}
                        {fullBankAccountList.map(bank => {
                            if (bank.type === BANK_TYPES.hiway) {
                                return (
                                    <HiwayTransactionList
                                        key={bank.id}
                                        topOffset={topOffset}
                                        isAdminInterface={isAdminInterface}
                                        bank={bank}
                                        integrations={integrations}
                                        isOpened={tabValue === BANK_TYPES.hiway || tabValue === bank.id}
                                        hasBankSidebarOpen={hasBankSidebarOpen}
                                        setHasBankSidebarOpen={setHasBankSidebarOpen}
                                        setTabValue={setTabValue}
                                        isTabValueAuto={isTabValueAuto}
                                    />
                                );
                            }

                            return (
                                <TabPanel
                                    key={bank.id}
                                    value={bank.id}
                                    sx={{p: 0}}
                                >
                                    <TransactionList
                                        topOffset={topOffset}
                                        isAdminInterface={isAdminInterface}
                                        bank={bank}
                                        isOpened={tabValue === bank.id}
                                        hasBankSidebarOpen={hasBankSidebarOpen}
                                        setHasBankSidebarOpen={setHasBankSidebarOpen}
                                        isTabValueAuto={isTabValueAuto}
                                    />
                                </TabPanel>
                            );
                        })}
                        {hasArchived && (
                        <TabPanel
                            value={BANK_TYPES.archived}
                            sx={{p: 0}}
                        >
                            <CombinedTransactionList
                                topOffset={topOffset}
                                isAdminInterface={isAdminInterface}
                                balance={totalOverview?.totalAvailableBalance ?? 0}
                                isOpened={tabValue === BANK_TYPES.archived}
                                user={user}
                                hasBankSidebarOpen={hasBankSidebarOpen}
                                combinedTabIcons={combinedTabIcons}
                                isTabValueAuto={isTabValueAuto}
                                isArchived={true}
                            />
                        </TabPanel>
                        )}
                    </TransactionListTabs>

                    <BankSidebar
                        onClose={() => setHasBankSidebarOpen(false)}
                        isMobileSize={isMobileSize}
                        integration={hasBankSidebarOpen}
                    />
                </Box>

                <PickAdditionalAccountModal />
                <PickIntegrationAccountModal />
                <PickDefaultBankModal />

                <CloseHiwayIntegrationUnavailableModal />
                <CloseHiwayIntegrationModal />
                <DeleteHiwayIntegrationModal />
                <ArchiveIntegrationModal />
                <DeleteIntegrationModal />
                <NewDefaultAccountModal />
            </Box>
        </>
    );
};

TransactionAccounts.propTypes = {
    isAdminInterface: PropTypes.bool,
    user: PropTypes.object,
};

TransactionAccounts.defaultProps = {
    isAdminInterface: false,
    user: null,
};
