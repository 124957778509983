// import {END_SIDE_EFFECTS_RUNNING, push, replace, selectRouterLocation} from '@burnmaniac/connected-router-saga';
// import {matchRoutes} from 'react-router-dom';
import {all, select, takeLatest} from 'redux-saga/effects';

import * as actions from './currentCompany.actions';
// import {storeSelectedCompanyId} from './currentCompany.actions';
import {createSelectedCompanySelector} from './currentCompany.selectors';
// import {Routes} from '../../../../lib/router/routes';
import {COMPANY_TYPES} from '../../../config/constants/companyConstants';
// import {resolveRoute} from '../../../navigation/resolveRoute';
// import {ROUTE_PATHS} from '../../../navigation/routePaths';
// import routes from '../../../navigation/router.routes';
// import {createFreelancerCompanyByIdSelector, selectFreelancerAccount} from '../../freelancer/freelancer.selectors';

const onSetSelectedCompany = function* ({payload}) {
    // const result = yield call(accessControlForHoldingCompany);
    //
    // if (result === END_SIDE_EFFECTS_RUNNING) {
    //     return;
    // }
    //
    // const company = yield select(createFreelancerCompanyByIdSelector(payload.companyId));
    //
    // const paths = [
    //     resolveRoute(ROUTE_PATHS.MY_COMPANY, {companyId: company?.id}),
    // ];
    //
    // const location = yield select(selectRouterLocation);
    //
    // const path = paths.find(path => location.pathname.includes(path));
    //
    // if (path) {
    //     const freelancerAccount = yield select(selectFreelancerAccount);
    //
    //     yield put(storeSelectedCompanyId(freelancerAccount.defaultCompanyId));
    //
    //     return;
    // }
    //
    // yield put(storeSelectedCompanyId(payload.companyId));
    //
    // if (company.type === COMPANY_TYPES.DEFAULT && payload.isManualAction) {
    //     yield put(push(ROUTE_PATHS.DASHBOARD));
    //
    //     return;
    // }
    //
    // if (company.type === COMPANY_TYPES.HOLDING && payload.isManualAction) {
    //     yield put(push(resolveRoute(ROUTE_PATHS.DOCUMENTS, {companyId: payload.companyId})));
    //
    //     return;
    // }
    //
    // for (const path of Object.values(ROUTE_PATHS)) {
    //     const match = matchRoutes(Routes, path);
    //
    //     if (!match || match?.path === '/') {
    //         continue;
    //     }
    //
    //     const companyId = match.params?.companyId;
    //
    //     if (!companyId || !company?.id) {
    //         continue;
    //     }
    //
    //     if (companyId === company?.id) {
    //         break;
    //     }
    //
    //     yield put(replace(resolveRoute(match.path, {companyId: company?.id})));
    // }
};

export const accessControlForHoldingCompany = function* () {
    const company = yield select(createSelectedCompanySelector());

    if (company?.type === COMPANY_TYPES.HOLDING) {
        // const paths = [
        //     resolveRoute(ROUTE_PATHS.DOCUMENTS, {companyId: company?.id}),
        //     resolveRoute(ROUTE_PATHS.SETUP_COMPANY, {companyId: company?.id}),
        //     resolveRoute(ROUTE_PATHS.GENERATE_DOCUMENTS, {companyId: company?.id}),
        //     resolveRoute(ROUTE_PATHS.MY_COMPANY, {companyId: company?.id}),
        // ];
        //
        // const location = yield select(getLocation);
        //
        // const path = paths.find(path => location.pathname.includes(path));
        //
        // if (!path) {
        //     yield put(replace(resolveRoute(ROUTE_PATHS.DOCUMENTS, {companyId: company?.id})));
        //
        //     return END_SIDE_EFFECTS_RUNNING;
        // }
    }
};

export const watchCurrentCompanySagas = function* () {
    yield all([
        takeLatest(actions.SET_SELECTED_COMPANY_ID, onSetSelectedCompany),
    ]);
};
