import {Box, ButtonGroup} from '@mui/material';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

export const TabsBlock = ({children, onTabChange}) => {
    const [activeTab, setActiveTab] = useState(0);
    const isMobileSize = useMediaQuery('(max-width: 960px)');

    useEffect(() => {
        onTabChange?.(activeTab);
    }, [activeTab, onTabChange]);

    const renderTabButtons = () => {
        return React.Children.map(children, (child, index) => (
            <Button
                onClick={() => setActiveTab(index)}
                variant={activeTab === index ? 'contained' : 'outlined'}
                color="primary_v2"
                disabled={child.props.isDisabled}
                sx={{
                    height: theme => theme.typography.pxToRem(36),
                    fontSize: theme => theme.typography.pxToRem(isMobileSize ? 13 : 14),
                    whiteSpace: 'nowrap',
                }}
            >
                {child.props.title}
            </Button>
        ));
    };

    const renderActiveTabContent = () => {
        return React.Children.map(children, (child, index) => {
            if (index === activeTab) return child.props.children;
            return null;
        });
    };

    return (
        <Box
            sx={{
                flex: 1,
                borderRadius: '16px',
                border: '1px solid',
                borderColor: 'v2.blueGray.50',
                p: 2,
            }}
        >
            <ButtonGroup variant="outlined" aria-label="outlined button group" fullWidth>
                {renderTabButtons()}
            </ButtonGroup>
            <Box mt={2}>{renderActiveTabContent()}</Box>
        </Box>
    );
};

TabsBlock.propTypes = {
    onTabChange: PropTypes.func,
};

TabsBlock.defaultProps = {
    onTabChange: undefined,
};

export const Tab = ({children}) => {
    return <>{children}</>;
};

Tab.propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    title: PropTypes.string.isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    isDisabled: PropTypes.bool,
};

Tab.defaultProps = {
    isDisabled: false,
};
