import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {Box} from '@mui/material';
import merge from 'lodash.merge';
import PropTypes from 'prop-types';

const baseStyles = {
    container: {
        'flex': 1,
        'borderRadius': '16px',
        'border': '1px solid',
        'borderColor': 'v2.blueGray.50',
        'fontFamily': 'Red Hat Text',
        'display': 'flex',
        'flexDirection': 'column',
        'alignItems': 'center',
        '&:hover, &:focus-within': {
            borderColor: '#bad6f1',
        },
        'p': 3,
        'gap': 3,
    },
    header: {
        flexDirection: 'column',
        alignItems: 'center',
        gap: 2,
        display: 'flex',
        flex: 1,
    },
    iconContainer: {
        backgroundColor: 'v2.light.primary.shades4',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        width: theme => theme.typography.pxToRem(96),
        height: theme => theme.typography.pxToRem(96),
    },
    icon: {
        color: 'v2.light.info.main',
        fontSize: theme => theme.typography.pxToRem(48),
    },
    title: {
        fontSize: theme => theme.typography.pxToRem(24),
        letterSpacing: '-0.2px',
        fontWeight: 600,
        lineHeight: 1.167,
        mb: 1,
        textAlign: 'center',
    },
    subtitle: {
        textAlign: 'center',
        fontSize: '1rem',
        lineHeight: 1.5,
        color: 'v2.blueGray.500',
        letterSpacing: '0.15px',
    },
};

const compactStyles = merge({}, baseStyles, {
    container: {
        p: 2,
        alignItems: 'flex-start',
        gap: 2,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 1,
    },
    iconContainer: {
        width: theme => theme.typography.pxToRem(44),
        height: theme => theme.typography.pxToRem(44),
    },
    icon: {
        fontSize: theme => theme.typography.pxToRem(24),
    },
    title: {
        fontSize: theme => theme.typography.pxToRem(20),
        mb: 0,
    },
    subtitle: {
        display: 'none',
    },
});

const horizontalStyles = merge({}, baseStyles, {
    container: {
        flexDirection: 'row',
        gap: 2,
    },
    header: {
        flexDirection: 'row',
    },
    title: {
        textAlign: 'left',
    },
    subtitle: {
        textAlign: 'left',
    },
});

const compactHorizontalStyles = merge({}, compactStyles, {
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});

const variantStyles = {
    'regular': baseStyles,
    'compact': compactStyles,
    'horizontal': horizontalStyles,
    'compact-horizontal': compactHorizontalStyles,
};

export const FieldBlock = ({Icon, title, subtitle, variant, children}) => {
    const styles = variantStyles[variant];

    return (
        <Box sx={styles.container}>
            <Box sx={styles.header}>
                <Box sx={styles.iconContainer}>
                    <Icon sx={styles.icon} />
                </Box>
                <Box sx={styles.titleAndSubtitle}>
                    <Box sx={styles.title}>{title}</Box>
                    {subtitle && <Box sx={styles.subtitle}>{subtitle}</Box>}
                </Box>
            </Box>

            {variant === 'horizontal' ? <Box flex={1}>{children}</Box> : children}
        </Box>
    );
};

FieldBlock.propTypes = {
    Icon: PropTypes.elementType,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    variant: PropTypes.string,
    children: PropTypes.node.isRequired,
};

FieldBlock.defaultProps = {
    Icon: QuestionMarkIcon,
    variant: 'regular',
    subtitle: '',
};
