import DoneAllIcon from '@mui/icons-material/DoneAll';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import SyncIcon from '@mui/icons-material/Sync';
import {Box, Button, CircularProgress, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import SectionStatus from './SectionStatus';
import {ButtonIcon} from '../../../../../../components/buttons/ButtonIcon';
import {DataItemRow} from '../../../../../../components/data-item-row/DataItemRow';
import {getCompanyDocument} from '../../../../../../v1/app/company/company.actions';
import DataItemRowLabel from '../../../../../../v1/components/ui-kit/DataItemRowLabel';
import DocumentStatusTag from '../../../../../../v1/components/ui-kit/DocumentStatusTag/DocumentStatusTag';
import ExpandingContainer from '../../../../../../v1/components/ui-kit/ExpandingContainer';
import {DOCUMENT_STATUSES} from '../../../../../../v1/config/constants/documentConstants';
import CategorisationSection from '../../../../../bank/modules/account-balance/components/categorisation/components/header/CategorisationSection';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {AccountingSelector} from '../../../accounting/store/accounting.selector';
import {FORMALITY_SECTION_STATUS} from '../../utils/constants';

const FlowStepDocumentSigning = ({isActive, isFocused}) => {
    const {freelancerId, companyId} = useParams();
    const [searchParams] = useSearchParams();
    const year = searchParams.get('year');
    const dispatch = useDispatch();

    const {t} = useTranslation('companies');
    const annualAccounts = useSelector(AccountingSelector.selectFinancialStatements)?.[year] ?? {};
    const documents = annualAccounts.generatedDocuments?.reduce((docs, doc) => ({...docs, [doc.id]: doc}), {}) ?? {};
    const allDocumentsSigned = Object.values(documents)
        .reduce((result, doc) => result && doc?.docStatus === DOCUMENT_STATUSES.SIGNED, true);
    const documentGenerationProgress = useSelector(AccountingSelector.selectDocumentGenerationProgres);

    const regenerateAllDocuments = () => {
        dispatch(UiActions.setModalData(ModalsKeys.DEPOSIT_OF_ACCOUNTS_REGENERATE_DOCUMENTS, null));
        dispatch(UiActions.setActiveModal(ModalsKeys.DEPOSIT_OF_ACCOUNTS_REGENERATE_DOCUMENTS, true));
    };

    const documentRows = () => {
        return (
            <Box>
                {Object.values(documents).map(doc => {
                    return (
                        <DataItemRow
                            key={doc.id}
                            label={(
                                <DataItemRowLabel>
                                    <Box sx={styles.rowInnerLabel}>
                                        {t(`formalities.depositOfAccounts.flowSteps.documentSigning.documents.${doc.docType}`)}
                                        <ButtonIcon
                                            icon={<OpenInNewIcon />}
                                            sx={{color: 'v2.light.primary.main'}}
                                            // View document
                                            onClick={() => {
                                                dispatch(getCompanyDocument(freelancerId, companyId, doc.id));
                                            }}
                                        />
                                    </Box>
                                </DataItemRowLabel>
                            )}
                            content={(
                                <Box sx={styles.rowContent}>
                                    {documentGenerationProgress[doc.id]
                                        ? <CircularProgress size={24} />
                                        : (
                                            <>
                                                <DocumentStatusTag status={doc.docStatus} />
                                                {!annualAccounts?.payLinkInfo?.integrationId && (
                                                    <ButtonIcon
                                                        icon={<RefreshIcon />}
                                                        sx={{color: 'v2.light.primary.main'}}
                                                        disabled={!!annualAccounts?.payLinkInfo?.integrationId}
                                                        // Regenerate documents
                                                        onClick={() => {
                                                            dispatch(UiActions.setModalData(
                                                                ModalsKeys.DEPOSIT_OF_ACCOUNTS_REGENERATE_DOCUMENTS,
                                                                doc,
                                                            ));
                                                            dispatch(UiActions.setActiveModal(
                                                                ModalsKeys.DEPOSIT_OF_ACCOUNTS_REGENERATE_DOCUMENTS,
                                                                true,
                                                            ));
                                                        }}
                                                    />
                                                )}
                                            </>
                                        )
                                    }
                                </Box>
                            )}
                        />
                    );
                })}
            </Box>
        );
    };

    const getStatus = () => {
        if (isActive) {
            return '';
        }

        // No balance sheet
        if (Object.keys(annualAccounts?.balanceSheetDocument ?? {}).length === 0) {
            return FORMALITY_SECTION_STATUS.WAITING_FOR_REPORT_UPLOAD;
        }

        if (!annualAccounts?.finalFec) {
            return FORMALITY_SECTION_STATUS.WAITING_FOR_FINAL_FEC_UPLOAD;
        }

        return FORMALITY_SECTION_STATUS.WAITING_FOR_FINAL_FEC_UPLOAD;
    };

    return (
        <CategorisationSection
            title={(
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    {t('formalities.depositOfAccounts.flowSteps.documentSigning.title')}
                    {isActive && !annualAccounts?.payLinkInfo?.integrationId && (
                        <Button
                            color="primary"
                            startIcon={<SyncIcon />}
                            onClick={regenerateAllDocuments}
                            disabled={
                                Object.values(documentGenerationProgress).reduce((res, curr) => res || curr, false)
                                    || !!annualAccounts?.payLinkInfo?.integrationId
                            }
                        >
                            {t('formalities.depositOfAccounts.flowSteps.documentSigning.regenerateAllDocuments')}
                        </Button>
                    )}
                </Box>
            )}
            status={getStatus()}
            SectionStatusComponent={SectionStatus}
            isActive={isActive}
            isFocused={isActive && isFocused}
            statusTranslationSource="companies"
            statusTranslationPath="accounting.uploadFec.statuses"
            sx={{width: '100%', maxWidth: '600px', gap: 0.5}}
        >
            {allDocumentsSigned && (
                <ExpandingContainer
                    isInitiallyExpanded={!allDocumentsSigned}
                    complexTitle={(
                        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1}}>
                            <DoneAllIcon sx={{color: 'v2.light.success.main'}} />
                            <Typography>
                                {t('formalities.depositOfAccounts.flowSteps.documentSigning.documentsSigned')}
                            </Typography>
                        </Box>
                    )}
                    elevation={0}
                    sxOverride={{'backgroundColor': 'v2.light.success.shades4',
                        'marginTop': '0px !important',
                        '.makeStyles-titleParent-4': {
                            padding: '4px 16px',
                        }}}
                >
                    {documentRows()}
                </ExpandingContainer>
            )}
            {!allDocumentsSigned && documentRows()}
        </CategorisationSection>
    );
};

const styles = {
    rowContent: {
        width: '100%',
        display: 'flex',
        justifyContent: 'right',
        gap: 1,
    },
    rowInnerLabel: {
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
    },
};

FlowStepDocumentSigning.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isFocused: PropTypes.bool.isRequired,
};

export default FlowStepDocumentSigning;
