import {all, call, fork, put, select, take, takeLatest} from 'redux-saga/effects';

import {watchContractDataSagas} from './contract/contract.sagas';
import * as actions from './freelancer.actions';
import {getFreelancerCompanies} from './freelancer.actions';
import {selectFreelancerAccount} from './freelancer.selectors';
import {watchSignatureSagas} from './signature/signature.sagas';
import {push} from '../../../lib/router/connected-router-saga';
import {Toast} from '../../../lib/toast';
import {getConfig} from '../../config';
import {
    getFreelancerAccountRequest,
    getFreelancerCompaniesRequest,
} from '../api/providers/freelancer/freelancer.provider';
import {selectCurrentCognitoUser} from '../auth/auth.selectors';

const getFreelancerAccountSaga = function* getFreelancerAccountSaga({payload}) {
    yield put(actions.setIsLoadingFreelancerAccount(true));

    try {
        const freelancerAccount = yield call(getFreelancerAccountRequest, payload);

        yield put(actions.storeFreelancerAccount(freelancerAccount));
    } catch (error) {
        if (error?.response?.status === 404) {
            Toast.error('canNotSetupCompany');

            yield put(push(getConfig().ROUTE_PATHS.DASHBOARD));

            return;
        }

        // TODO:LOW Better error handling.
        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingFreelancerAccount(false));
    }
};

const getFreelancerCompaniesSaga = function* getFreelancerCompaniesSaga({payload}) {
    yield put(actions.setIsLoadingFreelancerCompanies(true));

    try {
        const {freelancerId} = payload;

        const companies = yield call(getFreelancerCompaniesRequest, freelancerId);

        yield put(actions.storeFreelancerCompanies(companies));
    } catch (error) {
        // TODO:LOW Better error handling.
        Toast.error('anErrorOccurred');
    } finally {
        yield put(actions.setIsLoadingFreelancerCompanies(false));
    }
};

export const loadFreelancerAccountSaga = function* loadFreelancerAccountSaga(freelancerId) {
    const freelancerAccount = yield select(selectFreelancerAccount);

    if (!freelancerAccount || freelancerAccount.id !== freelancerId) {
        yield put(actions.getFreelancerAccount(freelancerId));

        yield take(actions.STORE_FREELANCER_ACCOUNT);
    }
};

export const loadCompaniesForCurrentUser = function* () {
    const currentCognitoUser = yield select(selectCurrentCognitoUser);

    if (!currentCognitoUser || !currentCognitoUser.id) {
        return;
    }

    yield put(getFreelancerCompanies(currentCognitoUser.id));

    yield take(actions.STORE_FREELANCER_COMPANIES);
};

export const watchFreelancerSagas = function* watchFreelancerSagas() {
    yield all([
        fork(watchSignatureSagas),
        fork(watchContractDataSagas),
        takeLatest(actions.GET_FREELANCER_ACCOUNT, getFreelancerAccountSaga),
        takeLatest(actions.GET_FREELANCER_COMPANIES, getFreelancerCompaniesSaga),
    ]);
};
