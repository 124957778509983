import {Slide} from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {FreelancerCompanyCreation} from '../../../../../assets/wrapped-svg/freelancer-company-creation';
import {PageHeader} from '../../../../../components/page-header/PageHeader';
import Banner from '../../../../../layout/ContainerWithBigImage/Banner';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../animations/utils/constants';
import {OnboardingActions} from '../../onboarding/store/onboarding.action';
import {CompanySetupSubSteps} from '../utils/constants';

export const InitialScreen = () => {
    const {t} = useTranslation('freelancerOnboarding');

    const dispatch = useDispatch();

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const onNextClick = () => {
        dispatch(AnimationActions.storeNextDispatch(
            OnboardingActions.setSubStep(CompanySetupSubSteps.PERSONAL_INFORMATION),
        ));
        dispatch(AnimationActions.setIsSubAnimationActive(false));
    };

    return (
        <Box display="grid" gridTemplateRows="min-content 1fr">
            {!isMobileSize && (
            <PageHeader>
                {t('companyCreateInitial.header')}
            </PageHeader>
            )}

            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <div>
                    <Banner
                        header={(
                            <Trans t={t} i18nKey="companyCreateInitial.headline" components={{bold: <Box component="span" sx={{color: 'rgba(232, 76, 76, 1)'}} />}} />
                        )}
                        text={t('companyCreateInitial.text')}
                        Illustration={(
                            <FreelancerCompanyCreation sx={!isMobileSize
                                ? {width: '473px', height: '518px'}
                                : {width: '100%', height: '318px'}
                            }
                            />
                        )}
                        nextButtonText={t('companyCreateInitial.next')}
                        onNextClick={onNextClick}
                    />
                </div>
            </Slide>
        </Box>
    );
};
