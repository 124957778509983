import {SvgIcon} from '@mui/material';

export const SmallHiwayLogo = props => {
    return (
        <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
            <mask id="mask0_26711_190061" x="0" y="0" width="16" height="16">
                <path d="M8.00007 16.0001C12.4184 16.0001 16.0001 12.4183 16.0001 8C16.0001 3.58168 12.4184 -6.86646e-05 8.00007 -6.86646e-05C3.58175 -6.86646e-05 0 3.58168 0 8C0 12.4183 3.58175 16.0001 8.00007 16.0001Z" fill="white" />
            </mask>
            <g mask="url(#mask0_26711_190061)">
                <path d="M8.00007 16C12.4184 16 16.0001 12.4183 16.0001 7.99994C16.0001 3.58163 12.4184 -0.000125885 8.00007 -0.000125885C3.58175 -0.000125885 0 3.58163 0 7.99994C0 12.4183 3.58175 16 8.00007 16Z" fill="#ECECEC" />
                <path d="M16.5727 -0.000118256H-0.570312V3.19991H16.5727V-0.000118256Z" fill="#ECAC4C" />
                <path d="M16.5727 3.19987H-0.570312V6.3999H16.5727V3.19987Z" fill="#EB8C4A" />
                <path d="M16.5727 6.39995H-0.570312V9.59998H16.5727V6.39995Z" fill="#EA6D4B" />
                <path d="M16.5727 9.59999H-0.570312V12.8H16.5727V9.59999Z" fill="#E84C4C" />
                <path d="M16.5727 12.8H-0.570312V16.0001H16.5727V12.8Z" fill="#0C577C" />
                <path opacity="0.300525" d="M8.75699 5.86856C8.67809 5.88671 8.60094 5.91176 8.52644 5.94344L9.13337 2.92441C9.164 2.7484 9.0984 2.56942 8.96129 2.45489C8.82418 2.34036 8.63638 2.30768 8.46864 2.36917C8.30091 2.43065 8.17871 2.57695 8.14809 2.75296L6.17751 12.4091C6.13583 12.5814 6.19268 12.7626 6.3253 12.8801C6.45865 12.9933 6.63752 13.0371 6.80809 12.9984L6.85736 12.9846C7.00712 12.936 7.11876 12.81 7.149 12.6555L8.08897 8.06977C8.2742 7.32093 8.57373 6.88738 8.9777 6.80068C9.43881 6.69229 9.48217 6.87753 9.50975 6.99774C9.5328 7.17541 9.52278 7.3558 9.4802 7.52982L8.59147 11.7923C8.5467 11.9698 8.60443 12.1575 8.74123 12.2791C8.88863 12.4108 9.09427 12.4557 9.28314 12.3973C9.42999 12.3488 9.53778 12.2228 9.56296 12.0702L10.4537 7.73082C10.5332 7.42127 10.5399 7.09751 10.4734 6.78491C10.294 6.01045 9.63784 5.66361 8.75699 5.86856Z" fill="#033D56" />
                <path d="M7.96793 5.67153C7.88903 5.68967 7.81188 5.71473 7.73737 5.74641L8.34431 2.72738C8.37494 2.55137 8.30934 2.37239 8.17223 2.25786C8.03511 2.14333 7.84732 2.11065 7.67958 2.17213C7.51184 2.23362 7.38965 2.37992 7.35902 2.55593L5.38845 12.2121C5.34677 12.3844 5.40362 12.5655 5.53624 12.6831C5.66959 12.7963 5.84846 12.8401 6.01903 12.8013L6.0683 12.7875C6.21806 12.7389 6.3297 12.613 6.35994 12.4584L7.29991 7.87274C7.48514 7.12389 7.78467 6.69035 8.18864 6.60364C8.64975 6.49526 8.6931 6.6805 8.72069 6.80071C8.74374 6.97837 8.73372 7.15877 8.69113 7.33278L7.80043 11.5992C7.75566 11.7767 7.8134 11.9644 7.9502 12.086C8.0976 12.2177 8.30323 12.2626 8.49211 12.2042C8.63896 12.1557 8.74675 12.0297 8.77193 11.8771L9.6646 7.53379C9.74413 7.22424 9.75087 6.90047 9.68431 6.58788C9.50498 5.81341 8.84878 5.46658 7.96793 5.67153Z" fill="white" />
            </g>
        </SvgIcon>
    );
};
