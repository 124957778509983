import CategoryIcon from '@mui/icons-material/Category';
import CoffeeIcon from '@mui/icons-material/Coffee';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import TrainIcon from '@mui/icons-material/Train';
import {useTranslation} from 'react-i18next';
import {FinanceViewIcon} from '../../../../../../assets/wrapped-svg/finance-view';
import {RocketIcon} from '../../../../../../assets/wrapped-svg/rocket';
import {TableViewIcon} from '../../../../../../assets/wrapped-svg/table-view';
import {EurField} from '../EurField';
import {FieldBlock} from '../FieldBlock';
import {FixedAmount} from '../FixedAmount';
import {FormSection} from '../FormSection';
import {FormSectionRow} from '../FormSectionRow';

const fixedAmounts = {
    SETUP: 700,
    HIWAY: 3588,
    BILAN: 600,
};

export const MyFreelanceProjectStep2 = () => {
    const {t} = useTranslation('revenueSimulator');

    return (
        <FormSection Icon={PersonIcon} title={t('projection.myPersonalCharges')}>
            <FormSectionRow chip={t('projection.fixedCharges')}>
                <FieldBlock variant="compact-horizontal" title={t('charges.setup')} Icon={RocketIcon}>
                    <FixedAmount name="projection.fixedCharges.setup" amount={fixedAmounts.SETUP} />
                </FieldBlock>
                <FieldBlock variant="compact-horizontal" title={t('charges.hiway')} Icon={FinanceViewIcon}>
                    <FixedAmount name="projection.fixedCharges.hiway" amount={fixedAmounts.HIWAY} />
                </FieldBlock>
                <FieldBlock variant="compact-horizontal" title={t('charges.bilan')} Icon={TableViewIcon}>
                    <FixedAmount name="projection.fixedCharges.bilan" amount={fixedAmounts.BILAN} />
                </FieldBlock>
            </FormSectionRow>
            <FormSectionRow chip={t('projection.variableCharges')}>
                <FieldBlock Icon={MedicalServicesIcon} title={t('charges.rcPro')} variant="compact">
                    <EurField
                        defaultValue={430}
                        required
                        name="projection.variableCharges.rcPro"
                        buttonAmounts="disabled"
                    />
                </FieldBlock>
                <FieldBlock Icon={HealthAndSafetyIcon} title={t('charges.health')} variant="compact">
                    <EurField
                        defaultValue={1500}
                        required
                        name="projection.variableCharges.health"
                        buttonAmounts={[1000, 1500, 2000]}
                    />
                </FieldBlock>
                <FieldBlock Icon={CoffeeIcon} title={t('charges.meal')} variant="compact">
                    <EurField
                        defaultValue={2000}
                        required
                        name="projection.variableCharges.meal"
                        buttonAmounts={[1000, 2000, 3000]}
                    />
                </FieldBlock>
                <FieldBlock Icon={TrainIcon} title={t('charges.transport')} variant="compact">
                    <EurField
                        defaultValue={1000}
                        required
                        name="projection.variableCharges.transport"
                        buttonAmounts={[500, 1000, 2500]}
                    />
                </FieldBlock>
            </FormSectionRow>
            <FormSectionRow>
                <FieldBlock Icon={SubscriptionsIcon} title={t('charges.subscription')} variant="compact">
                    <EurField
                        defaultValue={1000}
                        required
                        name="projection.variableCharges.subscription"
                        buttonAmounts={[500, 1000, 1500]}
                    />
                </FieldBlock>
                <FieldBlock Icon={SchoolIcon} title={t('charges.formation')} variant="compact">
                    <EurField
                        defaultValue={2000}
                        required
                        name="projection.variableCharges.formation"
                        buttonAmounts={[1000, 2000, 3000]}
                    />
                </FieldBlock>
                <FieldBlock Icon={DirectionsCarIcon} title={t('charges.ik')} variant="compact">
                    <EurField
                        defaultValue={6000}
                        required
                        name="projection.variableCharges.ik"
                        buttonAmounts={[3000, 6000, 9000]}
                    />
                </FieldBlock>
                <FieldBlock Icon={CategoryIcon} title={t('charges.other')} variant="compact">
                    <EurField
                        defaultValue={1000}
                        required
                        name="projection.variableCharges.other"
                        buttonAmounts={[500, 1000, 1500]}
                    />
                </FieldBlock>
            </FormSectionRow>
        </FormSection>
    );
};
