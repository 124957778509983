import {call, delay, put, select} from 'redux-saga/effects';
import {DatabaseActions} from './database.action';
import {DatabaseSelectors} from './database.selector';
import {END_SIDE_EFFECTS_RUNNING, push} from '../../../../../lib/router/connected-router-saga';
import {Toast} from '../../../../../lib/toast';
import {getSignedUrlForDocumentRequest} from '../../../../../v1/app/api/providers/company/company.provider';
import {isMobileSafari, isSafari} from '../../../../../v1/app/app.helpers';
import {getCompanyId} from '../../../../../v1/app/utils/get-company-id';
import {getFreelancerId} from '../../../../../v1/app/utils/get-freelancer-id';
import {getConfig} from '../../../../../v1/config';
import {
    DOCUMENT_CATEGORIES,
    DOCUMENT_CONTEXTS,
    DOCUMENT_TYPES,
} from '../../../../../v1/config/constants/documentConstants';
import {AccountingSelector} from '../../../../company-profile/modules/accounting/store/accounting.selector';
import {PAST_YEAR} from '../../../../company-profile/modules/accounting/utils/constants';
import {loadFreelancerCompanies} from '../../../../freelancer/modules/companies/store/companies.saga';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {DatabaseApi} from '../api/database.api';
import {DocumentContexts} from '../utils/constants';

export const getDocumentsFlow = function* ({freelancerId, companyId, context}) {
    try {
        const documents = yield call(DatabaseApi.getDocuments, {
            freelancerId,
            companyId,
            context,
        });

        yield put(DatabaseActions.storeDocuments(documents));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});
    }
};

const POLL_INTERVAL = 600; // milliseconds
const POLL_MAX_DURATION = 60000; // milliseconds
export const pollGeneratedDocuments = function* ({freelancerId, companyId, context = DocumentContexts.SIGNABLE}) {
    yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENT_DATABASE, true));
    yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, true));

    let pollDuration = 0;

    while (true) {
        const documents = yield call(DatabaseApi.getDocuments, {
            freelancerId,
            companyId,
            context: context,
        });

        yield put(DatabaseActions.storeDocuments(documents));

        if (!pollDuration) {
            yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENT_DATABASE, false));
        }

        const isDocumentGenerationInProgress = Object.values(documents).some(({status}) => status === 'GENERATING');

        if (pollDuration >= POLL_MAX_DURATION) {
            // TODO: Handle error
            break;
        }

        if (!isDocumentGenerationInProgress) {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, false));

            break;
        }

        pollDuration += POLL_INTERVAL;
        yield delay(POLL_INTERVAL);
    }
};

export const pollDocumentsByCategory = function* ({freelancerId, companyId, category = DOCUMENT_CATEGORIES.BANK}) {
    yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENT_DATABASE, true));
    yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, true));

    let pollDuration = 0;

    while (true) {
        const documents = yield call(DatabaseApi.getDocumentsByCategory, {
            freelancerId,
            companyId,
            category,
        });

        yield put(DatabaseActions.storeDocuments(documents));

        if (!pollDuration) {
            yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENT_DATABASE, false));
        }

        const isDocumentGenerationInProgress = Object.values(documents).some(({status}) => status === 'GENERATING');
        const hasErroredDocuments = Object.values(documents).some(({status}) => status === 'ERROR');

        if (pollDuration >= POLL_MAX_DURATION || hasErroredDocuments) {
            // TODO: Handle error
            break;
        }

        if (!isDocumentGenerationInProgress) {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, false));

            break;
        }

        pollDuration += POLL_INTERVAL;
        yield delay(POLL_INTERVAL);
    }
};

export const pollDocumentsByType = function* ({freelancerId, companyId, type = DOCUMENT_TYPES.BANK_ACCOUNT_DETAILS}) {
    yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENT_DATABASE, true));
    yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, true));

    let pollDuration = 0;

    while (true) {
        const documents = yield call(DatabaseApi.getDocumentsByDocType, {
            freelancerId,
            companyId,
            type,
        });

        yield put(DatabaseActions.storeDocuments(documents));

        if (!pollDuration) {
            yield put(LoadingActions.setLoading(LoadingTypes.DOCUMENT_DATABASE, false));
        }

        const isDocumentGenerationInProgress = Object.values(documents).some(({status}) => status === 'GENERATING');
        const hasErroredDocuments = Object.values(documents).some(({status}) => status === 'ERROR');

        if (pollDuration >= POLL_MAX_DURATION || hasErroredDocuments) {
            // TODO: Handle error
            break;
        }

        if (!isDocumentGenerationInProgress) {
            yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, false));

            break;
        }

        pollDuration += POLL_INTERVAL;
        yield delay(POLL_INTERVAL);
    }
};

// TODO This entire function should not exist, it is a fix for loading documents for CARE users after swan validation
export const delayedLoadDocuments = function* ({freelancerId, companyId, context}) {
    // Wait a second before loading
    yield delay(1000);

    yield call(getDocumentsFlow, {
        freelancerId,
        companyId,
        context,
    });

    const documents = yield select(DatabaseSelectors.selectDocuments);

    const isDocumentGenerationInProgress = Object.values(documents).some(({status}) => status === 'GENERATING');

    if (isDocumentGenerationInProgress) {
        yield call(pollGeneratedDocuments, {freelancerId, companyId, context});
    } else {
        // TODO This is beyond ugly, there must be a better way to do it
        // If for some reason documents are still not generated try again after a few seconds
        // eslint-disable-next-line no-lonely-if
        if (Object.values(documents)?.length === 0) {
            // Wait another 3 seconds before trying again.
            // This is more than enough for server to generate
            yield delay(3000);

            yield call(getDocumentsFlow, {
                freelancerId,
                companyId,
                context,
            });

            const documents = yield select(DatabaseSelectors.selectDocuments);

            const isDocumentGenerationInProgress = Object.values(documents).some(({status}) => status === 'GENERATING');

            if (isDocumentGenerationInProgress) {
                yield call(pollGeneratedDocuments, {freelancerId, companyId, context});
            }
        }
    }
};

export const loadDocuments = function* ({freelancerId, companyId, context}) {
    yield call(getDocumentsFlow, {
        freelancerId,
        companyId,
        context,
    });

    const documents = yield select(DatabaseSelectors.selectDocuments);

    const isDocumentGenerationInProgress = Object.values(documents).some(({status}) => status === 'GENERATING');

    if (isDocumentGenerationInProgress) {
        yield call(pollGeneratedDocuments, {freelancerId, companyId, context});
    }

    yield call(loadFreelancerCompanies, {freelancerId});
};

export const downloadDocumentSaga = function* (payload) {
    try {
        let childWindow;

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow = window.open('', '_blank');
        }

        const {freelancerId, companyId, documentId, isDownload} = payload;

        const {signedUrl} = yield call(
            getSignedUrlForDocumentRequest,
            freelancerId,
            companyId,
            documentId,
            isDownload,
        );

        if (!signedUrl) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('The document URL is missing.');
        }

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow.location = signedUrl;

            return;
        }

        if ((isSafari || isMobileSafari) && payload.isDownload) {
            // TODO:HIGH: It's ugly but it works.
            fetch(signedUrl).then(response => {
                return response.blob();
            }).then(blob => {
                const matchedGroups = signedUrl.match(/filename[^;=\n]*%3D(%22(.*)%22[^;\n]*)/);
                const filename = matchedGroups[2];

                window.saveAs(blob, decodeURI(filename));
            });

            return;
        }

        window.open(signedUrl, '_blank');
    } catch (error) {
        // TODO:LOW Better error handling.
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    }
};

export const loadUnsignedDocumentsSaga = function* () {
    const freelancerId = yield call(getFreelancerId);
    const companyId = yield call(getCompanyId);
    yield call(loadDocuments, {freelancerId, companyId, context: DOCUMENT_CONTEXTS.PENDING_SIGNING});
    const documents = yield select(DatabaseSelectors.selectDocuments);
    if (!Object.keys(documents).length) {
        yield put(push(getConfig().ROUTE_PATHS.DASHBOARD));
        return END_SIDE_EFFECTS_RUNNING;
    }
};

export const loadDepositOfAccountsDocumentsSaga = function* () {
    try {
        const annualAccountsData = yield select(AccountingSelector.selectFinancialStatements);
        const annualAccounts = annualAccountsData[PAST_YEAR] ?? {};

        const transformedDocuments = annualAccounts.generatedDocuments
            // transform fields
            ?.map(doc => ({
                ...doc,
                type: doc.docType,
                category: doc.docCategory,
                status: doc.docStatus,
            }))
            // format correctly
            .reduce((docs, doc) => ({...docs, [doc.id]: doc}), {});

        yield put(DatabaseActions.storeDocuments(transformedDocuments));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    }
};
