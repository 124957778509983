import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import DownloadTwoToneIcon from '@mui/icons-material/DownloadTwoTone';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
import UploadFileTwoToneIcon from '@mui/icons-material/UploadFileTwoTone';
import {Box, Divider, Fade, Paper, Popper, Typography, useMediaQuery} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import {useTranslation} from 'react-i18next';

const DOAFileDropdownOptions = ({
    isOpen,
    onClose,
    onViewFile,
    onReplaceFile,
    onDownloadFile,
    onDeleteFile,
    isEditable,
    hasDelayedUpload,
    anchorEl,
    isError,
    parentWidth,
    isDeletingPossible,
}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation('companies');

    const renderOptions = () => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    py: 1,
                }}
            >
                {!hasDelayedUpload && !isError && (
                    <>
                        {onViewFile && (
                            <>
                                <Box
                                    sx={{
                                        'display': 'flex',
                                        'flexDirection': 'row',
                                        'alignItems': 'center',
                                        'py': 2,
                                        'px': 2,
                                        'cursor': 'pointer',
                                        '&:hover': {
                                            backgroundColor: 'v2.light.primary.shades',
                                        },
                                    }}
                                    onClick={onViewFile}
                                >
                                    <RemoveRedEyeTwoToneIcon sx={{mr: 2, color: 'primary_v2.main'}} />
                                    <Typography
                                        sx={{
                                            fontSize: theme => theme.typography.pxToRem(13),
                                            textTransform: 'uppercase',
                                            fontWeight: 500,
                                            color: 'v2.blueGray.600',
                                        }}
                                    >
                                        {t('formalities.depositOfAccounts.fileDetails.view')}
                                    </Typography>
                                </Box>

                                <Divider sx={{mx: 2}} />
                            </>
                        )}

                        {onDownloadFile && (
                            <Box
                                sx={{
                                    'display': 'flex',
                                    'flexDirection': 'row',
                                    'alignItems': 'center',
                                    'px': 2,
                                    'py': 2,
                                    'cursor': 'pointer',
                                    '&:hover': {
                                        backgroundColor: 'v2.light.primary.shades',
                                    },
                                }}
                                onClick={onDownloadFile}
                            >
                                <DownloadTwoToneIcon sx={{mr: 2, color: 'primary_v2.main'}} />
                                <Typography
                                    sx={{
                                        fontSize: theme => theme.typography.pxToRem(13),
                                        textTransform: 'uppercase',
                                        fontWeight: 500,
                                        color: 'v2.blueGray.600',
                                    }}
                                >
                                    {t('formalities.depositOfAccounts.fileDetails.download')}
                                </Typography>
                            </Box>
                        )}
                    </>
                )}

                {(isEditable || hasDelayedUpload) && (
                    <>
                        {!isError && (
                            <Divider sx={{mx: 2}} />
                        )}

                        {onReplaceFile && (
                            <>
                                <Box
                                    sx={{
                                        'display': 'flex',
                                        'flexDirection': 'row',
                                        'alignItems': 'center',
                                        'px': 2,
                                        'py': 2,
                                        'cursor': 'pointer',
                                        '&:hover': {
                                            backgroundColor: 'v2.light.primary.shades',
                                        },
                                    }}
                                    onClick={onReplaceFile}
                                >
                                    <UploadFileTwoToneIcon sx={{mr: 2, color: 'primary_v2.main'}} />
                                    <Typography
                                        sx={{
                                            fontSize: theme => theme.typography.pxToRem(13),
                                            textTransform: 'uppercase',
                                            fontWeight: 500,
                                            color: 'v2.blueGray.600',
                                        }}
                                    >
                                        {t('formalities.depositOfAccounts.fileDetails.replace')}
                                    </Typography>
                                </Box>
                            </>
                        )}

                        {onDeleteFile && (
                            <>
                                <Divider sx={{mx: 2}} />
                                <Box
                                    sx={{
                                        'display': 'flex',
                                        'flexDirection': 'row',
                                        'alignItems': 'center',
                                        'px': 2,
                                        'py': 2,
                                        'cursor': isDeletingPossible ? 'pointer' : 'not-allowed',
                                        '&:hover': isDeletingPossible ? {
                                            backgroundColor: 'v2.light.primary.shades',
                                        } : {},
                                    }}
                                    onClick={onDeleteFile}
                                >
                                    <DeleteTwoToneIcon sx={{mr: 2, color: 'v2.light.error.main'}} />
                                    <Typography
                                        sx={{
                                            fontSize: theme => theme.typography.pxToRem(13),
                                            textTransform: 'uppercase',
                                            fontWeight: 500,
                                            color: 'v2.light.error.main',
                                        }}
                                    >
                                        {t('formalities.depositOfAccounts.fileDetails.delete')}
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </>
                )}
            </Box>
        );
    };

    if (!isMobileSize) {
        return (
            <Popper
                open={isOpen}
                anchorEl={anchorEl}
                transition
                sx={{
                    zIndex: '433',
                }}
            >
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper
                            sx={{
                                width: parentWidth ?? '328px',
                                borderRadius: '16px',
                            }}
                            elevation={8}
                        >
                            {renderOptions()}
                        </Paper>
                    </Fade>
                )}
            </Popper>
        );
    }

    return (
        <Drawer
            anchor="bottom"
            open={isOpen}
            onClose={onClose}
            sx={{
                '.MuiDrawer-paperAnchorBottom': {
                    borderTopRightRadius: '32px',
                    borderTopLeftRadius: '32px',
                },
            }}
        >
            <Box
                sx={{
                    minHeight: '100px',
                    borderTopRightRadius: '32px',
                    borderTopLeftRadius: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                    px: 3,
                }}
            >
                {renderOptions()}
            </Box>
        </Drawer>
    );
};

export default DOAFileDropdownOptions;
