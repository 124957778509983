import {
    faChevronDown,
    faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, List, ListItem, ListItemContent, Typography} from '@mui/joy';
import {Collapse} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ItemWithLink} from './ItemWithLink';
import {selectRouterLocation} from '../../../../lib/router/connected-router-saga';

export const ExpandedItemWithSubmenu = ({
    listItem,
    collapsedLists,
    createCollapsedListClickHandler,
}) => {
    const {t} = useTranslation('sidebar');

    const location = useSelector(selectRouterLocation);

    let isActive = listItem.path === '/'
        ? location.pathname === '/'
        : location.pathname.startsWith(listItem.path);

    const listItemWithActive = listItem.subItems.map(item => {
        const isSubMenuActive = location.pathname === item.path;
        if (isSubMenuActive) {
            isActive = true;
        }
        return {...item, isActive: isSubMenuActive};
    });

    const isCollapsed = collapsedLists.includes(listItem.id);

    useEffect(() => {
        if (!isCollapsed && isActive) {
            createCollapsedListClickHandler(listItem.id)();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <ListItem
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '224px',
                    height: '36px',
                    p: 1,
                    mt: 1,
                    cursor: 'pointer',
                    borderRadius: 'sm',
                    ...(isActive ? {
                        backgroundColor: 'neutral.plainColor',
                    } : {
                        '&:hover': {
                            backgroundColor: 'background.level2',
                        },
                    }),
                }}
                onClick={createCollapsedListClickHandler(listItem.id)}
            >
                {listItem.icon && (
                    <Box
                        color={isActive ? 'primary.solidColor' : 'text.icon'}
                        fontSize="md"
                        sx={{mr: 1}}
                    >
                        <FontAwesomeIcon icon={listItem.icon} fixedWidth />
                    </Box>
                )}

                <ListItemContent>
                    <Typography
                        level="body-sm"
                        sx={{color: isActive ? 'primary.solidColor' : 'text.primary'}}
                    >
                        {t(listItem.title)}
                    </Typography>
                </ListItemContent>

                <Box
                    color={isActive ? 'primary.solidColor' : 'text.icon'}
                    fontSize="md"
                    sx={{cursor: 'pointer'}}
                >
                    <FontAwesomeIcon
                        icon={isCollapsed ? faChevronUp : faChevronDown}
                        fixedWidth
                    />
                </Box>
            </ListItem>
            <Collapse
                in={isCollapsed}
                timeout="auto"
                unmountOnExit
            >
                <List
                    component="ul"
                    disablePadding
                    sx={{display: 'flex', flexDirection: 'column', pt: 1, pb: 0}}
                >
                    {listItemWithActive.map(subItem => {
                        return (
                            <ItemWithLink
                                to={subItem.path}
                                key={subItem.title}
                            >
                                <ListItem
                                    component="li"
                                    sx={{
                                        'display': 'list-item',
                                        'listStylePosition': 'inside',
                                        'py': 0.5,
                                        'px': 1,
                                        'ml': 2,
                                        'mr': 1,
                                        'mb': 1,
                                        'minHeight': 'auto',
                                        'borderRadius': 'xs',
                                        'backgroundColor': subItem.isActive ? 'background.level2' : 'transparent',
                                        '&::marker': {
                                            color: subItem.isActive ? 'primary.900' : 'primary.100',
                                            mr: 1,
                                        },
                                        '&:hover': {
                                            color: 'text.primary',
                                            backgroundColor: 'background.level2',
                                            borderRadius: 'sm',
                                        },
                                    }}
                                >
                                    <Typography
                                        component="span"
                                        level="body-sm"
                                        sx={{
                                            color: subItem.isActive ? 'text.primary' : 'text.secondary',
                                            display: 'inline',
                                        }}
                                    >
                                        {t(subItem.title)}
                                    </Typography>
                                </ListItem>
                            </ItemWithLink>
                        );
                    })}
                </List>
            </Collapse>
        </>
    );
};

ExpandedItemWithSubmenu.propTypes = {
    listItem: PropTypes.object.isRequired,
    collapsedLists: PropTypes.array,
    createCollapsedListClickHandler: PropTypes.func.isRequired,
};

ExpandedItemWithSubmenu.defaultProps = {
    collapsedLists: [],
};
