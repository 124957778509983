import {Box} from '@mui/material';
import {PAYMENT_TYPES, TransactionSide, TransactionStatus} from './constants';
import {ConditionalTooltip} from '../../../../../components/tooltip/ConditionalTooltip';

const calculateMainContainerOpenWidth = () => {
    if (!document.getElementById('mainContainer')) {
        return '100%';
    }
    const rightMargin = getComputedStyle(document.getElementById('mainContainer')).marginRight;

    if (parseInt(rightMargin, 10) > 368) {
        return '100%';
    }

    if (parseInt(rightMargin, 10) === 0) {
        return `calc(100% - 400px + 32px)`;
    }

    return `calc(100% - 400px + 32px + ${rightMargin})`;
};

const calculateScrollableContainerOpenWidth = () => {
    if (!document.getElementById('mainContainer')) {
        return '100%';
    }
    const rightMargin = getComputedStyle(document.getElementById('mainContainer')).marginRight;

    if (parseInt(rightMargin, 10) > 368) {
        return '100%';
    }

    if (parseInt(rightMargin, 10) === 0) {
        return `calc(100% - 400px - 32px)`;
    }

    return `calc(100% - 400px - 32px + ${rightMargin})`;
};

const calculateShouldFieldDissapearOnOpen = () => {
    if (!document.getElementById('mainContainer')) {
        return false;
    }
    const rightMargin = getComputedStyle(document.getElementById('mainContainer')).marginRight;

    return !(parseInt(rightMargin, 10) > 368);
};

const getTransactionLabel = data => {
    if (
        !!data?.creditorName
        && data?.side === 'DEBIT'
        && [
            PAYMENT_TYPES.DIRECT_DEBIT,
            PAYMENT_TYPES.TRANSFER_OUT,
            PAYMENT_TYPES.CREDIT_CARD_PAYMENT,
        ].includes(data?.type)
    ) {
        return data.creditorName;
    }
    if (
        !!data?.debtorName
        && data?.side === TransactionSide.CREDIT
        && [
            PAYMENT_TYPES.TRANSFER_IN,
            PAYMENT_TYPES.CREDIT_CARD_PAYMENT,
        ].includes(data?.type)
    ) {
        return data.debtorName;
    }
    return data?.label;
};

const showLabelIcon = (data, combinedTabIcons) => {
    if (combinedTabIcons && combinedTabIcons?.[data?.bankAccountId]) {
        return (
            <Box
                component="span"
                sx={{
                    ml: 1,
                    position: 'relative',
                    top: '6px',
                }}
            >
                <ConditionalTooltip tooltip={combinedTabIcons[data.bankAccountId].name}>
                    {combinedTabIcons[data.bankAccountId].icon}
                </ConditionalTooltip>
            </Box>
        );
    }
    return null;
};

const getColorAndIconName = ({
    selectCategoriesObject,
    selectSourcesObject,
    category,
    sourceOfRevenue,
}) => {
    let color;
    let iconName;
    const categoryName = Array.isArray(category)
        ? category?.[0]
        : category;

    if (categoryName) {
        color = selectCategoriesObject[categoryName]?.color;
        iconName = selectCategoriesObject[categoryName]?.iconName;
    } else if (sourceOfRevenue) {
        color = selectSourcesObject[sourceOfRevenue]?.color;
        iconName = selectSourcesObject[sourceOfRevenue]?.iconName;
    }
    return {
        color,
        iconName,
    };
};

const getCategorizationState = data => {
    const isRejected = data.status === TransactionStatus.REJECTED;
    const isCancelled = data.status === TransactionStatus.CANCELED;
    const isOnHold = data.status === TransactionStatus.PENDING;
    const isUpcoming = data.status === TransactionStatus.UPCOMING;
    const isNotCategorized = data.hasChip;
    const isCategorized = data.isCategorized;
    const isAutoCategorized = data.autoCategorized;
    const hasActiveSubscription = data.hasActiveSubscription;

    return {
        isRejected,
        isCancelled,
        isOnHold,
        isUpcoming,
        isNotCategorized,
        isCategorized,
        isAutoCategorized,
        hasActiveSubscription,
    };
};

export const AccountBalanceHelperFunctions = {
    calculateMainContainerOpenWidth,
    calculateScrollableContainerOpenWidth,
    calculateShouldFieldDissapearOnOpen,
    getTransactionLabel,
    showLabelIcon,
    getColorAndIconName,
    getCategorizationState,
};
