import {yupResolver} from '@hookform/resolvers/yup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import {LoadingButton} from '@mui/lab';
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel, Grow,
    Paper,
    Radio,
    RadioGroup, Slide,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import * as Yup from 'yup';

import {useDocumentsUploadFormStyles} from './styles';
import {PageHeader} from '../../../../components/page-header/PageHeader';
import {AnimationActions} from '../../../../features/animations/store/animations.action';
import {AnimationsSelectors} from '../../../../features/animations/store/animations.selector';
import {ANIMATION_TYPE, FORM_ANIMATION_DELAY} from '../../../../features/animations/utils/constants';
import {CompanySetupSubSteps} from '../../../../features/freelancer/modules/company-setup/utils/constants';
import {OnboardingActions} from '../../../../features/freelancer/modules/onboarding/store/onboarding.action';
import {selectCompanyDocuments} from '../../../app/company/company.selectors';
import {createSelectedCompanySelector} from '../../../app/company/currentCompany/currentCompany.selectors';
import {submitActiveStepForm} from '../../../app/company/setupCompany/setupCompany.actions';
import {
    IDENTIFICATION_DOCUMENT_TYPES,
    INSURANCE_DOCUMENT_TYPES,
} from '../../../app/company/setupCompany/setupCompany.constants';
import {getIsLoadingSetupCompanyForm} from '../../../app/company/setupCompany/setupCompany.selectors';
import {COMPANY_TYPES} from '../../../config/constants/companyConstants';
import DropZone from '../../ui-kit/DropZone';
import DocumentUploadStepTitle from '../DocumentUploadStepTitle';

const defaultValues = {
    hostIdentificationDocumentType: IDENTIFICATION_DOCUMENT_TYPES.ID_CARD,
    identificationDocument: null,
    identificationDocumentType: IDENTIFICATION_DOCUMENT_TYPES.ID_CARD,
    insuranceDocument: null,
    insuranceDocumentType: INSURANCE_DOCUMENT_TYPES.NATIONAL_INSURANCE_CARD,
    isUserHostedByThirdParty: false,
    residenceCertificationDocument: null,
};

export const DocumentsUploadForm = ({hasSubmitModificationsButton}) => {
    const styles = useDocumentsUploadFormStyles();
    const {t} = useTranslation('setup');
    const dispatch = useDispatch();

    const company = useSelector(createSelectedCompanySelector());
    const documents = useSelector(selectCompanyDocuments);
    const isLoadingForm = useSelector(getIsLoadingSetupCompanyForm);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);
    const isSubAnimationActive = useSelector(AnimationsSelectors.selectIsSubAnimationActive);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    /**
     * @type {React.MutableRefObject<HTMLFormElement>}
     */
    const formRef = useRef(null);

    const validationSchema = Yup.object().shape({
        identificationDocumentType: Yup
            .string()
            .oneOf(Object.values(IDENTIFICATION_DOCUMENT_TYPES))
            .required(t('validations.isRequired', {what: t('identificationDocument')})),
        identificationDocument: Yup.array().nullable()
            .required(t('validations.isRequired', {what: t('identificationDocument')})),
        residenceCertificationDocument: Yup.array().nullable()
            .required(t('validations.isRequired', {what: t('residenceCertificationDocument')})),
        isUserHostedByThirdParty: Yup.bool(),
        hostIdentificationDocumentType: Yup
            .string()
            .nullable()
            .when('isUserHostedByThirdParty', {
                is: true,
                then: Yup
                    .string()
                    .oneOf(Object.values(IDENTIFICATION_DOCUMENT_TYPES))
                    .required(t('validations.isRequired', {what: t('identificationDocumentType')})),
            }),
        hostCertificationDocument: Yup
            .array()
            .nullable()
            .when('isUserHostedByThirdParty', {
                is: true,
                then: Yup.array().required(t('validations.isRequired', {what: t('hostCertificationDocument')})),
            }),
        hostIdentificationDocument: Yup
            .array()
            .nullable()
            .when('isUserHostedByThirdParty', {
                is: true,
                then: Yup.array().required(t('validations.isRequired', {what: t('hostIdentificationDocument')})),
            }),
        insuranceDocumentType: Yup
            .string()
            .oneOf(Object
                .values(INSURANCE_DOCUMENT_TYPES))
            .required(t('validations.isRequired', {what: t('insuranceDocumentType')})),
        insuranceDocument: Yup.array().nullable()
            .required(t('validations.isRequired', {what: t('insuranceDocument')})),
    });

    const {
        control,
        formState: {errors, isDirty, isValid, dirtyFields},
        handleSubmit: createSubmitHandler,
        register,
        setValue,
        unregister,
        watch,
        reset,
        trigger,
    } = useForm({
        mode: 'onChange',
        defaultValues,
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        register('identificationDocument', {
            required: true,
        });

        register('residenceCertificationDocument', {
            required: true,
        });

        register('hostCertificationDocument', {
            required: true,
        });

        register('hostIdentificationDocument', {
            required: true,
        });

        register('insuranceDocument', {
            required: true,
        });

        return () => {
            unregister('hostIdentificationDocument');
            unregister('hostCertificationDocument');
            unregister('identificationDocument');
            unregister('insuranceDocument');
            unregister('residenceCertificationDocument');
        };
    }, [register, unregister]);

    useEffect(() => {
        if (!documents || !Object.keys(documents).length) {
            return;
        }

        const formData = {
            hostIdentificationDocumentType: documents?.hostIdentificationDocument?.subType
                || IDENTIFICATION_DOCUMENT_TYPES.ID_CARD,
            identificationDocumentType: documents?.identificationDocument?.subType
                || IDENTIFICATION_DOCUMENT_TYPES.ID_CARD,
            insuranceDocumentType: documents?.insuranceDocument?.subType
                || INSURANCE_DOCUMENT_TYPES.NATIONAL_INSURANCE_CARD,
            isUserHostedByThirdParty: !!documents?.hostIdentificationDocument
                && !!documents?.hostCertificationDocument,
            hostIdentificationDocument: documents?.hostIdentificationDocument
                ? [new File([], documents.hostIdentificationDocument.fileName)]
                : null,
            hostCertificationDocument: documents?.hostCertificationDocument
                ? [new File([], documents.hostCertificationDocument.fileName)]
                : null,
            identificationDocument: documents?.identificationDocument
                ? [new File([], documents.identificationDocument.fileName)]
                : null,
            insuranceDocument: documents?.insuranceDocument
                ? [new File([], documents.insuranceDocument.fileName)]
                : null,
            residenceCertificationDocument: documents?.residenceCertificationDocument
                ? [new File([], documents.residenceCertificationDocument.fileName)]
                : null,
        };

        reset(formData);

        // Just making sure that the user sees the error
        if (company?.type === COMPANY_TYPES.HOLDING) {
            let fields = ['residenceCertificationDocument'];

            if (formData.isUserHostedByThirdParty) {
                fields = [...fields, 'hostIdentificationDocument', 'hostCertificationDocument'];
            }

            setTimeout(() => {
                trigger(fields).then();
            }, 0);
        }
        // eslint-disable-next-line
    }, [documents, reset]);

    const hostIdentificationDocument = watch('hostIdentificationDocument');
    const hostIdentificationDocumentType = watch('hostIdentificationDocumentType');
    const hostCertificationDocument = watch('hostCertificationDocument');
    const identificationDocument = watch('identificationDocument');
    const insuranceDocument = watch('insuranceDocument');
    const isUserHostedByThirdParty = watch('isUserHostedByThirdParty');
    const residenceCertificationDocument = watch('residenceCertificationDocument');

    const createDropAcceptedHandler = useCallback(name => files => {
        setValue(name, files, {
            shouldDirty: true,
            shouldValidate: true,
        });
    }, [setValue]);

    const createDeleteFileHandler = useCallback(name => () => {
        setValue(name, null, {
            shouldDirty: true,
            shouldValidate: true,
        });
    }, [setValue]);

    const handleSubmit = useCallback(data => {
        const {
            hostIdentificationDocument,
            hostCertificationDocument,
            identificationDocument,
            insuranceDocument,
            residenceCertificationDocument,
            ...rest
        } = data;

        dispatch(submitActiveStepForm({
            ...rest,
            hostIdentificationDocument: hostIdentificationDocument ? hostIdentificationDocument[0] : null,
            hostCertificationDocument: hostCertificationDocument ? hostCertificationDocument[0] : null,
            identificationDocument: identificationDocument ? identificationDocument[0] : null,
            insuranceDocument: insuranceDocument ? insuranceDocument[0] : null,
            residenceCertificationDocument: residenceCertificationDocument ? residenceCertificationDocument[0] : null,
        }, isDirty, dirtyFields, hasSubmitModificationsButton));
    }, [dispatch, dirtyFields, isDirty, hasSubmitModificationsButton]);

    const identificationDocumentTypeOptions = useMemo(() => {
        return Object.values(IDENTIFICATION_DOCUMENT_TYPES).map(identificationDocumentType => (
            <FormControlLabel
                className={styles.radioLabel}
                control={<Radio color="primary" />}
                key={identificationDocumentType}
                label={t(`documents:identificationDocumentTypes.${identificationDocumentType}`)}
                value={identificationDocumentType}
            />
        ));
    }, [styles.radioLabel, t]);

    const insuranceDocumentTypeOptions = useMemo(() => {
        return Object.values(INSURANCE_DOCUMENT_TYPES).map(insuranceDocumentType => (
            <FormControlLabel
                className={styles.radioLabel}
                control={<Radio color="primary" />}
                key={insuranceDocumentType}
                label={t(`documents:insuranceDocumentTypes.${insuranceDocumentType}`)}
                value={insuranceDocumentType}
            />
        ));
    }, [styles.radioLabel, t]);

    const onEnteredAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
        window.scrollTo(0, 0);
    };
    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    if (!isMobileSize) {
        return (
            <form ref={formRef} onSubmit={createSubmitHandler(handleSubmit)} noValidate>
                <PageHeader subTitle={t('stepSubtitles.documentsUpload')}>
                    {t('stepTitles.documentsUpload')}
                </PageHeader>

                <Slide
                    direction="left"
                    in={isAnimationActive}
                    timeout={FORM_ANIMATION_DELAY}
                    onEntered={onEnteredAnimation}
                    onExited={onEndedAnimation}
                >
                    <Paper radius={16} elevation={2} sx={{px: 5, py: 4}}>
                        <div className={styles.documentUploadStepContainer}>
                            <DocumentUploadStepTitle
                                title={t('identificationDocument')}
                                stepNumber="A"
                                isCompleted={!!identificationDocument}
                            />

                            <Grow
                                in={isSubAnimationActive}
                                timeout={FORM_ANIMATION_DELAY}
                                onExited={() => {
                                    dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.SECONDARY));
                                }}
                            >
                                <div className={styles.documentUploadStepContent}>
                                    <FormControl component="fieldset">
                                        <Controller
                                            control={control}
                                            name="identificationDocumentType"
                                            render={({field: {value, onChange, ...rest}}) => (
                                                <RadioGroup
                                                    aria-label="identification-type"
                                                    row
                                                    value={value}
                                                    onChange={event => {
                                                        onChange(event);

                                                        setValue('identificationDocument', null);
                                                    }}
                                                    {...rest}
                                                >
                                                    {identificationDocumentTypeOptions}
                                                </RadioGroup>
                                            )}
                                        />
                                    </FormControl>

                                    <Alert severity="info">
                                        <p className={styles.alertParagraph}>
                                            <span>{t('paragraphs.pleaseScanBothSidesHandwrite')}</span>&nbsp;
                                            <strong>{t('paragraphs.iUndersignedCertify')}</strong>
                                        </p>

                                        <p><strong>{t('paragraphs.signatureMustInclude')}</strong></p>
                                    </Alert>

                                    <DropZone
                                        acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                                        errorMessage={errors.identificationDocument?.message}
                                        files={identificationDocument}
                                        onDeleteFile={createDeleteFileHandler('identificationDocument')}
                                        onDropAccepted={createDropAcceptedHandler('identificationDocument')}
                                        name="identification-document"
                                    />
                                </div>
                            </Grow>
                        </div>

                        <Divider className={styles.divider} />

                        <div className={styles.documentUploadStepContainer}>
                            <DocumentUploadStepTitle
                                title={t('residenceCertification')}
                                stepNumber="B"
                                isCompleted={!!residenceCertificationDocument && (!isUserHostedByThirdParty
                                || (isUserHostedByThirdParty
                                        && hostCertificationDocument
                                        && hostIdentificationDocument))}
                            />

                            <Grow
                                in={isSubAnimationActive}
                                timeout={FORM_ANIMATION_DELAY * 2}
                            >
                                <div className={styles.documentUploadStepContent}>
                                    <FormLabel component="legend" className={styles.formLabel} required>
                                        {t('scanOfUtilityBill')}
                                    </FormLabel>

                                    <Alert severity="info">
                                        <p className={styles.alertParagraph}>
                                            <Trans i18nKey="paragraphs.documentMustBeDated" t={t}>
                                                paragraph <strong>strong</strong> paragraph
                                            </Trans>
                                        </p>
                                        <p>
                                            <Trans i18nKey="paragraphs.documentTypeTaxNotice" t={t}>
                                                <strong>strong</strong> paragraph
                                            </Trans>
                                        </p>
                                    </Alert>

                                    <DropZone
                                        acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                                        errorMessage={errors.residenceCertificationDocument?.message}
                                        files={residenceCertificationDocument}
                                        onDeleteFile={createDeleteFileHandler('residenceCertificationDocument')}
                                        onDropAccepted={createDropAcceptedHandler('residenceCertificationDocument')}
                                        name="residence-certification-document"
                                    />

                                    <FormControl component="fieldset" className={styles.fieldset}>
                                        <Controller
                                            control={control}
                                            name="isUserHostedByThirdParty"
                                            render={({field: {value, onChange, ...rest}}) => (
                                                <FormControlLabel
                                                    checked={value}
                                                    className={styles.checkboxLabel}
                                                    control={<Checkbox color="primary" />}
                                                    label={t('isUserHostedByThirdParty')}
                                                    onChange={event => onChange(event?.target?.checked)}
                                                    value={true}
                                                    {...rest}
                                                />
                                            )}
                                        />
                                    </FormControl>

                                    {isUserHostedByThirdParty && (
                                    <div className={styles.hostCertificationContainer}>
                                        <FormLabel component="legend" className={styles.formLabel} required>
                                            {t('mustBeSignedByHost')}
                                        </FormLabel>

                                        <DropZone
                                            acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                                            errorMessage={errors.hostCertificationDocument?.message}
                                            files={hostCertificationDocument}
                                            onDeleteFile={createDeleteFileHandler('hostCertificationDocument')}
                                            onDropAccepted={createDropAcceptedHandler('hostCertificationDocument')}
                                            name="host-certification-document"
                                        />

                                        <FormLabel
                                            component="legend"
                                            className={clsx(styles.formLabel, styles.hostIdentificationDocumentLabel)}
                                            required
                                        >
                                            {t('hostIdentificationDocument')}
                                        </FormLabel>

                                        <FormControl component="fieldset">
                                            <Controller
                                                control={control}
                                                name="hostIdentificationDocumentType"
                                                render={({field: {value, onChange, ...rest}}) => (
                                                    <RadioGroup
                                                        aria-label="host-identification-type"
                                                        row
                                                        value={value}
                                                        onChange={event => {
                                                            onChange(event);

                                                            setValue('hostIdentificationDocument', null);
                                                        }}
                                                        {...rest}
                                                    >
                                                        {identificationDocumentTypeOptions}
                                                    </RadioGroup>
                                                )}
                                            />
                                        </FormControl>

                                        <FormLabel component="legend" className={styles.formLabel} required>
                                            {hostIdentificationDocumentType === 'id-card' ? t('scanOfHostId') : t('scanOfHostPassport')}
                                        </FormLabel>

                                        <Alert severity="info">
                                            <p className={styles.alertParagraph}>
                                                <span>{t('paragraphs.pleaseScanBothSidesOfHostsDocument')}</span>&nbsp;

                                                <strong>{t('paragraphs.iUndersignedCertify')}</strong>
                                            </p>

                                            <p><strong>{t('paragraphs.signatureMustInclude')}</strong></p>
                                        </Alert>

                                        <DropZone
                                            acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                                            errorMessage={errors.hostIdentificationDocument?.message}
                                            files={hostIdentificationDocument}
                                            onDeleteFile={createDeleteFileHandler('hostIdentificationDocument')}
                                            onDropAccepted={createDropAcceptedHandler('hostIdentificationDocument')}
                                            name="host-identification-document"
                                        />
                                    </div>
                                    )}
                                </div>
                            </Grow>
                        </div>

                        <Divider className={styles.divider} />

                        <div className={styles.documentUploadStepContainer}>
                            <DocumentUploadStepTitle
                                title={t('scanOfDocumentsWithNin')}
                                stepNumber="C"
                                isCompleted={!!insuranceDocument}
                            />

                            <Grow
                                in={isSubAnimationActive}
                                timeout={FORM_ANIMATION_DELAY * 3}
                            >
                                <div className={styles.documentUploadStepContent}>
                                    <FormControl component="fieldset">
                                        <Controller
                                            control={control}
                                            name="insuranceDocumentType"
                                            render={({field: {value, onChange, ...rest}}) => (
                                                <RadioGroup
                                                    aria-label="insurance-type"
                                                    row
                                                    value={value}
                                                    onChange={event => {
                                                        onChange(event);

                                                        setValue('insuranceDocument', null);
                                                    }}
                                                    {...rest}
                                                >
                                                    {insuranceDocumentTypeOptions}
                                                </RadioGroup>
                                            )}
                                        />
                                    </FormControl>

                                    <DropZone
                                        acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                                        errorMessage={errors.insuranceDocument?.message}
                                        files={insuranceDocument}
                                        onDeleteFile={createDeleteFileHandler('insuranceDocument')}
                                        onDropAccepted={createDropAcceptedHandler('insuranceDocument')}
                                        name="insurance-document"
                                    />
                                </div>
                            </Grow>
                        </div>

                        {/* <SetupCompanyFormFooter */}
                        {/*    isSubmitButtonDisabled={!isValid} */}
                        {/*    areBothButtonsDisabled={isLoading} */}
                        {/*    buttonTitle={t('complete')} */}
                        {/*    onBackButtonClick={handleBackButtonClick} */}
                        {/*    onSubmitForApprovalButtonClick={handleSubmitForApprovalButtonClick} */}
                        {/*    isLastStep */}
                        {/* /> */}
                    </Paper>
                </Slide>

                <Box display="flex" justifyContent="space-between" sx={{mt: 3}}>
                    <Slide
                        direction="right"
                        in={isAnimationActive}
                        timeout={FORM_ANIMATION_DELAY * 4}
                    >
                        <Button
                            size="large"
                            variant="outlined"
                            color="primary"
                            startIcon={<ArrowBackIcon />}
                            onClick={() => {
                                dispatch(OnboardingActions.setSubStep(CompanySetupSubSteps.BANK));
                                dispatch(OnboardingActions.setInternalSubStep(CompanySetupSubSteps.BANK));
                            }}
                        >
                            {t('back')}
                        </Button>
                    </Slide>

                    <Slide
                        direction="left"
                        in={isAnimationActive}
                        timeout={FORM_ANIMATION_DELAY * 4}
                    >
                        <div>
                            {hasSubmitModificationsButton ? (
                                <LoadingButton
                                    loading={isLoadingForm}
                                    size="large"
                                    variant="contained"
                                    color="primary"
                                    startIcon={<SaveIcon />}
                                    type="submit"
                                    disabled={!isValid}
                                >
                                    {t('submitForApproval')}
                                </LoadingButton>
                            ) : (
                                <LoadingButton
                                    loading={isLoadingForm}
                                    size="large"
                                    variant="contained"
                                    color="secondary"
                                    endIcon={<ArrowForwardIcon />}
                                    disabled={!isValid}
                                    type="submit"
                                >
                                    {t('saveAndProceed')}
                                </LoadingButton>
                            )}
                        </div>
                    </Slide>
                </Box>
            </form>
        );
    }

    return (
        <form ref={formRef} onSubmit={createSubmitHandler(handleSubmit)} noValidate>
            <PageHeader subTitle={t('stepSubtitles.documentsUpload')}>
                {t('stepTitles.documentsUpload')}
            </PageHeader>

            <Paper radius={16} elevation={2} sx={{px: 5, py: 4}}>
                <div className={styles.documentUploadStepContainer}>
                    <DocumentUploadStepTitle
                        title={t('identificationDocument')}
                        stepNumber="A"
                        isCompleted={!!identificationDocument}
                    />
                    <div className={styles.documentUploadStepContent}>
                        <FormControl component="fieldset">
                            <Controller
                                control={control}
                                name="identificationDocumentType"
                                render={({field: {value, onChange, ...rest}}) => (
                                    <RadioGroup
                                        aria-label="identification-type"
                                        row
                                        value={value}
                                        onChange={event => {
                                            onChange(event);

                                            setValue('identificationDocument', null);
                                        }}
                                        {...rest}
                                    >
                                        {identificationDocumentTypeOptions}
                                    </RadioGroup>
                                )}
                            />
                        </FormControl>

                        <Alert severity="info">
                            <p className={styles.alertParagraph}>
                                <span>{t('paragraphs.pleaseScanBothSidesHandwrite')}</span>&nbsp;
                                <strong>{t('paragraphs.iUndersignedCertify')}</strong>
                            </p>

                            <p><strong>{t('paragraphs.signatureMustInclude')}</strong></p>
                        </Alert>

                        <DropZone
                            acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                            errorMessage={errors.identificationDocument?.message}
                            files={identificationDocument}
                            onDeleteFile={createDeleteFileHandler('identificationDocument')}
                            onDropAccepted={createDropAcceptedHandler('identificationDocument')}
                            name="identification-document"
                        />
                    </div>
                </div>

                <Divider className={styles.divider} />

                <div className={styles.documentUploadStepContainer}>
                    <DocumentUploadStepTitle
                        title={t('residenceCertification')}
                        stepNumber="B"
                        isCompleted={!!residenceCertificationDocument && (!isUserHostedByThirdParty
                                || (isUserHostedByThirdParty
                                && hostCertificationDocument
                                && hostIdentificationDocument))}
                    />

                    <div className={styles.documentUploadStepContent}>
                        <FormLabel component="legend" className={styles.formLabel} required>
                            {t('scanOfUtilityBill')}
                        </FormLabel>

                        <Alert severity="info">
                            <p className={styles.alertParagraph}>
                                <Trans i18nKey="paragraphs.documentMustBeDated" t={t}>
                                    paragraph <strong>strong</strong> paragraph
                                </Trans>
                            </p>
                            <p>
                                <Trans i18nKey="paragraphs.documentTypeTaxNotice" t={t}>
                                    <strong>strong</strong> paragraph
                                </Trans>
                            </p>
                        </Alert>

                        <DropZone
                            acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                            errorMessage={errors.residenceCertificationDocument?.message}
                            files={residenceCertificationDocument}
                            onDeleteFile={createDeleteFileHandler('residenceCertificationDocument')}
                            onDropAccepted={createDropAcceptedHandler('residenceCertificationDocument')}
                            name="residence-certification-document"
                        />

                        <FormControl component="fieldset" className={styles.fieldset}>
                            <Controller
                                control={control}
                                name="isUserHostedByThirdParty"
                                render={({field: {value, onChange, ...rest}}) => (
                                    <FormControlLabel
                                        checked={value}
                                        className={styles.checkboxLabel}
                                        control={<Checkbox color="primary" />}
                                        label={t('isUserHostedByThirdParty')}
                                        onChange={event => onChange(event?.target?.checked)}
                                        value={true}
                                        {...rest}
                                    />
                                )}
                            />
                        </FormControl>

                        {isUserHostedByThirdParty && (
                            <div className={styles.hostCertificationContainer}>
                                <FormLabel component="legend" className={styles.formLabel} required>
                                    {t('mustBeSignedByHost')}
                                </FormLabel>

                                <DropZone
                                    acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                                    errorMessage={errors.hostCertificationDocument?.message}
                                    files={hostCertificationDocument}
                                    onDeleteFile={createDeleteFileHandler('hostCertificationDocument')}
                                    onDropAccepted={createDropAcceptedHandler('hostCertificationDocument')}
                                    name="host-certification-document"
                                />

                                <FormLabel
                                    component="legend"
                                    className={clsx(styles.formLabel, styles.hostIdentificationDocumentLabel)}
                                    required
                                >
                                    {t('hostIdentificationDocument')}
                                </FormLabel>

                                <FormControl component="fieldset">
                                    <Controller
                                        control={control}
                                        name="hostIdentificationDocumentType"
                                        render={({field: {value, onChange, ...rest}}) => (
                                            <RadioGroup
                                                aria-label="host-identification-type"
                                                row
                                                value={value}
                                                onChange={event => {
                                                    onChange(event);

                                                    setValue('hostIdentificationDocument', null);
                                                }}
                                                {...rest}
                                            >
                                                {identificationDocumentTypeOptions}
                                            </RadioGroup>
                                        )}
                                    />
                                </FormControl>

                                <FormLabel component="legend" className={styles.formLabel} required>
                                    {hostIdentificationDocumentType === 'id-card' ? t('scanOfHostId') : t('scanOfHostPassport')}
                                </FormLabel>

                                <Alert severity="info">
                                    <p className={styles.alertParagraph}>
                                        <span>{t('paragraphs.pleaseScanBothSidesOfHostsDocument')}</span>&nbsp;

                                        <strong>{t('paragraphs.iUndersignedCertify')}</strong>
                                    </p>

                                    <p><strong>{t('paragraphs.signatureMustInclude')}</strong></p>
                                </Alert>

                                <DropZone
                                    acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                                    errorMessage={errors.hostIdentificationDocument?.message}
                                    files={hostIdentificationDocument}
                                    onDeleteFile={createDeleteFileHandler('hostIdentificationDocument')}
                                    onDropAccepted={createDropAcceptedHandler('hostIdentificationDocument')}
                                    name="host-identification-document"
                                />
                            </div>
                        )}
                    </div>
                </div>

                <Divider className={styles.divider} />

                <div className={styles.documentUploadStepContainer}>
                    <DocumentUploadStepTitle
                        title={t('scanOfDocumentsWithNin')}
                        stepNumber="C"
                        isCompleted={!!insuranceDocument}
                    />

                    <div className={styles.documentUploadStepContent}>
                        <FormControl component="fieldset">
                            <Controller
                                control={control}
                                name="insuranceDocumentType"
                                render={({field: {value, onChange, ...rest}}) => (
                                    <RadioGroup
                                        aria-label="insurance-type"
                                        row
                                        value={value}
                                        onChange={event => {
                                            onChange(event);

                                            setValue('insuranceDocument', null);
                                        }}
                                        {...rest}
                                    >
                                        {insuranceDocumentTypeOptions}
                                    </RadioGroup>
                                )}
                            />
                        </FormControl>

                        <DropZone
                            acceptFiles="image/jpeg, image/png, image/jpg, application/pdf"
                            errorMessage={errors.insuranceDocument?.message}
                            files={insuranceDocument}
                            onDeleteFile={createDeleteFileHandler('insuranceDocument')}
                            onDropAccepted={createDropAcceptedHandler('insuranceDocument')}
                            name="insurance-document"
                        />
                    </div>
                </div>

                {/* <SetupCompanyFormFooter */}
                {/*    isSubmitButtonDisabled={!isValid} */}
                {/*    areBothButtonsDisabled={isLoading} */}
                {/*    buttonTitle={t('complete')} */}
                {/*    onBackButtonClick={handleBackButtonClick} */}
                {/*    onSubmitForApprovalButtonClick={handleSubmitForApprovalButtonClick} */}
                {/*    isLastStep */}
                {/* /> */}
            </Paper>

            <Box display="flex" justifyContent="center" flexDirection="column" sx={{mt: 3}}>
                <Button
                    sx={{mx: 2}}
                    size="large"
                    variant="outlined"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    onClick={() => {
                        dispatch(OnboardingActions.setSubStep(CompanySetupSubSteps.COMPANY_INFORMATION));
                    }}
                >
                    {t('back')}
                </Button>

                {hasSubmitModificationsButton ? (
                    <LoadingButton
                        loading={isLoadingForm}
                        sx={{mx: 2, mt: 3}}
                        size="large"
                        variant="contained"
                        color="primary"
                        startIcon={<SaveIcon />}
                        type="submit"
                        disabled={!isValid}
                    >
                        {t('submitForApproval')}
                    </LoadingButton>
                ) : (
                    <LoadingButton
                        sx={{mx: 2, mt: 3}}
                        loading={isLoadingForm}
                        size="large"
                        variant="contained"
                        color="secondary"
                        endIcon={<ArrowForwardIcon />}
                        disabled={!isValid}
                        type="submit"
                    >
                        {t('saveAndProceed')}
                    </LoadingButton>
                )}
            </Box>
        </form>
    );
};

DocumentsUploadForm.propTypes = {
    hasSubmitModificationsButton: PropTypes.bool,
};

DocumentsUploadForm.defaultProps = {
    hasSubmitModificationsButton: false,
};
