export const BE_DATE_FORMAT = 'DD/MM/YYYY';
export const FR_DATE_FORMAT_SHORT = 'DD/MM/YY';
export const FR_DATE_FORMAT_LONG = 'DD MMMM YYYY';
export const DEFAULT_TIME_ZONE = 'Europe/Paris';

export const PayCalculationType = {
    Instant: 'INSTANT',
    Delayed: 'DELAYED',
};

