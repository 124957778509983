import {Box, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {SubSectionTitle} from './SubSectionTitle';

export const SubSection = ({title, children}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box sx={{background: 'white', py: 2, px: 3, borderRadius: '8px'}}>
            <SubSectionTitle>{title}</SubSectionTitle>
            <Box sx={{display: 'flex', flexDirection: isMobileSize ? 'column' : 'row', gap: 2}}>
                {children}
            </Box>
        </Box>
    );
};

SubSection.propTypes = {
    title: PropTypes.string.isRequired,
};
