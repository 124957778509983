import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';
import {Box, Button, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {ModalWrapper} from '../../../../../components/modal-wrapper/ModalWrapper';
import {UiActions} from '../../../../ui/store/ui.action';
import {UiSelectors} from '../../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {AccountingActions} from '../../accounting/store/accounting.action';

const modalKey = ModalsKeys.DEPOSIT_OF_ACCOUNTS_REGENERATE_DOCUMENTS;

const RegenerateDocumentsModal = () => {
    const {t} = useTranslation('companies');
    const {companyId} = useParams();
    const documentToRegenerate = useSelector(UiSelectors.createModalDataSelector(modalKey));
    const dispatch = useDispatch();

    return (
        <ModalWrapper
            modalKey={modalKey}
            hasShowClose={true}
            containerSx={{width: '600px'}}
            title={(
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(34),
                    fontWeight: 700,
                    lineHeight: '116.7%',
                    pb: 1,
                }}
                >
                    {documentToRegenerate
                        ? t('formalities.depositOfAccounts.flowSteps.documentSigning.regenerateDocuments.regenerateSingleTitle')
                        : t('formalities.depositOfAccounts.flowSteps.documentSigning.regenerateDocuments.title')}
                </Typography>
            )}
        >
            <Typography>
                {documentToRegenerate
                    ? (
                        <Trans
                            t={t}
                            i18nKey="formalities.depositOfAccounts.flowSteps.documentSigning.regenerateDocuments.regenerateSingleMessage"
                            components={{b: <Typography component="span" sx={{fontWeight: 600}} />}}
                            values={{docName: t(`formalities.depositOfAccounts.flowSteps.documentSigning.documents.${documentToRegenerate.docType}`)}}
                        />
                    )
                    : t('formalities.depositOfAccounts.flowSteps.documentSigning.regenerateDocuments.message')}
            </Typography>
            <Box sx={{display: 'flex', width: '100%', gap: 2, justifyContent: 'right', mt: 4}}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        dispatch(UiActions.setActiveModal(modalKey, false));
                    }}
                    startIcon={<ArrowBackIcon />}
                >
                    {t('formalities.depositOfAccounts.flowSteps.documentSigning.regenerateDocuments.buttonReturn')}
                </Button>
                <Button
                    variant="contained"
                    startIcon={<RefreshIcon />}
                    onClick={() => {
                        if (documentToRegenerate) {
                            dispatch(AccountingActions.regenerateSingleDocument(
                                {companyId, documentId: documentToRegenerate.id},
                            ));
                        } else {
                            dispatch(AccountingActions.regenerateAllDocuments({companyId}));
                        }

                        dispatch(UiActions.setActiveModal(modalKey, false));
                    }}
                >
                    {documentToRegenerate
                        ? t('formalities.depositOfAccounts.flowSteps.documentSigning.regenerateDocuments.buttonRegenerateSingle')
                        : t('formalities.depositOfAccounts.flowSteps.documentSigning.regenerateDocuments.buttonRegenerate')}
                </Button>
            </Box>
        </ModalWrapper>
    );
};

export default RegenerateDocumentsModal;
