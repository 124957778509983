import axios from 'axios';
import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {SignatureActions} from './signature.action';
import {SignatureActionTypes} from './signature.action-type';
import {Debug} from '../../../utils/debug';
import {LoadingActions, LoadingTypes} from '../../loading';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {SignatureApi} from '../api/signature.api';

const uploadSignatureFlow = function* ({freelancerId, file}) {
    try {
        const {signedUrl: url} = yield call(SignatureApi.putSignature, {freelancerId, file});

        yield put(SignatureActions.storeSignatureUrl(url));
    } catch (error) {
        Debug.error('signature', 'Error: ', {error});
    }
};

const uploadSignatureWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_SIGNATURE, true));

    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    yield call(uploadSignatureFlow, {
        freelancerId: loggedInUser.id,
        file: payload.file,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_SIGNATURE, false));
};

const downloadFromS3 = url => axios.get(url, {
    responseType: 'blob',
}).then(result => result.data);

const getSignatureFlow = function* ({freelancerId}) {
    try {
        const url = yield call(SignatureApi.getSignature, {freelancerId});
        const blob = yield call(downloadFromS3, url);

        yield put(SignatureActions.storeSignatureUrl(window.URL.createObjectURL(blob)));
    } catch (error) {
        Debug.error('signature', 'Error: ', {error});
    }
};

export const loadSignature = function* () {
    yield put(LoadingActions.setLoading(LoadingTypes.SIGNATURE, true));

    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    if (loggedInUser) {
        yield call(getSignatureFlow, {
            freelancerId: loggedInUser.id,
        });
    }

    yield put(LoadingActions.setLoading(LoadingTypes.SIGNATURE, false));
};

export const signatureSaga = function* () {
    yield all([
        takeEvery(SignatureActionTypes.UPLOAD_SIGNATURE, uploadSignatureWorker),
    ]);
};
