import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import {Box, Button, Typography} from '@mui/material';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import {ModalWrapper} from '../../../../../../components/modal-wrapper/ModalWrapper';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {AccountingActions} from '../../../accounting/store/accounting.action';

const modalKey = ModalsKeys.DEPOSIT_OF_ACCOUNTS_GENERATE_DOCUMENTS;

const GenerateDocumentsModal = () => {
    const {t} = useTranslation('companies');
    const dispatch = useDispatch();

    const {companyId} = useParams();
    const [searchParams] = useSearchParams();

    const generateDocuments = () => {
        dispatch(AccountingActions.generateDOADocuments({
            companyId,
            year: searchParams.get('year'),
        }));
        dispatch(UiActions.setActiveModal(modalKey, false));
    };

    return (
        <ModalWrapper
            modalKey={modalKey}
            hasShowClose={true}
            containerSx={{width: '540px'}}
            title={(
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(34),
                    fontWeight: 700,
                    lineHeight: '116.7%',
                    pb: 1,
                }}
                >
                    {t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.generateDocumentsDialog.generateModalTitle')}
                </Typography>
            )}
        >
            <Trans t={t} i18nKey="formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.generateDocumentsDialog.generateModalMessage" components={{p: <Typography sx={{mb: 1}} />}} />
            <Box sx={{display: 'flex', width: '100%', gap: 3, justifyContent: 'right', mt: 4}}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        dispatch(UiActions.setActiveModal(modalKey, false));
                    }}
                >
                    {t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.generateDocumentsDialog.buttonCancel')}
                </Button>
                <Button variant="contained" startIcon={<DocumentScannerIcon />} onClick={generateDocuments}>
                    {t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.generateDocumentsDialog.buttonGenerate')}
                </Button>
            </Box>
        </ModalWrapper>
    );
};

export default GenerateDocumentsModal;
