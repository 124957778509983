import {fromContactInfoDTO, toContactInfoDTO} from './user.dto';
import {hiwayApiInstance} from '../../../lib/hiway-api-instance';

const putContactInfo = ({userId, data, propagateAddressChange = false}) => {
    return hiwayApiInstance
        .put(`/v1/users/${userId}/contact-info`, {
            ...toContactInfoDTO(data),
            propagate_address_change: propagateAddressChange,
        }).then(result => fromContactInfoDTO(result.data));
};

const getContactInfo = userId => {
    return hiwayApiInstance
        .get(`/v1/users/${userId}/contact-info`)
        .then(result => fromContactInfoDTO(result.data));
};

const getGlobalDataExport = (role) => {
    return hiwayApiInstance.get(`/v1/users/global-data-export`, {
        params: {
            roles: [role],
        }
    })
        .then(result => result.data);
};

export const UserApi = {
    putContactInfo,
    getContactInfo,
    getGlobalDataExport,
};
