import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {DashboardV3Actions} from './dashboard-v3.action';
import {DashboardV3ActionTypes} from './dashboard-v3.action-type';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {isUserCare} from '../../../utils/user-roles';
import {createDefaultCompanySelector} from '../../../v1/app/company/companyList/companyList.selectors';
import {BankApi} from '../../bank/api/bank.api';
import {TransactionsActions} from '../../bank/modules/account-balance/store/transactions.action';
import {TransactionsSelector} from '../../bank/modules/account-balance/store/transactions.selector';
import {DashboardApi} from '../../dashboard/api/dashboard.api';
import {getDashboardDatepickerCurrentYear} from '../../dashboard/util/dashboard.util';
import {InvoiceApi} from '../../invoicing/api/invoice.api';
import {LoadingActions, LoadingTypes} from '../../loading';
import {toPayCalculationInfoDTO} from '../../user-list/api/user.dto';
import {LoggedInUserActions, LoggedInUserSelectors} from '../../user/modules/logged-in-user';

export const dashboardV3LoaderSaga = function* () {
    yield put(DashboardV3Actions.fetchData());
};

const fetchTransactionOverview = function* (freelancerId) {
    try {
        return yield call(BankApi.getTransactionOverview, freelancerId);
    } catch (error) {
        Debug.error('fetchTransactionOverview', error);
        return null;
    }
};

const fetchFinancialData = function* (freelancerId, year) {
    try {
        return yield call(InvoiceApi.getFinancialData, {freelancerId, year});
    } catch (error) {
        Debug.error('fetchFinancialData', error);
        return null;
    }
};

const fetchInvoiceStatsIndicators = function* (companyId) {
    try {
        return yield call(InvoiceApi.getInvoiceStatsIndicators, {companyId});
    } catch (error) {
        Debug.error('fetchInvoiceStatsIndicators', 'Error: ', {error});
        return null;
    }
};

const fetchExpensesStats = function* (freelancerId, year) {
    try {
        return yield call(BankApi.getExpensesStats, {freelancerId, year});
    } catch (error) {
        Debug.error('fetchExpensesStats', error);
        return null;
    }
};

const fetchRemunerationStats = function* (freelancerId, year) {
    try {
        return yield call(BankApi.getRemunerationStats, {freelancerId, year});
    } catch (error) {
        Debug.error('fetchRemunerationStats', error);
        return null;
    }
};

// Flow
const requestRemunerationCalculationFlow = function* ({companyId, calculationType}) {
    yield put(LoadingActions.setLoading(LoadingTypes.REQUEST_PAY_CALCULATION_INFO, true));

    try {
        const data = yield call(DashboardApi.requestPayCalculation, {
            companyId,
            calculationType,
        });

        // TODO: Refetch only financial data, no need for the whole dashboard
        yield call(fetchDataFlow);

        const loggedInUserData = yield select(LoggedInUserSelectors.selectLoggedInUserDashboard);
        yield put(
            LoggedInUserActions.storeLoggedInUserAccountForDashboard({
                ...loggedInUserData,
                additionalInfo: {
                    ...loggedInUserData?.additionalInfo,
                    pay_calculation_info: toPayCalculationInfoDTO(data),
                },
            }),
        );
    } catch (error) {
        Toast.error('anErrorOccurred');
        Debug.error('pay-calculation-info', 'Error: ', {error});
    } finally {
        yield put(LoadingActions.setLoading(LoadingTypes.REQUEST_PAY_CALCULATION_INFO, false));
    }
};

const fetchDataFlow = function* () {
    const company = yield select(createDefaultCompanySelector());

    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    const isCareUser = isUserCare(loggedInUser);

    yield put(LoadingActions.setLoading(LoadingTypes.FETCH_DASHBOARD_V3_DATA, true));

    // Preload categories
    const categoriesList = yield select(TransactionsSelector.selectCategories);
    if (!categoriesList.length) {
        yield put(TransactionsActions.getCategoryList());
    }

    try {
        const year = getDashboardDatepickerCurrentYear();
        const freelancerId = loggedInUser.id;

        const apiCalls = [
            call(fetchTransactionOverview, freelancerId),
            call(fetchFinancialData, freelancerId, year),
            call(fetchExpensesStats, freelancerId, year),
            call(fetchRemunerationStats, freelancerId, year),
        ];

        if (!isCareUser) {
            apiCalls.push(call(fetchInvoiceStatsIndicators, company.id));
        }

        // TODO: Split into multiple reducers
        const [
            bankAccounts,
            financialData,
            expensesStats,
            remunerationStats,
            invoiceStatsIndicators,
        ] = yield all(apiCalls);

        yield put(DashboardV3Actions.storeData(
            {bankAccounts, financialData, expensesStats, remunerationStats, invoiceStatsIndicators},
        ));

        yield put(LoadingActions.setLoading(LoadingTypes.FETCH_DASHBOARD_V3_DATA, false));
    } catch (error) {
        Debug.error('dashboard', 'Error: ', {error});
        Toast.error('anErrorOccurred');
        throw error;
    }
};

// Workers
const fetchDataWorker = function* () {
    yield call(fetchDataFlow);
};

const requestRemunerationCalculationWorker = function* ({payload}) {
    const {companyId, calculationType} = payload;

    yield call(requestRemunerationCalculationFlow, {companyId, calculationType});
};

export const watchDashboardV3Saga = function* () {
    yield all([
        takeLatest(DashboardV3ActionTypes.FETCH_DATA, fetchDataWorker),
        takeLatest(DashboardV3ActionTypes.REQUEST_REMUNERATION_CALCULATION, requestRemunerationCalculationWorker),
    ]);
};
