import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Container, Modal, Paper, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {UiActions} from '../../features/ui/store/ui.action';
import {UiSelectors} from '../../features/ui/store/ui.selector';
import {useMemoizedCreateSelector} from '../../hooks/use-memoized-create-selector';
import {ButtonIcon} from '../buttons/ButtonIcon';
import CloseButton from '../buttons/CloseButton';

export const ModalWrapper = ({
    modalKey,
    handleBack,
    handleOpenCB,
    handleCloseCB,
    hasShowClose,
    containerSx,
    bodySx,
    titleSx,
    buttonsSx,
    title,
    buttons,
    borderRadius,
    children,
}) => {
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isOpen = useMemoizedCreateSelector(
        UiSelectors.createIsModalActiveSelector, modalKey,
    );

    const handleClose = () => {
        dispatch(UiActions.setActiveModal(modalKey, false));
        if (handleCloseCB) {
            handleCloseCB();
        }
    };

    const init = useCallback(() => {
        if (handleOpenCB && isOpen) {
            handleOpenCB();
        }
    }, [handleOpenCB, isOpen]);

    useEffect(init, [isOpen]); // eslint-disable-line

    if (!isOpen) {
        return null;
    }

    const isChildrenFunction = typeof children === 'function';

    return (
        <Modal
            open={isOpen}
            onClose={hasShowClose ? handleClose : null}
            sx={!isMobileSize ? {overflowY: 'scroll'} : {}}
            BackdropProps={{
                sx: {backgroundColor: 'rgba(241, 244, 246, 0.1)'},
            }}
        >
            <Box sx={{
                display: 'flex',
                minHeight: '100vh',
                alignItems: 'center',
            }}
            >
                {!isMobileSize ? (
                    <Container sx={containerSx}>
                        <Paper
                            radius={borderRadius}
                            elevation={24}
                        >
                            <Box sx={{position: 'relative'}}>
                                {hasShowClose && (
                                    <CloseButton
                                        onClose={handleClose}
                                        buttonStyles={{
                                            position: 'absolute',
                                            top: '8px',
                                            right: '8px',
                                            zIndex: 1,
                                        }}
                                    />
                                )}
                                {handleBack && (
                                    <Box sx={{
                                        position: 'absolute',
                                        left: '8px',
                                        top: '8px',
                                    }}
                                    >
                                        <ButtonIcon
                                            icon={<ArrowBackIcon fontSize="large" />}
                                            onClick={handleBack}
                                            sx={{color: 'text_v2.primary'}}
                                        />
                                    </Box>
                                )}
                                <Box sx={{
                                    p: 5,
                                    my: 3,
                                    ...bodySx,
                                }}
                                >
                                    {title && (
                                        <Box sx={{mb: 2, pr: 3, ...titleSx}}>
                                            {title}
                                        </Box>
                                    )}
                                    {isChildrenFunction ? children({handleClose}) : children}
                                    {buttons && (
                                        <Box sx={{
                                            pt: 5,
                                            gap: 3,
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            ...buttonsSx,
                                        }}
                                        >
                                            {buttons({handleClose})}
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Paper>
                    </Container>
                ) : (
                    <Box
                        sx={{
                            ...containerSx,
                            width: '100vw',
                            height: '100vh',
                            backgroundColor: '#fff',
                            position: 'relative',
                        }}
                    >
                        {hasShowClose && (
                            <CloseButton
                                onClose={handleClose}
                                buttonStyles={{
                                    position: 'absolute',
                                    top: '8px',
                                    right: '8px',
                                }}
                            />
                        )}
                        {handleBack && (
                            <ButtonIcon
                                icon={<ArrowBackIcon fontSize="large" />}
                                onClick={handleBack}
                                sx={{
                                    color: 'text_v2.primary',
                                    position: 'absolute',
                                    left: '8px',
                                }}
                            />
                        )}
                        <Box sx={{
                            height: '100vh',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            p: 3,
                            pt: 2,
                        }}
                        >
                            {title && (
                                <Box sx={{mb: 2, pr: 3, ...titleSx}}>
                                    {title}
                                </Box>
                            )}
                            {isChildrenFunction ? children({handleClose}) : children}
                            {buttons && (
                                <Box sx={{
                                    mt: 'auto',
                                    pt: 5,
                                    gap: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    ...buttonsSx,
                                }}
                                >
                                    {buttons({handleClose})}
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        </Modal>
    );
};

ModalWrapper.propTypes = {
    modalKey: PropTypes.string.isRequired,
    handleOpenCB: PropTypes.func,
    handleCloseCB: PropTypes.func,
    handleBack: PropTypes.func,
    containerSx: PropTypes.object,
    bodySx: PropTypes.object,
    titleSx: PropTypes.object,
    buttonsSx: PropTypes.object,
    hasShowClose: PropTypes.bool,
    title: PropTypes.node,
    buttons: PropTypes.func,
    borderRadius: PropTypes.number,
};

ModalWrapper.defaultProps = {
    handleOpenCB: null,
    handleCloseCB: null,
    containerSx: {width: '552px'},
    bodySx: {},
    titleSx: {},
    buttonsSx: {},
    hasShowClose: true,
    handleBack: null,
    title: null,
    buttons: null,
    borderRadius: 16,
};
