import {all, call, delay, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {SigningActions} from './signing.action';
import {SigningActionTypes} from './signing.action-type';
import {SigningSelectors} from './signing.selector';
import {Toast} from '../../../../../lib/toast';
import {Debug} from '../../../../../utils/debug';
import {isUserCare} from '../../../../../utils/user-roles';
import {FULL_COMPANY_STATUSES} from '../../../../../v1/app/company/setupCompany/setupCompany.constants';
import {USER_STATUSES} from '../../../../../v1/app/user/user.constants';
import {DOCUMENT_STATUSES, DOCUMENT_TYPES} from '../../../../../v1/config/constants/documentConstants';
import {getIsRegistrationWithExistingCompany} from '../../../../../v1/utils';
import {BankActions} from '../../../../bank/store/bank.action';
import {BankSelector} from '../../../../bank/store/bank.selector';
import {getFinancialStatementsByYearFlow} from '../../../../company-profile/modules/accounting/store/accounting.saga';
import {AccountingSelector} from '../../../../company-profile/modules/accounting/store/accounting.selector';
import {loadContractData} from '../../../../contract/store/contract.saga';
import {ContractSelectors} from '../../../../contract/store/contract.selector';
import {FreelancerSelectors} from '../../../../freelancer';
import {FinalizationSubsteps} from '../../../../freelancer/modules/capital-deposit/utils/constants';
import {CompaniesSelectors} from '../../../../freelancer/modules/companies';
import {
    getFreelancerCompaniesFlow,
    loadFreelancerCompanies,
} from '../../../../freelancer/modules/companies/store/companies.saga';
import {
    CompanySetupInternalSubSteps,
    CompanySetupSubSteps,
} from '../../../../freelancer/modules/company-setup/utils/constants';
import {ContractSigningSubSteps} from '../../../../freelancer/modules/contract-signing/utils/constants';
import {OnboardingLoader} from '../../../../freelancer/modules/onboarding/store/onboarding-loader.saga';
import {OnboardingActions} from '../../../../freelancer/modules/onboarding/store/onboarding.action';
import {OnboardingSelectors} from '../../../../freelancer/modules/onboarding/store/onboarding.selectors';
import {OnBoardingLaunchedStatus} from '../../../../freelancer/modules/onboarding/utils/constants';
import {OnboardingSteps} from '../../../../freelancer/modules/onboarding/utils/onboadingStepsConstant';
import {loadFreelancerAccount} from '../../../../freelancer/store/freelancer.saga';
import {InsuranceSelectors} from '../../../../insurance/store/insurance.selector';
import {InsuranceStatus} from '../../../../insurance/utils/constants';
import {LoadingActions, LoadingTypes} from '../../../../loading';
import {SignatureSelectors} from '../../../../signature/store/signature.selector';
import {TrainingActions} from '../../../../training/store/training.action';
import {TRAINING_CONSTANTS} from '../../../../training/utils/constants';
import {UiActions} from '../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {LoggedInUserSelectors, loadLoggedInUserAccount} from '../../../../user/modules/logged-in-user';
import {DatabaseActions} from '../../database/store/database.action';
import {loadDocuments} from '../../database/store/database.saga';
import {DatabaseSelectors} from '../../database/store/database.selector';
import {DocumentContexts} from '../../database/utils/constants';
import {SigningApi} from '../api/signing.api';
import {ACCOUNTING_DOCUMENTS} from '../utils/constants';

const signDocumentFlow = function* ({freelancerId, documentId, data}) {
    try {
        yield call(SigningApi.putSignDocument, {freelancerId, documentId, data});
    } catch (error) {
        Debug.error('signing', 'Error: ', {error});
    }
};

const signContractWorker = function* () {
    yield put(LoadingActions.setLoading(LoadingTypes.SIGN_CONTRACT, true));

    const contractData = yield select(ContractSelectors.selectData);
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    const progress = yield select(OnboardingSelectors.selectProgress);

    yield call(signDocumentFlow, {
        freelancerId: loggedInUser.id,
        documentId: contractData.documentId,
        data: {
            // TODO: Get terms from the API?
            term_ids: [
                '2a9c114c-6fba-4025-bc84-5bc96fc499ee',
                'b04326a2-2545-485d-8bf0-ec583cffd04f',
            ],
        },
    });

    yield all([
        call(loadFreelancerAccount, {
            freelancerId: loggedInUser.id,
        }),
        call(loadContractData),
        call(loadLoggedInUserAccount),
    ]);

    yield put(OnboardingActions.setProgress({
        ...progress,
        [OnboardingSteps.CONTRACT_SIGNING]: {
            ...progress.CONTRACT_SIGNING,
            subStepProgress: {
                [ContractSigningSubSteps.ENTER_PERSONAL_ADDRESS]: {
                    isCompleted: true,
                },
                [ContractSigningSubSteps.SIGN_CONTRACT]: {
                    isCompleted: true,
                },
            },
        },
    }));

    yield put(LoadingActions.setLoading(LoadingTypes.SIGN_CONTRACT, false));
};

const getCurrentCompanyDocumentSaga = function* (documentId) {
    if (!documentId) {
        return;
    }

    yield put(SigningActions.setIsLoadingCurrentDocument(true));

    const freelancer = yield select(FreelancerSelectors.selectAccount);

    const freelancerId = freelancer.id;
    const companyId = freelancer.defaultCompanyId;

    /**
     * @property {String|undefined|null} data.signedUrl The URL which can be used to download/open the document
     */
    const data = yield call(SigningApi.getSignedUrlForDocumentRequest, freelancerId, companyId, documentId, true);

    if (!data.hasOwnProperty('signedUrl') || !data.signedUrl) {
        Toast.error('missingDocument');
    }

    const documents = yield select(DatabaseSelectors.selectDocuments);
    yield put(SigningActions.setCurrentDocumentUrl(data?.signedUrl));
    yield put(SigningActions.setCurrentDocumentId(documentId));
    yield put(SigningActions.setCurrentDocumentIsSigned(documents[documentId].status === 'SIGNED'));
    yield put(SigningActions.setIsSigningCurrentDocument(false));

    yield put(SigningActions.setIsLoadingCurrentDocument(false));
};

const signCurrentDocumentSaga = function* () {
    yield put(SigningActions.setIsSigningCurrentDocument(true));

    const annualAccounts = yield select(AccountingSelector.selectFinancialStatements);
    const documents = yield select(DatabaseSelectors.selectDocuments);
    const freelancer = yield select(FreelancerSelectors.selectAccount);
    const freelancerId = freelancer.id;
    const companyId = freelancer.defaultCompanyId;
    const documentId = yield select(SigningSelectors.selectCurrentDocumentId);
    const currDocument = documents[documentId];

    // If user does not have signature show modal for uploading signature
    const signature = yield select(SignatureSelectors.selectUrl);

    if (!signature) {
        yield put(SigningActions.setIsSigningCurrentDocument(false));
        yield put(UiActions.setActiveModal(ModalsKeys.UPLOAD_SIGNATURE, true));
        return;
    }

    const isSigningAccountingDocuments = ACCOUNTING_DOCUMENTS.includes(currDocument.type);
    let signDocumentResult;
    if (isSigningAccountingDocuments) {
        signDocumentResult = yield call(SigningApi.signAccountingDocumentRequest, companyId, documentId);
    } else {
        signDocumentResult = yield call(SigningApi.signDocumentRequest, freelancerId, companyId, documentId);
    }

    if (ACCOUNTING_DOCUMENTS.includes(currDocument.type)) {
        yield call(getFinancialStatementsByYearFlow, companyId, annualAccounts.year);
    }

    const {allDocumentsSigned} = signDocumentResult;

    if (allDocumentsSigned) {
        const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer.defaultCompanyId));
        if (company.status === 'PENDING_FINAL_SIGS') {
            const progress = yield select(OnboardingSelectors.selectProgress);
            yield put(OnboardingActions.setProgress({
                ...progress,
                [OnboardingSteps.COMPANY_REGISTRATION]: {
                    ...progress[OnboardingSteps.COMPANY_REGISTRATION],
                    isCompleted: true,
                },
            }));
        } else {
            yield call(loadFreelancerCompanies, {freelancerId});
        }
    }

    yield call(getCurrentCompanyDocumentSaga, documentId);

    // TODO This is quickfix
    // const documents = yield select(DatabaseSelectors.selectDocuments);
    const newDocuments = {...documents};
    newDocuments[documentId].status = DOCUMENT_STATUSES.SIGNED;

    yield put(DatabaseActions.storeDocuments(newDocuments));

    yield put(SigningActions.setCurrentDocumentIsSigned(true));
    yield put(SigningActions.setIsSigningCurrentDocument(false));
};

const getCurrentCompanyDocumentWorker = function* ({payload}) {
    yield call(getCurrentCompanyDocumentSaga, payload);
};

// TODO This function is becoming overloaded,
// we need to split this function for each area it is appearing in (First and second batch, insurance, training?)
const openNextDocumentWorker = function* () {
    const documents = yield select(DatabaseSelectors.selectDocuments);
    const currentDocumentId = yield select(SigningSelectors.selectCurrentDocumentId);

    const insurance = yield select(InsuranceSelectors.selectInsurance);

    const foundDocument = Object.values(documents).sort((previous, next) => {
        const previousType = previous.type.toUpperCase();
        const nextType = next.type.toUpperCase();

        return (previousType < nextType) ? -1 : (previousType > nextType) ? 1 : 0;
    }).find(document => {
        return document?.status === DOCUMENT_STATUSES.PENDING_SIGNING && document?.id !== currentDocumentId;
    });

    // TODO This entire monstrosity in else should be refactored and moved from this file
    if (foundDocument) {
        yield call(getCurrentCompanyDocumentSaga, foundDocument.id);
    } else {
        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

        // Check companies status
        yield call(getFreelancerCompaniesFlow, {freelancerId: freelancer.id});

        const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer.defaultCompanyId));

        if (company.status === FULL_COMPANY_STATUSES.PENDING_INIT_SIGS) {
            // If company changed status while user was signing
            const params = {freelancerId: freelancer.id, companyId: company.id};
            yield all([
                put(OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_GENERATION)),
                call(OnboardingLoader.loadDocumentInformation, params),
                call(loadDocuments, {
                    freelancerId: freelancer.id,
                    companyId: company.id,
                    context: DocumentContexts.SIGNABLE,
                }),
                put(OnboardingActions.setInternalSubStep(null)),
                put(SigningActions.setCurrentDocumentUrl(null)),
                put(SigningActions.setCurrentDocumentId(null)),
                put(SigningActions.setCurrentDocumentIsSigned(false)),
                put(SigningActions.setIsSigningCurrentDocument(false)),
                put(SigningActions.setIsLoadingCurrentDocument(false)),
            ]);
        } else if (company.status === FULL_COMPANY_STATUSES.PENDING_FINAL_SIGS
            // If user has active company and user it means signing is after onboarding
            || (company.status === FULL_COMPANY_STATUSES.ACTIVE
                && loggedInUser?.status !== USER_STATUSES.ACTIVE)) {
            const integrations = yield select(BankSelector.selectIntegrations);
            const hasIntegration = !!integrations?.[0];

            // If company is active
            yield put(OnboardingActions.setStep(
                OnboardingSteps.FINAL_POINTS,
            ));

            if (!hasIntegration) {
                const registrationType = company?.enterpriseInformation?.registration_type;
                const registrationWithExistingCompany = getIsRegistrationWithExistingCompany(registrationType);

                if (!registrationWithExistingCompany) {
                    // Needed to get correct substep on non-Hiway pro user
                    yield put(OnboardingActions.setSubStep(FinalizationSubsteps.INSURANCE));
                    yield put(OnboardingActions.setInternalSubStep(
                        FinalizationSubsteps.INSURANCE,
                    ));
                } else {
                    // Needed to get correct substep on transformation users
                    yield put(OnboardingActions.setInternalSubStep(
                        'INITIAL_SCREEN',
                    ));
                }
            } else {
                const progress = yield select(OnboardingSelectors.selectProgress);
                const bankAccountHoldersList = integrations?.[0]?.bankAccountHolders;
                const individualBankAccountHolders = bankAccountHoldersList
                    ? bankAccountHoldersList.find(holder => holder.type !== 'COMPANY')
                    : undefined;

                if (isUserCare(loggedInUser)) {
                    yield put(OnboardingActions.setSubStep(FinalizationSubsteps.ACCESS_PLATFORM));
                    yield put(OnboardingActions.setInternalSubStep(
                        FinalizationSubsteps.ACCESS_PLATFORM,
                    ));
                } else if (individualBankAccountHolders?.bankAccounts?.[0]?.status !== 'CLOSED'
                        && individualBankAccountHolders?.bankAccounts?.[0]?.status !== 'CLOSING') {
                    yield put(OnboardingActions.setSubStep(FinalizationSubsteps.PENDING_FINALIZATION));
                    yield put(OnboardingActions.setInternalSubStep(
                        FinalizationSubsteps.PENDING_FINALIZATION,
                    ));
                } else {
                    yield put(OnboardingActions.setSubStep(FinalizationSubsteps.INSURANCE));
                    yield put(OnboardingActions.setInternalSubStep(
                        FinalizationSubsteps.INSURANCE,
                    ));
                }

                yield put(OnboardingActions.setProgress({
                    ...progress,
                    [OnboardingSteps.FINAL_POINTS]: {
                        ...progress[OnboardingSteps.FINAL_POINTS],
                        subStepProgress: {
                            ...progress[OnboardingSteps.FINAL_POINTS].subStepProgress,
                            [FinalizationSubsteps.DOCUMENT_SIGNING_FINALIZATION]: {
                                isCompleted: true,
                            },
                        },
                    },
                }));
            }

            yield all([
                put(BankActions.getCapitalDeposit()),
                put(SigningActions.setCurrentDocumentUrl(null)),
                put(SigningActions.setCurrentDocumentId(null)),
                put(SigningActions.setCurrentDocumentIsSigned(false)),
                put(SigningActions.setIsSigningCurrentDocument(false)),
                put(SigningActions.setIsLoadingCurrentDocument(false)),
            ]);
        } else {
            const signedInsuranceDocs = documents ? Object
                ?.values(documents)
                ?.find(doc => doc?.type === DOCUMENT_TYPES.INSURANCE_GROUP_INSURANCE_DIRECT_DEBIT_MANDATE
                    && doc?.status === DOCUMENT_STATUSES.SIGNED) : null;

            // If there are signed insurance docs with company and user being active
            // and insurance in WAITING_FOR_COMPANY_REGISTRATION status
            // we need to show that document to the user,
            // so he can continue with flow and run insurance API call
            if (
                loggedInUser?.status === USER_STATUSES.ACTIVE
                && company?.status === FULL_COMPANY_STATUSES.ACTIVE
                && insurance?.status === InsuranceStatus?.WAITING_FOR_COMPANY_REGISTRATION
                && signedInsuranceDocs
            ) {
                // Get document data
                const data = yield call(
                    SigningApi.getSignedUrlForDocumentRequest,
                    loggedInUser?.id,
                    company?.id,
                    signedInsuranceDocs?.id,
                    true,
                );

                yield put(SigningActions.setCurrentDocumentUrl(data?.signedUrl));
                yield put(SigningActions.setCurrentDocumentId(signedInsuranceDocs?.id));
                yield put(SigningActions.setCurrentDocumentIsSigned(true));
                yield put(SigningActions.setIsSigningCurrentDocument(false));
                yield put(SigningActions.setIsLoadingCurrentDocument(false));
            } else {
                yield all([
                    put(OnboardingActions.setInternalSubStep(
                        CompanySetupInternalSubSteps.AWAITING_DOCUMENT_VERIFICATION,
                    )),
                    put(SigningActions.setCurrentDocumentUrl(null)),
                    put(SigningActions.setCurrentDocumentId(null)),
                    put(SigningActions.setCurrentDocumentIsSigned(false)),
                    put(SigningActions.setIsSigningCurrentDocument(false)),
                    put(SigningActions.setIsLoadingCurrentDocument(false)),
                ]);
            }
        }
    }
};

const openNextTrainingDocumentWorker = function* () {
    const documents = yield select(DatabaseSelectors.selectSignableDocuments);
    const currentDocumentId = yield select(SigningSelectors.selectCurrentDocumentId);

    const foundDocument = documents.sort((previous, next) => {
        const previousType = previous.type.toUpperCase();
        const nextType = next.type.toUpperCase();

        return (previousType < nextType) ? -1 : (previousType > nextType) ? 1 : 0;
    }).find(document => {
        return document.status === DOCUMENT_STATUSES.PENDING_SIGNING && document.id !== currentDocumentId;
    });

    if (foundDocument) {
        yield call(getCurrentCompanyDocumentSaga, foundDocument.id);
    } else {
        yield put(TrainingActions.submitStep(TRAINING_CONSTANTS.DOSSIER_STEPS.DOCUMENT_SIGNING));

        yield delay(1000);
        yield all([
            put(SigningActions.setCurrentDocumentUrl(null)),
            put(SigningActions.setCurrentDocumentId(null)),
            put(SigningActions.setCurrentDocumentIsSigned(false)),
            put(SigningActions.setIsSigningCurrentDocument(false)),
            put(SigningActions.setIsLoadingCurrentDocument(false)),
        ]);
    }
};

export const isSigningAdditionalDocumentsSaga = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    const company = yield select(CompaniesSelectors.createCompanyByIdSelector(loggedInUser.defaultCompanyId));
    const isSigningAdditionalDocuments = loggedInUser.onboardingStatus === OnBoardingLaunchedStatus.LAUNCHED && company.status === 'ACTIVE';
    yield put(SigningActions.setIsSigningAdditionalDocuments(isSigningAdditionalDocuments));
};

export const clearSignableDocumentsSaga = function* () {
    yield all([
        put(DatabaseActions.clearDocuments()),
        put(SigningActions.setCurrentDocumentUrl(null)),
        put(SigningActions.setCurrentDocumentId(null)),
        put(SigningActions.setCurrentDocumentIsSigned(false)),
        put(SigningActions.setIsSigningCurrentDocument(false)),
        put(SigningActions.setIsLoadingCurrentDocument(false)),
    ]);
};

export const signingSaga = function* () {
    yield all([
        takeEvery(SigningActionTypes.SIGN_CONTRACT, signContractWorker),
        takeLatest(SigningActionTypes.SET_CURRENT_COMPANY_DOCUMENT, getCurrentCompanyDocumentWorker),
        takeLatest(SigningActionTypes.SIGN_CURRENT_DOCUMENT, signCurrentDocumentSaga),
        takeLatest(SigningActionTypes.OPEN_NEXT_DOCUMENT, openNextDocumentWorker),
        takeLatest(SigningActionTypes.OPEN_NEXT_TRAINING_DOCUMENT, openNextTrainingDocumentWorker),
    ]);
};
