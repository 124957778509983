import {BeneficiariesActionType} from './beneficiaries.action-type';

export const BeneficiariesActions = {
    storeList: data => ({
        type: BeneficiariesActionType.STORE_LIST,
        payload: data,
    }),
    create: data => ({
        type: BeneficiariesActionType.CREATE,
        payload: data,
    }),
    update: data => ({
        type: BeneficiariesActionType.UPDATE,
        payload: data,
    }),
    delete: data => ({
        type: BeneficiariesActionType.DELETE,
        payload: data,
    }),
    setIbanConflict: data => ({
        type: BeneficiariesActionType.SET_IBAN_CONFLICT,
        payload: data,
    }),
};
