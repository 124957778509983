import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {EventTabs} from './EventTabs';
import {MobileRoadmapSteps} from './MobileRoadmapSteps';
import {RoadmapChart} from './RoadmapChart';
import {RoadmapSteps} from './RoadmapSteps';

export const Roadmap = () => {
    const isMobileSize = useMediaQuery('(max-width: 960px)');

    return (
        <Box flex={1}>
            <RoadmapChart />
            <Box sx={{ml: '70px'}}>
                <RoadmapSteps />
                <EventTabs />
            </Box>
            {isMobileSize && <MobileRoadmapSteps />}
        </Box>
    );
};
