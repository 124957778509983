import {Box, Slider} from '@mui/material';
import PropTypes from 'prop-types';
import {useController} from 'react-hook-form';
import NumberFormat from 'react-number-format';
import {TextInput} from './TextInput';

export const NumberField = ({
    name,
    withSlider,
    inputStyles,
    renderMarkLabel,
    defaultValue,
    required,
    min,
    max,
    disabled,
    decimalScale,
    ...other
}) => {
    const {field, fieldState} = useController({name, defaultValue, rules: {required, disabled, min, max}});

    const {onChange, ...numberFormatProps} = field;
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
            }}
        >
            <NumberFormat
                customInput={TextInput}
                decimalScale={decimalScale}
                thousandSeparator={' '}
                decimalSeparator="."
                allowedDecimalSeparators={[',']}
                allowNegative={false}
                // Remove leading zero if it is the only character and user inputs a number after it - UX requirement
                onInput={e => {
                    if (field.value === 0) {
                        e.target.value = e.target.value.replace(/^0+([0-9])/, '$1');
                    }
                }}
                // Set the field to '' instead of undefined because that would revert it to the default value
                onValueChange={values => field.onChange(values.floatValue ?? '')}
                disabled={disabled}
                sx={inputStyles}
                error={fieldState.invalid}
                {...numberFormatProps}
                {...other}
            />
            {withSlider && (
                <Slider
                    size="small"
                    aria-label="slider"
                    valueLabelDisplay="off"
                    min={min}
                    max={max}
                    disabled={disabled}
                    marks={[
                        {value: min, label: renderMarkLabel?.(min) || min},
                        {value: max, label: renderMarkLabel?.(max) || max},
                    ]}
                    sx={{
                        '& .MuiSlider-markLabel[data-index="0"]': {
                            transform: 'translateX(0%)',
                        },
                        '& .MuiSlider-markLabel[data-index="1"]': {
                            transform: 'translateX(-100%)',
                        },
                    }}
                    {...field}
                    value={(!disabled && field.value) || 0}
                />
            )}
        </Box>
    );
};
/* eslint-disable react/boolean-prop-naming */
NumberField.propTypes = {
    name: PropTypes.string,
    withSlider: PropTypes.bool,
    inputStyles: PropTypes.object,
    renderMarkLabel: PropTypes.func,
    defaultValue: PropTypes.number,
    required: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    disabled: PropTypes.bool,
    decimalScale: PropTypes.number,
};

NumberField.defaultProps = {
    name: '',
    withSlider: false,
    inputStyles: undefined,
    renderMarkLabel: undefined,
    defaultValue: undefined,
    required: true,
    min: 0,
    max: undefined,
    disabled: false,
    decimalScale: 0,
};
