export const selectBankSSEStatus = state => state.sse.bankConnectionStatus;
export const selectBankSSEEventSource = state => state.sse.bankSseEventSource;

export const selectInvoiceSSEStatus = state => state.sse.invoiceConnectionStatus;
export const selectInvoiceSSEEventSource = state => state.sse.invoiceSseEventSource;

export const SSESelector = {
    selectBankSSEStatus,
    selectBankSSEEventSource,

    selectInvoiceSSEStatus,
    selectInvoiceSSEEventSource,
};
