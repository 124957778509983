import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, TextField, Typography, useTheme} from '@mui/material';
import Button from '@mui/material/Button';
import {useController, useFormContext} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import image from '../../../../../../assets/png/revenue-simulator-start.png';

export const FirstStep = () => {
    const {t} = useTranslation('revenueSimulator');
    const {pxToRem} = useTheme().typography;
    const {formState} = useFormContext();
    const {field: firstNameField} = useController({
        name: 'firstName',
        defaultValue: '',
        rules: {required: true},
    });
    const {field: lastNameField} = useController({
        name: 'lastName',
        defaultValue: '',
        rules: {required: true},
    });
    return (
        <Box>
            <Typography
                component="h1"
                sx={{
                    fontSize: pxToRem(34),
                    mb: 2,
                    fontWeight: 700,
                    letterSpacing: pxToRem(-0.2),
                    lineHeight: 1.167,
                }}
            >
                {t('simulateMyRevenue')}
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    borderRadius: '8px 8px 24px 24px',
                    pb: '69px',
                    pt: '34px',
                }}
            >
                <img width={300} height={300} src={image} alt="" />

                <Box
                    sx={{
                        px: 2,
                        textAlign: 'center',
                        color: 'text_v2.secondary',
                    }}
                >
                    <Typography
                        component="h2"
                        sx={{
                            fontSize: pxToRem(48),
                            letterSpacing: pxToRem(-0.5),
                            fontWeight: 700,
                            lineHeight: 1.2,
                            mb: 1,
                        }}
                    >
                        {t('simulateMyRevenue')}
                    </Typography>
                    <Typography
                        sx={{
                            maxWidth: pxToRem(486),
                            letterSpacing: pxToRem(0.15),
                        }}
                    >
                        {t('firstPageDescription')}
                    </Typography>
                </Box>

                <Box sx={{display: 'flex', gap: 4, my: 4}}>
                    <TextField variant="standard" label={t('firstName')} {...firstNameField} />
                    <TextField variant="standard" label={t('lastName')} {...lastNameField} />
                </Box>
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={!formState.isValid}
                    type="submit"
                    endIcon={<ArrowForwardIcon />}
                >
                    {t('simulateMyRevenue')}
                </Button>
            </Box>
        </Box>
    );
};
