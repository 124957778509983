import {hiwayApiInstance} from '../../../../../lib/hiway-api-instance';
import {invoiceApiInstance} from '../../../../../lib/invoice-api-instance';
import {PAST_YEAR} from '../utils/constants';

const downloadFileFEC = ({
    companyId,
    type,
    dateFrom,
    dateTo,
}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/companies/${companyId}/fec/export`,
        params: {
            type,
            dateFrom,
            dateTo,
        },
    }).then(result => result?.data);
};

const getJournalAccounts = ({companyId}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/companies/${companyId}/journal-accounts`,
    }).then(result => result?.data);
};

const updateJournalAccounts = ({companyId, items}) => {
    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/companies/${companyId}/journal-accounts`,
        data: {
            items,
        },
    }).then(result => result?.data);
};

const downloadFinalFileFEC = ({companyId, year, type}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/companies/${companyId}/fec/final/export`,
        params: {
            year,
            type,
        },
    }).then(result => result?.data);
};

const uploadFinalFileFEC = ({companyId, year, file}) => {
    const formData = new FormData();
    formData.append('year', year);
    formData.append('file', file);

    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/companies/${companyId}/fec/final/upload`,
        data: formData,
    }).then(result => result?.data);
};

const getFinancialStatements = ({companyId, annualAccountId = null, year = PAST_YEAR}) => {
    if (annualAccountId) {
        return invoiceApiInstance({
            method: 'GET',
            url: `/v1/companies/${companyId}/formalities/annual-accounts/${annualAccountId}`,
        }).then(result => result?.data);
    }

    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/companies/${companyId}/formalities/annual-accounts?year=${year}`,
    }).then(result => result?.data);
};

export const setDepositOfAccountsManualMode = ({companyId, annualAccountId}) => {
    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/companies/${companyId}/formalities/annual-accounts/${annualAccountId}/manually-validate`,
    }).then(response => response?.data);
};

export const resetDepositOfAccountsManualMode = ({companyId, annualAccountId}) => {
    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/companies/${companyId}/formalities/annual-accounts/${annualAccountId}/reset`,
    }).then(response => response?.data);
};

const deleteFinalFileFEC = ({companyId, annualAccountId}) => {
    return invoiceApiInstance({
        method: 'DELETE',
        url: `/v1/companies/${companyId}/formalities/annual-accounts/${annualAccountId}/final-fec`,
    }).then(result => result?.data);
};

const uploadBalanceSheet = ({companyId, annualAccountId, file}) => {
    const formData = new FormData();
    formData.append('file', file);

    return invoiceApiInstance({
        method: 'PUT',
        url: `/v1/companies/${companyId}/formalities/annual-accounts/${annualAccountId}/balance-sheet-document-base-upload`,
        data: formData,
    }).then(result => result?.data);
};

const getBalanceSheetDetails = ({companyId, year}) => {
    return invoiceApiInstance({
        method: 'GET',
        url: `/v1/companies/${companyId}/balance-sheet`,
        params: {
            year,
        },
    }).then(result => result?.data);
};

const deleteLatestBalanceSheet = ({freelancerId, companyId, documentId}) => {
    return hiwayApiInstance({
        method: 'DELETE',
        url: `/v1/freelancers/${freelancerId}/companies/${companyId}/documents/${documentId}`,
    }).then(result => result?.data);
};

const generateDocuments = ({companyId, year}) => {
    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/companies/${companyId}/documents/generate`,
        params: {
            stage: 'ANNUAL_ACCOUNTING',
            year,
        },
    }).then(result => result?.data);
};

const regenerateDocument = ({companyId, documentId}) => {
    return invoiceApiInstance({
        method: 'PUT',
        url: `/v1/companies/${companyId}/documents/${documentId}/regenerate`,
    }).then(result => result?.data);
};

const regenerateAllDocuments = ({companyId, year}) => {
    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/companies/${companyId}/documents/regenerate`,
        params: {
            stage: 'ANNUAL_ACCOUNTING',
            year,
        },
    }).then(result => result?.data);
};

const sendFormalityToINPI = ({companyId, annualAccountId}) => {
    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/companies/${companyId}/formalities/annual-accounts/${annualAccountId}/send-to-inpi`,
        data: {
            eventId: annualAccountId,
        },
    }).then(result => result?.data);
};

const retrySendFormalityToINPI = ({companyId, annualAccountId}) => {
    return invoiceApiInstance({
        method: 'POST',
        url: `/v1/companies/${companyId}/formalities/annual-accounts/${annualAccountId}/retry-send-to-inpi`,
        data: {
            eventId: annualAccountId,
        },
    }).then(result => result?.data);
};

export const AccountingApi = {
    downloadFileFEC,
    getJournalAccounts,
    updateJournalAccounts,
    getFinancialStatements,
    downloadFinalFileFEC,
    uploadFinalFileFEC,
    setDepositOfAccountsManualMode,
    resetDepositOfAccountsManualMode,
    deleteFinalFileFEC,
    uploadBalanceSheet,
    getBalanceSheetDetails,
    deleteLatestBalanceSheet,
    generateDocuments,
    regenerateDocument,
    regenerateAllDocuments,
    sendFormalityToINPI,
    retrySendFormalityToINPI,
};
