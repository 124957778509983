import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

export const Section = ({Icon, title, isCollapsable, defaultCollapsed, children}) => {
    const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const {t} = useTranslation('revenueSimulator');

    const toggleCollapse = event => {
        event.stopPropagation();
        if (isCollapsable) {
            setIsCollapsed(prevState => !prevState);
        }
    };

    return (
        <Box
            sx={{
                mt: 2,
                pb: 4,
            }}
        >
            <Paper
                elevation={isCollapsable && isCollapsed ? 1 : 0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    background: isCollapsed ? 'white' : 'transparent',
                    px: isCollapsed || isMobileSize ? 2 : 0,
                    py: isCollapsable && isMobileSize ? 1 : 0,
                    mx: isCollapsed && isMobileSize ? 2 : 0,
                    borderRadius: isCollapsed && isMobileSize ? '40px' : '24px',
                    mb: isCollapsable ? 0 : 1,
                }}
                onClick={toggleCollapse}
            >
                <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                    <Icon
                        sx={{
                            fontSize: theme => theme.typography.pxToRem(24),
                            color: 'v2.blueGray.300',
                        }}
                    />
                    <Box
                        sx={{
                            textTransform: 'uppercase',
                            fontSize: theme => theme.typography.pxToRem(12),
                            fontWeight: 500,
                            color: 'lightTextSecondary.main',
                            letterSpacing: '1px',
                        }}
                    >
                        {title}
                    </Box>
                </Box>
                {isCollapsable && (
                    <Button
                        endIcon={isCollapsed ? <UnfoldMoreIcon /> : <UnfoldLessIcon />}
                        onClick={toggleCollapse}
                    >
                        {!isMobileSize && t('viewInformations')}
                    </Button>
                )}
            </Paper>
            <Paper
                elevation={isCollapsable && !isCollapsed ? 1 : 0}
                sx={{
                    background: isCollapsed ? 'white' : 'transparent',
                    display: isCollapsed ? 'none' : 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    overflow: 'hidden',
                    borderRadius: '24px',
                }}
            >
                {children}
            </Paper>
        </Box>
    );
};

Section.propTypes = {
    Icon: PropTypes.elementType.isRequired,
    title: PropTypes.string.isRequired,
    isCollapsable: PropTypes.bool,
    // eslint-disable-next-line react/boolean-prop-naming
    defaultCollapsed: PropTypes.bool,
};

Section.defaultProps = {
    defaultCollapsed: false,
    isCollapsable: false,
};
