import MessageIcon from '@mui/icons-material/Message';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import expiredImage from '../../../../../assets/svg/delete-history.svg';
import {RevenueSimulatorSelectors} from '../../../store/revenue-simulator.selector';

export const SimulationExpired = () => {
    const {t} = useTranslation('revenueSimulator');
    const simulation = useSelector(RevenueSimulatorSelectors.selectSimulation);
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                background: 'white',
                height: `calc(100vh - ${isMobileSize ? 80 : 75}px)`,
            }}
        >
            <Box component="img" src={expiredImage} sx={{marginTop: -6}} />
            <Box sx={{fontSize: theme => theme.typography.pxToRem(34), fontWeight: 700}}>
                {t('simulationExpired.title')}
            </Box>
            <Box sx={{mb: 3, mx: 2, textAlign: 'center'}}>
                <Trans t={t} i18nKey="simulationExpired.description" />{' '}
            </Box>
            <a href={`mailto:${simulation.coachEmail}`}>
                <Button variant="contained" startIcon={<MessageIcon />}>
                    {t('contactSection.buttonText')}
                </Button>
            </a>
        </Box>
    );
};
