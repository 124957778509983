import {SignatureActionTypes} from './signature.action-type';

export const SignatureActions = {
    uploadSignature: file => ({
        type: SignatureActionTypes.UPLOAD_SIGNATURE,
        payload: {
            file,
        },
    }),
    storeSignatureUrl: url => ({
        type: SignatureActionTypes.STORE_SIGNATURE_URL,
        payload: url,
    }),
};
