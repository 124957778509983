import {TransactionsActionTypes} from './transactions.action-type';

export const TransactionsActions = {
    getCategoryList: () => ({
        type: TransactionsActionTypes.GET_CATEGORY_LIST,
    }),
    storeCategoryList: data => ({
        type: TransactionsActionTypes.STORE_CATEGORY_LIST,
        payload: data,
    }),
    storeCategoryObject: data => ({
        type: TransactionsActionTypes.STORE_CATEGORY_OBJECT,
        payload: data,
    }),
    getSourcesList: () => ({
        type: TransactionsActionTypes.GET_SOURCES_LIST,
    }),
    storeSourcesList: data => ({
        type: TransactionsActionTypes.STORE_SOURCES_LIST,
        payload: data,
    }),
    storeSourcesObject: data => ({
        type: TransactionsActionTypes.STORE_SOURCES_OBJECT,
        payload: data,
    }),
    getTransactionList: params => ({
        type: TransactionsActionTypes.GET_TRANSACTION_LIST,
        payload: params,
    }),
    storeTransactionList: data => ({
        type: TransactionsActionTypes.STORE_TRANSACTION_LIST,
        payload: data,
    }),
    storeNonCategorizedCount: data => ({
        type: TransactionsActionTypes.STORE_NON_CATEGORIZED_AMOUNT,
        payload: data,
    }),
    setTransactionAlert: value => ({
        type: TransactionsActionTypes.SET_TRANSACTION_ALERT,
        payload: value,
    }),
    loadNextPage: params => ({
        type: TransactionsActionTypes.LOAD_NEXT_TRANSACTION_PAGE,
        payload: params,
    }),
    getTransaction: params => ({
        type: TransactionsActionTypes.GET_BANK_TRANSACTION,
        payload: params,
    }),
    storeTransactionInObject: params => ({
        type: TransactionsActionTypes.STORE_TRANSACTION_IN_OBJECT,
        payload: params,
    }),
    saveArticles: params => ({
        type: TransactionsActionTypes.SAVE_BANK_ARTICLES,
        payload: params,
    }),
    saveTransactionField: params => ({
        type: TransactionsActionTypes.SAVE_BANK_TRANSACTION_FIELD,
        payload: params,
    }),
    uploadTransactionFile: params => ({
        type: TransactionsActionTypes.UPLOAD_TRANSACTION_FILE,
        payload: params,
    }),
    updateFileUploadLoader: params => ({
        type: TransactionsActionTypes.UPLOAD_TRANSACTION_FILE_LOADER,
        payload: params,
    }),
    deleteBankFile: params => ({
        type: TransactionsActionTypes.DELETE_BANK_FILE,
        payload: params,
    }),
    replaceTransaction: params => ({
        type: TransactionsActionTypes.REPLACE_TRANSACTION,
        payload: params,
    }),
    getCombinedTransactionList: params => ({
        type: TransactionsActionTypes.GET_COMBINED_TRANSACTION_LIST,
        payload: params,
    }),
    loadNextCombinedPage: params => ({
        type: TransactionsActionTypes.LOAD_NEXT_COMBINED_TRANSACTION_PAGE,
        payload: params,
    }),
    getTotalOverview: params => ({
        type: TransactionsActionTypes.GET_TOTAL_OVERVEIW,
        payload: params,
    }),
    storeTotalOverview: params => ({
        type: TransactionsActionTypes.STORE_TOTAL_OVERVEIW,
        payload: params,
    }),
    // Categorization
    setCategorizationScreenInfo: params => ({
        type: TransactionsActionTypes.SET_CATEGORIZATION_SCREEN_INFO,
        payload: params,
    }),
    setCategorizationList: params => ({
        type: TransactionsActionTypes.SET_CATEGORIZATION_LIST,
        payload: params,
    }),
    countCategorizedInSession: () => ({
        type: TransactionsActionTypes.COUNT_CATEGORIZED_IN_SESSION,
    }),
    addToCategorisationList: params => ({
        type: TransactionsActionTypes.ADD_TO_CATEGORISATION_LIST,
        payload: params,
    }),
    addSpecialToCategorisationList: params => ({
        type: TransactionsActionTypes.ADD_SPECIAL_TO_CATEGORISATION_LIST,
        payload: params,
    }),
    setTotalCategorizationNumberInSession: params => ({
        type: TransactionsActionTypes.SET_TOTAL_CATEGORIZED_IN_SESSION,
        payload: params,
    }),
    getMoreUncategorizedTransactions: params => ({
        type: TransactionsActionTypes.GET_MORE_UNCATEGORIZED_TRANSACTIONS,
        payload: params,
    }),
    getMostUsedCategories: params => ({
        type: TransactionsActionTypes.GET_MOST_USED_CATEGORIES,
        payload: params,
    }),
    storeMostUsedCategories: params => ({
        type: TransactionsActionTypes.STORE_MOST_USED_CATEGORIES,
        payload: params,
    }),
    refuseShareProofOfDocuments: params => ({
        type: TransactionsActionTypes.REFUSE_CATEGORIZATION_PROOF_OF_DOCUMENTS,
        payload: params,
    }),
    setCategorizationSidebar: params => ({
        type: TransactionsActionTypes.SET_CATEGORIZATION_SIDEBAR,
        payload: params,
    }),
    setCategorizationOverlay: params => ({
        type: TransactionsActionTypes.SET_CATEGORIZATION_SIDEBAR,
        payload: params,
    }),
    getTransactionMatchingSubscription: params => ({
        type: TransactionsActionTypes.GET_TRANSACTIONS_MATCHING_SUBSCRIPTION,
        payload: params,
    }),
    storeTransactionMatchingSubscription: params => ({
        type: TransactionsActionTypes.STORE_TRANSACTIONS_MATCHING_SUBSCRIPTION,
        payload: params,
    }),
    getEligibleTransactionMatchingSubscription: params => ({
        type: TransactionsActionTypes.GET_ELIGIBLE_TRANSACTIONS_MATCHING_SUBSCRIPTION,
        payload: params,
    }),
    storeEligibleTransactionMatchingSubscription: params => ({
        type: TransactionsActionTypes.STORE_ELIGIBLE_TRANSACTIONS_MATCHING_SUBSCRIPTION,
        payload: params,
    }),
    activateSubscription: params => ({
        type: TransactionsActionTypes.ACTIVATE_SUBSCRIPTION,
        payload: params,
    }),
    deactivateSubscription: params => ({
        type: TransactionsActionTypes.DEACTIVATE_SUBSCRIPTION,
        payload: params,
    }),
    validateTransactionVat: params => ({
        type: TransactionsActionTypes.VALIDATE_VAT,
        payload: params,
    }),
    refreshTransactionList: params => ({
        type: TransactionsActionTypes.REFRESH_TRANSACTIONS_LIST,
        payload: params,
    }),
};
