import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Section} from './common/Section';
import {SubSectionTitle} from './common/SubSectionTitle';
import {EurAmount} from '../../../../../components/eur-amount/EurAmount';
import {useSimulationData} from '../utils/useSimulationData';

const InputRow = ({label, description, value}) => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box>
                <Box component="span" sx={{fontWeight: 500}}>
                    {label}
                </Box>
                {description && (
                    <Box component="span" sx={{fontSize: theme => theme.typography.pxToRem(14)}}>
                        {' ' + description}
                    </Box>
                )}
            </Box>
            <Box>{value}</Box>
        </Box>
    );
};

InputRow.propTypes = {
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    value: PropTypes.any.isRequired,
};

InputRow.defaultProps = {
    description: undefined,
};

const InputsBlock = ({title, value, description, children}) => {
    return (
        <Box
            sx={{
                backgroundColor: 'v2.light.primary.shades4',
                width: '100%',
                py: 2,
                px: 3,
                borderRadius: '4px',
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
            }}
        >
            <Box
                sx={{
                    fontSize: theme => theme.typography.pxToRem(18),
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{color: 'v2.blueGray.300', fontWeight: 500}}>
                    {title}{' '}
                    {description && (
                        <Box component="span" sx={{fontSize: theme => theme.typography.pxToRem(16), fontWeight: 400}}>
                            {description}
                        </Box>
                    )}
                </Box>
                <Box sx={{fontWeight: 600, fontSize: theme => theme.typography.pxToRem(16)}}>{value}</Box>
            </Box>
            {children}
        </Box>
    );
};

InputsBlock.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.any,
    description: PropTypes.string,
};

InputsBlock.defaultProps = {
    description: undefined,
    value: undefined,
};

const InputsGroup = ({title, children}) => (
    <Box sx={{width: '100%'}}>
        <SubSectionTitle sx={{mb: 2}}>{title}</SubSectionTitle>
        <Box sx={{borderRadius: '24px', display: 'flex', flexDirection: 'column', gap: '4px', overflow: 'hidden'}}>
            {children}
        </Box>
    </Box>
);

InputsGroup.propTypes = {
    title: PropTypes.string.isRequired,
};

export const InputsSummary = () => {
    const isMobileSize = useMediaQuery('(max-width: 960px)');
    const {t} = useTranslation('revenueSimulator');
    const simulation = useSimulationData();
    const inputs = simulation.simulationInput;

    const otherRevenuesSum = Object.values(inputs.professionalInfo.otherRevenue).reduce((acc, curr) => acc + curr);
    const spouseOtherRevenuesSum = Object.values(inputs.professionalInfo.spouseOtherRevenue || {}).reduce(
        (acc, curr) => acc + curr,
        0,
    );
    const turnover = inputs.projection.pricePerDay * inputs.projection.workingDaysPerYear;

    const allCharges = {...inputs.projection.fixedCharges, ...inputs.projection.variableCharges};

    const allChargesSum = Object.values(allCharges).reduce((acc, curr) => acc + curr);

    const {numberOfAdults, numberOfDependents} = inputs.personalInfo;
    const numberOfParts = numberOfAdults + numberOfDependents;

    return (
        <Section Icon={PlaylistAddCheckIcon} title={t('inputsSummary.sectionTitle')} isCollapsable defaultCollapsed>
            <Box
                sx={{
                    display: 'flex',
                    borderRadius: '24px',
                    background: 'white',
                    py: 2,
                    px: 3,
                    gap: 3,
                    flexDirection: isMobileSize ? 'column' : 'row',
                }}
            >
                <InputsGroup title={t('inputsSummary.myCurrentSituation')}>
                    <InputsBlock title={t('inputsSummary.financialSituation')}>
                        <InputRow
                            label={t('inputsSummary.status')}
                            value={inputs.professionalInfo.employmentSituation}
                        />
                        <InputRow
                            label={t('inputsSummary.income')}
                            description={`(${t('inputsSummary.netYearBeforeTax')})`}
                            value={<EurAmount amount={inputs.professionalInfo.monthlyNetIncome} />}
                        />
                        <InputRow
                            label={t('inputsSummary.otherIncome')}
                            value={<EurAmount amount={otherRevenuesSum} />}
                        />
                        <InputRow
                            label={t('inputsSummary.eligibleTravelSubvention')}
                            value={inputs.professionalInfo.eligibleTravelSubvention ? t('yes') : t('no')}
                        />
                        {!inputs.professionalInfo.eligibleTravelSubvention && (
                            <InputRow
                                label={t('inputsSummary.monthsToRestoreTravelSubvention')}
                                value={
                                    inputs.professionalInfo.monthsToRestoreTravelSubvention
                                    + ' '
                                    + t('inputsSummary.months')
                                }
                            />
                        )}
                    </InputsBlock>
                    <InputsBlock title={t('inputsSummary.personalSituation')}>
                        <InputRow
                            label={t('inputsSummary.age')}
                            value={inputs.personalInfo.age + ' ' + t('inputsSummary.years')}
                        />
                        <InputRow label={t('inputsSummary.numberOfParts')} value={numberOfParts} />
                        <InputRow label={t('inputsSummary.numberOfAdults')} value={numberOfAdults} />
                        {inputs.personalInfo.numberOfAdults === 2 && (
                            <>
                                <InputRow
                                    label={t('inputsSummary.spouseEmploymentSituation')}
                                    value={inputs.professionalInfo.spouseEmploymentSituation}
                                />
                                <InputRow
                                    label={t('inputsSummary.spouseIncome')}
                                    description={`(${t('inputsSummary.netYearBeforeTax')})`}
                                    value={<EurAmount amount={inputs.professionalInfo.spouseMonthlyNetIncome} />}
                                />
                                <InputRow
                                    label={t('inputsSummary.spouseOtherIncome')}
                                    value={<EurAmount amount={spouseOtherRevenuesSum} />}
                                />
                            </>
                        )}
                    </InputsBlock>
                </InputsGroup>
                <InputsGroup title={t('inputsSummary.myFreelanceProject')}>
                    <InputsBlock
                        title={t('inputsSummary.targetStartingDate')}
                        value={inputs.projection.targetStartingDate}
                    />
                    <InputsBlock
                        title={t('inputsSummary.turnover')}
                        description={`(${t('inputsSummary.annualExcludingTax')})`}
                        value={<EurAmount amount={turnover} />}
                    >
                        <InputRow
                            label={t('inputsSummary.pricePerDay')}
                            value={<EurAmount amount={inputs.projection.pricePerDay} />}
                        />
                        <InputRow
                            label={t('inputsSummary.workingDaysPerYear')}
                            value={inputs.projection.workingDaysPerYear}
                        />
                    </InputsBlock>
                    <InputsBlock
                        title={t('inputsSummary.professionalCharges')}
                        value={<EurAmount amount={allChargesSum} />}
                    >
                        {Object.entries(allCharges).map(([key, value]) => (
                            <InputRow key={key} label={t(`charges.${key}`)} value={<EurAmount amount={value} />} />
                        ))}
                    </InputsBlock>
                    {inputs.investments.monthlyRetirementSavingsPlanAmount && (
                        <InputsBlock title={t('inputsSummary.placements')}>
                            <InputRow
                                label={t('inputsSummary.monthlyRetirementSavingsPlanAmount')}
                                value={<EurAmount amount={inputs.investments.monthlyRetirementSavingsPlanAmount} />}
                            />
                        </InputsBlock>
                    )}
                </InputsGroup>
            </Box>
        </Section>
    );
};
