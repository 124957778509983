import {call, put, select} from 'redux-saga/effects';
import {selectCurrentCognitoUser} from './auth.selectors';
import {getAndStoreCurrentUserInfo} from './getAndStoreCurrentUserInfo';
import {isUserLoggedIn} from './isUserLoggedIn.saga';
import {push} from '../../../lib/router/connected-router-saga';
import {COOKIE_NAMES, getCookie} from '../../../utils/cookie';
import {getConfig} from '../../config';

export const getIsEmailVerificationRequired = function* () {
    // Handle if there is cookie token.
    const cookieToken = getCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);
    if (cookieToken) {
        return false;
    }

    // Get currently logged in user (from Cognito)
    yield call(getAndStoreCurrentUserInfo, {
        shouldForce: true,
    });

    const user = yield select(selectCurrentCognitoUser);

    return user && !user.isEmailVerified;
};

export const forceEmailVerification = function* () {
    const config = yield call(getConfig);

    yield put(push(config.ROUTE_PATHS.EMAIL_VERIFICATION));
};

export const emailVerificationLoaderSaga = function* () {
    const isUserSignedIn = yield call(isUserLoggedIn);

    if (isUserSignedIn) {
        yield call(getAndStoreCurrentUserInfo, {
            shouldForce: true,
        });
    }

    const isEmailVerificationRequired = yield call(getIsEmailVerificationRequired);

    if (isEmailVerificationRequired) {
        return;
    }

    const config = yield call(getConfig);

    if (!isUserSignedIn) {
        yield put(push(config.ROUTE_PATHS.LOGIN));

        return;
    }

    yield put(push(config.ROUTE_PATHS.DASHBOARD));
};
