import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import {LoadingButton} from '@mui/lab';
import {Alert, Paper} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {ButtonAction} from '../../../../../components/buttons/ButtonAction';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {resolveRoute} from '../../../../../v1/navigation/resolveRoute';
import {ContractSelectors} from '../../../../contract/store/contract.selector';
import {LoadingTypes, useLoadingState} from '../../../../loading';
import {SignatureSelectors} from '../../../../signature/store/signature.selector';
import {UiActions} from '../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../ui/utils/constants';
import {DocumentActions} from '../../../store/document.action';
import {SigningActions} from '../store/signing.action';
import {SigningSelectors} from '../store/signing.selector';

export const DocumentSigningMobile = ({MainActionComponent, areAllDocumentsSigned, isLoadingNextStep}) => {
    const {t} = useTranslation('document');

    const dispatch = useDispatch();

    const contractData = useSelector(ContractSelectors.selectData);
    const isSigned = useSelector(SigningSelectors.selectCurrentDocumentIsSigned);

    const signatureUrl = useSelector(SignatureSelectors.selectUrl);

    const isSigning = useSelector(SigningSelectors.selectIsSigningCurrentDocument);

    const isDownloadingContract = useLoadingState(LoadingTypes.DOWNLOAD_DOCUMENT);

    const documentId = useSelector(SigningSelectors.selectCurrentDocumentId);

    const onNextClick = () => {
        dispatch(SigningActions.openNextDocument());
    };

    return (
        <Paper radius={8} elevation={2} sx={{p: 2}}>
            <Alert
                severity={isSigned ? 'success' : 'warning'}
                sx={{mt: 1, borderRadius: 3, color: isSigned ? 'rgba(0, 0, 0, 0.6)' : 'warning.dark'}}
                icon={isSigned ? <CheckCircleIcon sx={{color: theme => theme.palette.buttonSuccess.main}} /> : <HourglassTopIcon sx={{color: 'warning.dark'}} />}
            >
                {!isSigned ? t('signing.awaitingSignature') : t('signing.contractSigned')}
            </Alert>

            {MainActionComponent
                ? React.createElement(MainActionComponent)
                : areAllDocumentsSigned
                    ? (
                        <Link
                            to={resolveRoute(RoutePaths.DASHBOARD)}
                            style={{textDecoration: 'none', color: 'inherit', alignSelf: 'end'}}
                        >
                            <LoadingButton
                                sx={{py: 0.75, borderRadius: 2, mt: 1, color: '#fff'}}
                                variant="contained"
                                color="info"
                                size="small"
                                loading={isSigning || isLoadingNextStep || !documentId}
                                loadingPosition="start"
                                onClick={() => {
                                    isSigned
                                        ? onNextClick()
                                        : dispatch(SigningActions.signCurrentDocument());
                                }}
                                fullWidth
                            >
                                Retour au Dashboard
                            </LoadingButton>
                        </Link>
                    )
                    : (
                        <LoadingButton
                            sx={{py: 0.75, borderRadius: 2, mt: 1, color: '#fff'}}
                            startIcon={isSigned ? null : <EditIcon />}
                            endIcon={isSigned ? <ArrowForwardIcon /> : null}
                            variant="contained"
                            color={isSigned ? 'buttonSuccess' : 'secondary'}
                            size="small"
                            loading={isSigning}
                            loadingPosition="start"
                            onClick={() => {
                                isSigned
                                    ? onNextClick()
                                    : dispatch(SigningActions.signCurrentDocument());
                            }}
                            fullWidth
                        >
                            {isSigned ? t('signing.next') : t('signing.signButton')}
                        </LoadingButton>
                    )
            }

            {isSigned && (
            <LoadingButton
                sx={{py: 0.75, borderRadius: 2, mt: 1}}
                startIcon={<DownloadIcon />}
                variant="outlined"
                size="small"
                loading={isDownloadingContract}
                disabled={!MainActionComponent || !isSigned || !signatureUrl}
                loadingPosition="start"
                onClick={() => {
                    if (!MainActionComponent || !isSigned || !signatureUrl) {
                        return;
                    }
                    dispatch(DocumentActions.getDocument(contractData.documentId, true));
                }}
                fullWidth
            >
                {t('signing.downloadPDFMobile')}
            </LoadingButton>
            )}

            {isSigned && (
                <ButtonAction
                    startIcon={<PublishedWithChangesIcon />}
                    size="medium"
                    onClick={() => dispatch(UiActions.setActiveModal(ModalsKeys.UPLOAD_SIGNATURE, true))}
                    fullWidth
                    variant="outlined"
                    sx={{mt: 1, py: 0.7, borderRadius: 2, fontSize: theme => theme.typography.pxToRem(14)}}
                >
                    {t('signing.mySignatureTitle')}
                </ButtonAction>
            )}
        </Paper>
    );
};

DocumentSigningMobile.propTypes = {
    MainActionComponent: PropTypes.any,
    areAllDocumentsSigned: PropTypes.bool,
    isLoadingNextStep: PropTypes.bool,
};

DocumentSigningMobile.defaultProps = {
    MainActionComponent: null,
    areAllDocumentsSigned: false,
    isLoadingNextStep: false,
};
