import SendIcon from '@mui/icons-material/Send';
import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import {Section} from './Section';

export const CTASection = ({title, heading, description, button}) => {
    const isMobileSize = useMediaQuery('(max-width: 960px)');
    return (
        <Section Icon={SendIcon} title={title}>
            <Box
                sx={{
                    borderRadius: '16px',
                    py: 3,
                    px: 4,
                    display: 'flex',
                    alignItems: isMobileSize ? 'flex-end' : 'center',
                    justifyContent: 'space-between',
                    background: 'white',
                    flexDirection: isMobileSize ? 'column' : 'row',
                    gap: 2,
                }}
            >
                <Box>
                    <Box
                        sx={{
                            mb: 1,
                            fontSize: theme => theme.typography.pxToRem(21),
                            fontWeight: 700,
                            lineHeight: 1.235,
                            letterSpacing: theme => theme.typography.pxToRem(0.25),
                        }}
                    >
                        {heading}
                    </Box>
                    <Box>{description}</Box>
                </Box>
                {button}
            </Box>
        </Section>
    );
};

CTASection.propTypes = {
    title: PropTypes.string.isRequired,
    heading: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    button: PropTypes.object.isRequired,
};
