import {useMediaQuery} from '@mui/material';

export const roadmapStepsBgColor = 'v2.light.primary.shades4';

export const useChartDimensions = () => {
    const isMobileSize = useMediaQuery(`(max-width: 960px)`);

    const marginLeft = 10;
    const xAxisPaddingLeft = isMobileSize ? 16 : 48;
    const xAxisPaddingRight = xAxisPaddingLeft + marginLeft;
    const barWidth = isMobileSize ? 8 : 24;
    const stepsOffsetLeft = isMobileSize ? '-1px' : '32px';
    const stepsOffsetRight = isMobileSize ? '11px' : '44px';

    return {
        marginLeft,
        xAxisPaddingLeft,
        xAxisPaddingRight,
        barWidth,
        stepsOffsetLeft,
        stepsOffsetRight,
    };
};
