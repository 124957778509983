import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {Box, Button, Typography} from '@mui/material';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {Checkbox} from '../../../../../../components/checkbox/Checkbox';
import {ModalWrapper} from '../../../../../../components/modal-wrapper/ModalWrapper';
import {UiActions} from '../../../../../ui/store/ui.action';
import {UiSelectors} from '../../../../../ui/store/ui.selector';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {AccountingActions} from '../../../accounting/store/accounting.action';
import {AccountingSelector} from '../../../accounting/store/accounting.selector';
import {PAST_YEAR} from '../../../accounting/utils/constants';

const modalKey = ModalsKeys.DEPOSIT_OF_ACCOUNTS_MANUAL_MODE;

const ManualModeModal = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation('companies');
    const [confirmed, setConfirmed] = useState(false);
    const {resetFormality} = useSelector(UiSelectors.createModalDataSelector(
        modalKey,
    )) ?? {};

    const [searchParams] = useSearchParams();
    const year = searchParams.get('year') ?? PAST_YEAR;
    const annualAccounts = useSelector(AccountingSelector.selectFinancialStatements)?.[year] ?? {};

    const {companyId} = useParams();

    const resetAnnualAccounts = () => {
        if (annualAccounts.manual) {
            dispatch(AccountingActions.toggleDepositOfAccountsMode({
                companyId,
                annualAccountId: annualAccounts.id,
                manualMode: false,
            }));
        } else {
            dispatch(AccountingActions.toggleDepositOfAccountsMode({
                companyId,
                annualAccountId: annualAccounts.id,
                manualMode: true,
            }));
        }
        onClose();
    };

    const onClose = () => {
        dispatch(UiActions.setActiveModal(modalKey, false));
    };

    return (
        <ModalWrapper
            modalKey={modalKey}
            handleCloseCB={onClose}
            hasShowClose={true}
            containerSx={{width: '540px'}}
            title={(
                <Typography sx={{
                    fontSize: theme => theme.typography.pxToRem(34),
                    fontWeight: 700,
                    lineHeight: '116.7%',
                    pb: 1,
                }}
                >
                    {!resetFormality && t('formalities.depositOfAccounts.manualMode.title')}
                    {resetFormality && t('formalities.depositOfAccounts.manualMode.resetModal.title')}
                </Typography>
            )}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                }}
            >
                {!resetFormality && (
                    <>
                        <Typography>
                            {t('formalities.depositOfAccounts.manualMode.message')}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                p: 1,
                                borderRadius: '8px',
                                backgroundColor: 'v2.gray.50',

                            }}
                        >
                            <Checkbox checked={confirmed} onChange={() => setConfirmed(confirmed => !confirmed)} />
                            <Box>
                                {t('formalities.depositOfAccounts.manualMode.confirmMessage')}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                gap: 3,
                                justifyContent: 'right',
                            }}
                        >
                            <Button
                                color="info"
                                size="large"
                                variant="outlined"
                                onClick={onClose}
                                sx={{p: '15px 22px'}}
                            >
                                {t('formalities.depositOfAccounts.manualMode.cancelButton')}
                            </Button>
                            <Button
                                color="error"
                                size="large"
                                variant="contained"
                                endIcon={<ArrowForwardIcon />}
                                disabled={!confirmed}
                                onClick={resetAnnualAccounts}
                                sx={{p: '15px 22px'}}
                            >
                                {t('formalities.depositOfAccounts.manualMode.validateButton')}
                            </Button>
                        </Box>
                    </>
                )}
                {resetFormality && (
                    <>
                        <Typography>
                            {t('formalities.depositOfAccounts.manualMode.resetModal.message')}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                gap: 2,
                                justifyContent: 'right',
                            }}
                        >
                            <Button
                                color="info"
                                size="small"
                                variant="outlined"
                                onClick={onClose}
                            >
                                {t('formalities.depositOfAccounts.manualMode.cancelButton')}
                            </Button>
                            <Button
                                color="error"
                                size="small"
                                variant="contained"
                                endIcon={<ArrowForwardIcon />}
                                onClick={resetAnnualAccounts}
                            >
                                {t('formalities.depositOfAccounts.manualMode.resetModal.resetButton')}
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </ModalWrapper>
    );
};

export default ManualModeModal;
