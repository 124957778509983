import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CancelIcon from '@mui/icons-material/Cancel';
import {LoadingButton} from '@mui/lab';
import {Button, Typography, useMediaQuery} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ModalWrapper} from '../../../../components/modal-wrapper/ModalWrapper';
import {ModalsKeys} from '../../../ui/utils/constants';

const modalKey = ModalsKeys.CLOSE_HIWAY_INTEGRATION_UNAVAILABLE_MODAL;

const CloseHiwayIntegrationUnavailableModal = () => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const loading = false;
    const disabled = true;

    return (
        <ModalWrapper
            modalKey={modalKey}
            title={(
                <Typography variant="h4">
                    {t('accountArchiving.closeTitle')}
                </Typography>
            )}
            buttons={({handleClose}) => (
                <>
                    <Button
                        disableElevation
                        fullWidth={isMobileSize}
                        startIcon={<ArrowBackIcon />}
                        onClick={() => {
                            handleClose();
                        }}
                        variant="outlined"
                        color="primary"
                    >
                        {t('accountArchiving.backButton')}
                    </Button>

                    <LoadingButton
                        loading={loading}
                        disabled={disabled}
                        fullWidth={isMobileSize}
                        startIcon={<CancelIcon />}
                        loadingPosition="start"
                        color="secondary"
                        variant="contained"
                    >
                        {t('archivingActions.close')}
                    </LoadingButton>
                </>
            )}
        >
            <Typography>
                {t('accountArchiving.closeErrorText')}
            </Typography>
        </ModalWrapper>
    );
};

export default CloseHiwayIntegrationUnavailableModal;
