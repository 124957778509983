import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

export const EurAmount = ({amount, decimalScale}) => {
    return <NumberFormat decimalScale={decimalScale} thousandSeparator={' '} value={amount} displayType="text" suffix=" €" />;
};

EurAmount.propTypes = {
    amount: PropTypes.number.isRequired,
    decimalScale: PropTypes.number,
};

EurAmount.defaultProps = {
    decimalScale: 2,
};
