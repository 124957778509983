import {FinalStep} from '../components/steps/FinalStep';
import {FirstStep} from '../components/steps/FirstStep';
import {MyFreelanceProjectStep1} from '../components/steps/MyFreelanceProjectStep1';
import {MyFreelanceProjectStep2} from '../components/steps/MyFreelanceProjectStep2';
import {MyInvestmentsStep} from '../components/steps/MyInvestmentsStep';
import {MySituationStep1} from '../components/steps/MySituationStep1';
import {MySituationStep2} from '../components/steps/MySituationStep2';
import {MySituationStep3} from '../components/steps/MySituationStep3';

export const stepNames = {
    FIRST_STEP: 'FIRST_STEP',
    MY_SITUATION_1: 'MY_SITUATION_1',
    MY_SITUATION_2: 'MY_SITUATION_2',
    MY_SITUATION_3: 'MY_SITUATION_3',
    MY_PROJECT_1: 'MY_PROJECT_1',
    MY_PROJECT_2: 'MY_PROJECT_2',
    MY_INVESTMENTS: 'MY_INVESTMENTS',
    FINAL_STEP: 'FINAL_STEP',
};

export const stepsConfig = {
    [stepNames.FIRST_STEP]: {
        component: FirstStep,
        props: {
            hasNavigation: false,
            hasProgressIndicator: false,
        },
    },
    [stepNames.MY_SITUATION_1]: {
        component: MySituationStep1,
    },
    [stepNames.MY_SITUATION_2]: {
        component: MySituationStep2,
    },
    [stepNames.MY_SITUATION_3]: {
        component: MySituationStep3,
    },
    [stepNames.MY_PROJECT_1]: {
        component: MyFreelanceProjectStep1,
    },
    [stepNames.MY_PROJECT_2]: {
        component: MyFreelanceProjectStep2,
    },
    [stepNames.MY_INVESTMENTS]: {
        component: MyInvestmentsStep,
    },
    [stepNames.FINAL_STEP]: {
        component: FinalStep,
        props: {
            hasProgressIndicator: true,
            hasNavigation: false,
        },
    },
};

export const getStepGroups = ({numberOfAdults}) => [
    {
        name: 'mySituation',
        steps: [
            stepNames.MY_SITUATION_1,
            stepNames.MY_SITUATION_2,
            ...(numberOfAdults === 2 ? [stepNames.MY_SITUATION_3] : []),
        ],
    },
    {
        name: 'myProject',
        steps: [stepNames.MY_PROJECT_1, stepNames.MY_PROJECT_2],
    },
    {
        name: 'myInvestments',
        steps: [stepNames.MY_INVESTMENTS],
    },
];

export const getInputSteps = ({stepGroups}) => [
    stepNames.FIRST_STEP,
    ...stepGroups.flatMap(group => group.steps),
    stepNames.FINAL_STEP,
];
