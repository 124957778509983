import BarChartIcon from '@mui/icons-material/BarChart';
import {Box} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {ComparisonTable} from './ComparisonTable';
import {Roadmap} from './Roadmap';
import {Section} from '../common/Section';
import {SubSection} from '../common/SubSection';
import {SubSectionTitle} from '../common/SubSectionTitle';

export const RoadmapSection = () => {
    const {t} = useTranslation('revenueSimulator');
    return (
        <Section Icon={BarChartIcon} title={t('roadmap.sectionTitle')}>
            <SubSection title={t('roadmap.title')}>
                <Roadmap />
            </SubSection>
            <Box sx={{background: 'white', pt: 2, borderRadius: '8px'}}>
                <SubSectionTitle sx={{px: 3}}>{t('comparisonTable.title')}</SubSectionTitle>
                <Box sx={{pb: 2, px: 3, overflowX: 'auto'}}>
                    <ComparisonTable />
                </Box>
            </Box>
        </Section>
    );
};
