import {call, put, select} from 'redux-saga/effects';

import * as actions from './company.actions';
import {selectCompanyDocuments, selectUploadingDocumentIds} from './company.selectors';
import {Toast} from '../../../lib/toast';
import {USER_ROLES} from '../../../utils/user-roles';
import {
    getSignedUploadUrlForDocumentRequest,
    updateCompanyDocumentRequest,
    uploadDocumentRequest,
} from '../api/providers/company/company.provider';
import {selectCurrentCognitoUser} from '../auth/auth.selectors';
import {selectFreelancerAccount} from '../freelancer/freelancer.selectors';

export const uploadDocumentSaga = function* ({payload}) {
    try {
        const uploadingDocumentIds = yield select(selectUploadingDocumentIds);
        yield put(actions.setUploadingDocumentIds([
            ...uploadingDocumentIds,
            payload.documentId,
        ]));

        const currentCognitoUser = yield select(selectCurrentCognitoUser);

        let freelancerId = currentCognitoUser.id;

        if (currentCognitoUser.role !== USER_ROLES.FREELANCER) {
            const freelancerAccount = yield select(selectFreelancerAccount);

            freelancerId = freelancerAccount.id;
        }

        const {companyId, documentId, file: document, onSuccess, hasNotification} = payload;

        const {signedUrl} = yield call(
            getSignedUploadUrlForDocumentRequest,
            freelancerId,
            companyId,
            documentId,
        );

        const file = document?.file || document;
        const subType = document?.subType || null;

        yield call(uploadDocumentRequest, signedUrl, file);

        yield call(updateCompanyDocumentRequest, freelancerId, companyId, documentId, file.name, subType);

        const {
            identificationDocument,
            residenceCertificationDocument,
            insuranceDocument,
            hostIdentificationDocument,
            hostCertificationDocument,
        } = yield select(selectCompanyDocuments);

        if (identificationDocument?.id === documentId) {
            identificationDocument.fileName = file.name;
        } else if (residenceCertificationDocument?.id === documentId) {
            residenceCertificationDocument.fileName = file.name;
        } else if (insuranceDocument?.id === documentId) {
            insuranceDocument.fileName = file.name;
        } else if (hostIdentificationDocument?.id === documentId) {
            hostIdentificationDocument.fileName = file.name;
        } else if (hostCertificationDocument?.id === documentId) {
            hostCertificationDocument.fileName = file.name;
        }

        yield put(actions.storeCompanyDocuments({
            identificationDocument,
            residenceCertificationDocument,
            insuranceDocument,
            hostIdentificationDocument,
            hostCertificationDocument,
        }));

        if (onSuccess && typeof onSuccess === 'function') {
            onSuccess();
        }

        if (hasNotification) {
            Toast.success('documentUploaded');
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);

        Toast.error('anErrorOccurred');
    } finally {
        const uploadingDocumentIds = yield select(selectUploadingDocumentIds);
        yield put(actions.setUploadingDocumentIds(
            uploadingDocumentIds.filter(documentId => documentId !== payload.documentId),
        ));
    }
};
