import {Box} from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import useMediaQuery from '@mui/material/useMediaQuery';
import {Auth} from 'aws-amplify';
import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {iframeStyles} from './styles';
import {Toast} from '../../../../lib/toast';
import {selectCurrentCognitoUser} from '../../../app/auth/auth.selectors';
import {sendEvent} from '../../../app/event/event.actions';
import {EVENT_TYPES} from '../../../app/event/event.constants';

const AdvancedFrame = ({title, url, maxHeight}) => {
    const styles = iframeStyles();
    const iframe = useRef();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    const [isLoading, setIsLoading] = useState(true);
    const [token, setToken] = useState(null);

    const {t} = useTranslation('common');
    const dispatch = useDispatch();
    const [hasError, setHasError] = useState(null);
    const currentCognitoUser = useSelector(selectCurrentCognitoUser);

    Auth.currentSession().then(response => {
        setToken(response.getIdToken().getJwtToken());
    });

    useEffect(() => {
        const handleMessage = event => {
            if (event.origin !== process.env.REACT_APP_LEGACY_BASE_URL) {
                return;
            }

            setIsLoading(false);

            if (event?.data?.currentPage === 'login') {
                setHasError(true);

                Toast.error('couldNotFetchFinancialData');

                dispatch(sendEvent(EVENT_TYPES.ERROR, {
                    message: '[FINANCIAL_PLATFORM] The financial platform returned the login screen.',
                }));
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [currentCognitoUser, dispatch, t]);

    const shouldRenderIframe = () => {
        if (!token) {
            return false;
        }
        return hasError === null || hasError === false;
    };

    return (
        <Box>
            {isLoading && [...Array(10).keys()].map(mask => (
                <Skeleton className={styles.skeleton} key={`${title}-${mask}`} />
            ))}

            {shouldRenderIframe() && (
                <>
                    <iframe
                        onLoad={() => {
                            setIsLoading(false);
                        }}
                        ref={iframe}
                        className={styles.iframe}
                        title={title}
                        src={`${url}${token}`}
                        style={{
                            height: maxHeight || 'calc(((100vh - 50px) - 60px) - 79px)',
                            marginLeft: isMobileSize ? '-12px' : '-32px',
                            marginRight: isMobileSize ? '-12px' : '-32px',
                            marginBottom: '-32px',
                            position: 'relative',
                            width: isMobileSize ? 'calc(100% + 12px)' : 'calc(100% + 64px)',
                            display: isLoading ? 'none' : 'block',
                        }}
                        frameBorder="0"
                    />
                </>
            )}
        </Box>
    );
};

AdvancedFrame.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    maxHeight: PropTypes.string,
};

AdvancedFrame.defaultProps = {
    maxHeight: '',
};

export default AdvancedFrame;
