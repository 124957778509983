import FavoriteIcon from '@mui/icons-material/Favorite';
import HelpIcon from '@mui/icons-material/Help';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import SportsIcon from '@mui/icons-material/Sports';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {FULL_USER_ROLES, UserRoles} from '../../utils/user-roles';

const DtCellRole = ({rowData}) => {
    const Icon = useMemo(() => {
        switch (rowData.role) {
            case UserRoles.CARE:
                return FavoriteIcon;
            case UserRoles.FREELANCER:
                return LaptopMacIcon;
            case UserRoles.ADMINISTRATOR:
                return SupervisorAccountIcon;
            case FULL_USER_ROLES.COACH:
                return SportsIcon;
            default:
                return HelpIcon;
        }
    }, [rowData.role]);

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            color: '#060506',
        }}
        >
            <Icon sx={{
                fontSize: '18px',
            }}
            />
            <Typography
                sx={{
                    fontSize: theme => theme.typography.pxToRem(12),
                    pt: '2px',
                }}
            >
                {rowData.role}
            </Typography>
        </Box>
    );
};

DtCellRole.propTypes = {
    rowData: PropTypes.object.isRequired,
};

export default DtCellRole;
