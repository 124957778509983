import {Auth} from 'aws-amplify';
import {call, select} from 'redux-saga/effects';
import {LoggedInUserSelectors} from '../../../features/user/modules/logged-in-user';
import {getLoggedInUserAccountFlow} from '../../../features/user/modules/logged-in-user/store/logged-in-user.saga';
import {COOKIE_NAMES, getCookie} from '../../../utils/cookie';

export const getCurrentUserInfo = function* getCurrentUserInfo() {
    // Handle if cookie is passed
    const cookieToken = getCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);
    if (cookieToken) {
        let user = yield select(LoggedInUserSelectors.selectLoggedInUser);
        if (!user) {
            yield call(getLoggedInUserAccountFlow);
            user = yield select(LoggedInUserSelectors.selectLoggedInUser);
        }

        return {
            'username': user.id,
            'attributes': {
                'custom:roles': user.role,
                'email': user.email,
                'email_verified': true,
            },
        };
    }
    // Handle normal cognito flow
    return yield call([Auth, Auth.currentUserInfo]);
};
