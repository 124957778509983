import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, List, ListItem, Tooltip} from '@mui/joy';
import {Collapse} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {ItemWithLink} from './ItemWithLink';
import {selectRouterLocation} from '../../../../lib/router/connected-router-saga';

export const CollapsedItemWithSubmenu = ({
    listItem,
    collapsedLists,
    createCollapsedListClickHandler,
}) => {
    const {t} = useTranslation('sidebar');

    const location = useSelector(selectRouterLocation);

    let isActive = listItem.path === '/'
        ? location.pathname === '/'
        : location.pathname.startsWith(listItem.path);

    const listItemWithActive = listItem.subItems.map(item => {
        const isSubMenuActive = location.pathname === item.path;
        if (isSubMenuActive) {
            isActive = true;
        }
        return {...item, isActive: isSubMenuActive};
    });

    const isCollapsed = collapsedLists.includes(listItem.id);

    return (
        <>
            <Tooltip title={t(listItem.title)} placement="right">
                <ListItem
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '64px',
                        height: '36px',
                        p: 1,
                        mt: 1,
                        cursor: 'pointer',
                        borderRadius: 'sm',
                        ...(isActive ? {
                            backgroundColor: 'neutral.plainColor',
                        } : {
                            '&:hover': {
                                backgroundColor: 'background.level2',
                            },
                        }),
                    }}
                    onClick={createCollapsedListClickHandler(listItem.id)}
                >
                    {listItem.icon && (
                        <Box
                            color={isActive ? 'primary.solidColor' : 'text.icon'}
                            fontSize="md"
                        >
                            <FontAwesomeIcon icon={listItem.icon} fixedWidth />
                        </Box>
                    )}
                </ListItem>
            </Tooltip>
            <Collapse
                in={isCollapsed}
                timeout="auto"
                unmountOnExit
            >
                <List
                    component="Box"
                    disablePadding
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        textAlign: 'center',
                    }}
                >
                    {listItemWithActive.map(subItem => {
                        return (
                            <ItemWithLink
                                to={subItem.path}
                                key={subItem.title}
                            >
                                <Tooltip title={t(subItem.title)} placement="right">
                                    <ListItem
                                        sx={{
                                            'display': 'flex',
                                            'alignItems': 'center',
                                            'justifyContent': 'center',
                                            'width': '64px',
                                            'height': '36px',
                                            'p': 1,
                                            'mt': 1,
                                            ...(subItem.isActive ? {
                                                backgroundColor: 'background.level2',
                                                borderRadius: 'sm',
                                            } : {}),
                                            '&:hover': {
                                                color: 'text.primary',
                                                backgroundColor: 'background.level2',
                                                borderRadius: 'sm',
                                            },
                                        }}
                                    >
                                        {subItem.icon && (
                                            <Box
                                                color="text.primary"
                                                fontSize="md"
                                            >
                                                <FontAwesomeIcon icon={subItem.icon} fixedWidth />
                                            </Box>
                                        )}
                                    </ListItem>
                                </Tooltip>
                            </ItemWithLink>
                        );
                    })}
                </List>
            </Collapse>
        </>
    );
};

CollapsedItemWithSubmenu.propTypes = {
    listItem: PropTypes.object.isRequired,
    collapsedLists: PropTypes.array,
    createCollapsedListClickHandler: PropTypes.func.isRequired,
};

CollapsedItemWithSubmenu.defaultProps = {
    collapsedLists: [],
};
