import {Alert as MUIAlert, Typography} from '@mui/material';
import PropTypes from 'prop-types';

import {useWarningAlertStyles} from './styles';

const Alert = ({title, message, severity, ...rest}) => {
    const styles = useWarningAlertStyles();

    return (
        <MUIAlert
            variant="standard"
            elevation={1}
            className={styles.container}
            severity={severity}
            {...rest}
        >
            {!!title && (
                <Typography className={styles.title}>
                    {title}
                </Typography>
            )}

            {!!message && message}
        </MUIAlert>
    );
};

Alert.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
    severity: PropTypes.oneOf(['warning', 'info', 'success', 'error']),
};

Alert.defaultProps = {
    title: null,
    message: null,
    severity: 'warning',
};

export default Alert;
