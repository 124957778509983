import {Divider, FormLabel, Grid, MenuItem, TextField} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {CityAutocomplete} from '../../../../../components/CityAutocomplete';
import {ProfessionAndSpecializationSelectors} from '../../../../job-profession-and-specilties/store/profession-and-specialization.selector';
import {LoadingSelectors, LoadingTypes} from '../../../../loading';
import {ContactSource, StartContractType} from '../../../utils/constants';
import {UserSelect} from '../../user-select/components/UserSelect';

export const AdditionalTopFreelancerFields = ({control, isLoading, errors}) => {
    const {t} = useTranslation('createUser');

    return (
        <>
            <Grid item xs={12} lg={12}>
                <UserSelect
                    data-cy="coach"
                    label={t('coach')}
                    name="coach"
                    control={control}
                    disabled={isLoading}
                    error={!!errors.coach}
                    helperText={errors.coach?.message}
                />
            </Grid>
        </>
    );
};

AdditionalTopFreelancerFields.propTypes = {
    control: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    errors: PropTypes.object,
};

AdditionalTopFreelancerFields.defaultProps = {
    isLoading: false,
    errors: null,
};

export const AdditionalFreelancerFields = function ({control, isLoading, startContractType}) {
    const {t} = useTranslation('createUser');

    const jobTypes = useSelector(ProfessionAndSpecializationSelectors.getAllJobProfessions());
    const isLoadingJobTypes = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GET_PROFESSION_VALUE),
    );


    return (
        <>
            <Divider variant="dashed" sx={{my: 4}} />

            <FormLabel variant="black" sx={{mb: 2, display: 'block'}}>
                {t('coachingInformation')}
            </FormLabel>

            <Grid container spacing={3}>

                <Grid item xs={12} lg={12}>
                    <Controller
                        control={control}
                        name="jobType"
                        render={({field}) => (
                            <TextField
                                data-cy="jobType"
                                variant="filled"
                                label={t('jobType')}
                                disabled={isLoading || isLoadingJobTypes}
                                fullWidth
                                select
                                {...field}
                            >
                                {jobTypes.map(jobType => {
                                    return (
                                        <MenuItem key={jobType} value={jobType}>
                                            {t(`jobTypes.${jobType}`)}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        )}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <CityAutocomplete data-cy="city" label={t('city')} name="city" control={control} disabled={isLoading} />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Controller
                        control={control}
                        name="contactSource"
                        render={({field}) => (
                            <TextField
                                data-cy="contactSource"
                                variant="filled"
                                label={t('contactSource')}
                                disabled={isLoading}
                                fullWidth
                                select
                                {...field}
                            >
                                {Object.values(ContactSource).map(contactSource => {
                                    return (
                                        <MenuItem key={contactSource} value={contactSource}>
                                            {t(`contactSources.${contactSource}`)}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        )}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Controller
                        control={control}
                        name="startContractType"
                        render={({field}) => (
                            <TextField
                                data-cy="startContractType"
                                variant="filled"
                                label={t('startContractType')}
                                disabled={isLoading}
                                fullWidth
                                select
                                {...field}
                            >
                                {Object.values(StartContractType).map(startContractType => {
                                    return (
                                        <MenuItem key={startContractType} value={startContractType}>
                                            {t(`startContractTypes.${startContractType}`)}
                                        </MenuItem>
                                    );
                                })}
                            </TextField>
                        )}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Controller
                        control={control}
                        name="lastDayOfWork"
                        render={({field}) => (
                            <DatePicker
                                {...field}
                                disabled={isLoading}
                                renderInput={params => (
                                    <TextField
                                        data-cy="lastDayOfWork"
                                        {...params}
                                        variant="filled"
                                        label={t('lastDayOfWork')}
                                        fullWidth
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>

                {/* <Grid item xs={12} lg={6}> */}
                {/*    <Controller */}
                {/*        control={control} */}
                {/*        name="contractStatus" */}
                {/*        render={({field}) => ( */}
                {/*            <TextField */}
                {/*                data-cy="contractStatus" */}
                {/*                variant="filled" */}
                {/*                label={t('contractStatus')} */}
                {/*                disabled={isLoading} */}
                {/*                fullWidth */}
                {/*                select */}
                {/*                {...field} */}
                {/*            > */}
                {/*                {Object.values(ContractStatus).map(contractStatus => { */}
                {/*                    return ( */}
                {/*                        <MenuItem key={contractStatus} value={contractStatus}> */}
                {/*                            {t(`contractStatuses.${contractStatus}`)} */}
                {/*                        </MenuItem> */}
                {/*                    ); */}
                {/*                })} */}
                {/*            </TextField> */}
                {/*        )} */}
                {/*    /> */}
                {/* </Grid> */}

                <Grid item xs={12} lg={6}>
                    <Controller
                        control={control}
                        name="registrationDate"
                        render={({field}) => (
                            <DatePicker
                                {...field}
                                disabled={startContractType === StartContractType.DIRECT_START_WITHOUT_PE || isLoading}
                                renderInput={params => (
                                    <TextField
                                        data-cy="registrationDate"
                                        {...params}
                                        variant="filled"
                                        label={t('registrationDate')}
                                        fullWidth
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} lg={6}>
                    <Controller
                        control={control}
                        name="estimatedRegistrationDate"
                        render={({field}) => (
                            <DatePicker
                                {...field}
                                disabled={isLoading}
                                renderInput={params => (
                                    <TextField
                                        data-cy="estimatedRegistrationDate"
                                        {...params}
                                        variant="filled"
                                        label={t('estimatedRegistrationDate')}
                                        fullWidth
                                    />
                                )}
                            />
                        )}
                    />
                </Grid>

                <Grid item xs={12} lg={12}>
                    <Controller
                        control={control}
                        name="note"
                        render={({field}) => (
                            <TextField
                                data-cy="note"
                                variant="filled"
                                label={t('note')}
                                disabled={isLoading}
                                fullWidth
                                {...field}
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </>
    );
};

AdditionalFreelancerFields.propTypes = {
    control: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    startContractType: PropTypes.string,
};

AdditionalFreelancerFields.defaultProps = {
    isLoading: false,
    startContractType: '',
};
