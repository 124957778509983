import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {Grow, Skeleton, Step, StepLabel, Stepper} from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {useDispatch, useSelector} from 'react-redux';
import {FreelanceStepperSubStep} from './FreelanceStepperSubStep';
import {isUserCare} from '../../../../../utils/user-roles';
import {FULL_COMPANY_STATUSES} from '../../../../../v1/app/company/setupCompany/setupCompany.constants';
import {USER_STATUSES} from '../../../../../v1/app/user/user.constants';
import {DOCUMENT_TYPES} from '../../../../../v1/config/constants/documentConstants';
import {BankSelector} from '../../../../bank/store/bank.selector';
import {DatabaseSelectors} from '../../../../document/modules/database/store/database.selector';
import {InsuranceSelectors} from '../../../../insurance/store/insurance.selector';
import {InsuranceStatus} from '../../../../insurance/utils/constants';
import {LoadingTypes, useLoadingState} from '../../../../loading';
import {SidebarSelectors} from '../../../../sidebar/store/sidebar.selector';
import {FreelancerSelectors} from '../../../store/freelancer.selector';
import {
    CapitalDepositInternalSubSteps,
    CapitalDepositStatus,
    FinalizationSubsteps,
} from '../../capital-deposit/utils/constants';
import {CompaniesSelectors} from '../../companies';
import {CompanyRegistrationInternalSubSteps} from '../../company-registration/utils/constants';
import {CompanySetupSubSteps} from '../../company-setup/utils/constants';
import {ContractSigningInternalSubSteps} from '../../contract-signing/utils/constants';
import {OnboardingActions} from '../store/onboarding.action';
import {OnboardingSelectors} from '../store/onboarding.selectors';
import {
    OnboardingCareStepsConfig,
    OnboardingStepsConfig,
    OnboardingStepsConfigWithExistingCompany,
    capitalDepositSubsteps, careFinalizationSubsteps, finalizationSubsteps,
} from '../utils/constants';
import {OnboardingSteps} from '../utils/onboadingStepsConstant';

export const FreelanceStepper = ({
    isRegistrationWithExistingCompany,
    loggedInUser,
}) => {
    const {t} = useTranslation('freelancerOnboarding');
    const dispatch = useDispatch();

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const activeStepKey = useSelector(OnboardingSelectors.selectStep);
    const activeSubStepKey = useSelector(OnboardingSelectors.selectSubStep);
    const progress = useSelector(OnboardingSelectors.selectProgress);
    const insurance = useSelector(InsuranceSelectors.selectInsurance);

    const activeStepIndex = Object.values(OnboardingSteps).findIndex(stepKey => stepKey === activeStepKey);
    const nextUncompletedStepKey = Object.keys(progress).find(stepKey => !progress[stepKey].isCompleted);

    const isSidebarExpandedSelector = useSelector(SidebarSelectors.selectIsSidebarExpanded);
    const isSidebarExpanded = isMobileSize ? true : isSidebarExpandedSelector;
    const isLoading = useLoadingState(LoadingTypes.ONBOARDING);

    const hasLabel = isSidebarExpanded || isMobileSize;

    const integrations = useSelector(BankSelector.selectIntegrations);
    const hasIntegration = !!integrations?.[0];
    const bankAccountHoldersList = integrations?.[0]?.bankAccountHolders;
    const individualBankAccountHolders = bankAccountHoldersList
        ? bankAccountHoldersList.find(holder => holder.type !== 'COMPANY')
        : undefined;
    const isKbisUploaded = !!(useSelector(
        DatabaseSelectors.createDocumentByTypeSelector(DOCUMENT_TYPES.KBIS),
    ));

    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const company = useSelector(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));
    const deposit = useSelector(BankSelector.selectCapitalDeposit);

    const isUserCareRole = isUserCare(loggedInUser);

    const onClick = key => () => {
        // Prevent going back to sustep 1 while doing bank capital deposit
        if (
            key === OnboardingSteps.CAPITAL_DEPOSIT
            && activeStepKey === OnboardingSteps.CAPITAL_DEPOSIT
            && hasIntegration
            && window.location.pathname.indexOf('freelance-onboarding') !== -1
        ) {
            return;
        }

        if (progress[OnboardingSteps.CONTRACT_SIGNING].isCompleted) {
            dispatch(OnboardingActions.setInternalSubStep(ContractSigningInternalSubSteps.FINAL_SCREEN));
        } else {
            dispatch(OnboardingActions.setInternalSubStep(ContractSigningInternalSubSteps.INITIAL_SCREEN));
        }

        // If user clicked on step 6
        if (key === OnboardingSteps.FINAL_POINTS && !isRegistrationWithExistingCompany) {
            // If user has integration
            if (hasIntegration) {
                // User has not signed final batch
                if (company.status === FULL_COMPANY_STATUSES.PENDING_FINAL_SIGS) {
                    dispatch(OnboardingActions.setSubStep(FinalizationSubsteps.DOCUMENT_SIGNING_FINALIZATION));
                    dispatch(OnboardingActions.setInternalSubStep(CompanyRegistrationInternalSubSteps.FILE_LIST));
                } else if (loggedInUser.status === 'ACTIVE') {
                    if (individualBankAccountHolders?.bankAccounts?.[0]?.status !== 'CLOSED'
                        && individualBankAccountHolders?.bankAccounts?.[0]?.status !== 'CLOSING') {
                        // Account is closing
                        dispatch(OnboardingActions.setSubStep(FinalizationSubsteps.PENDING_FINALIZATION));
                        dispatch(OnboardingActions.setInternalSubStep(
                            FinalizationSubsteps.PENDING_FINALIZATION,
                        ));
                    } else {
                        // eslint-disable-next-line no-lonely-if
                        if (insurance?.status === InsuranceStatus.SIGNED) {
                            // User did everything
                            dispatch(OnboardingActions.setSubStep(FinalizationSubsteps.ACCESS_PLATFORM));
                            dispatch(OnboardingActions.setInternalSubStep(
                                FinalizationSubsteps.ACCESS_PLATFORM,
                            ));
                        } else if (!isUserCareRole) {
                            // User need to fill insurance
                            dispatch(OnboardingActions.setSubStep(FinalizationSubsteps.INSURANCE));
                            dispatch(OnboardingActions.setInternalSubStep(
                                FinalizationSubsteps.INSURANCE,
                            ));
                        }
                    }
                } else {
                    // If user has not validated KYC or paid
                    // eslint-disable-next-line no-lonely-if
                    if (loggedInUser?.status === USER_STATUSES.COMPANY_PENDING
                    && company.status === FULL_COMPANY_STATUSES.REGISTRATION_COMPLETED) {
                        if (isKbisUploaded) {
                            if (!isUserCareRole) {
                                // When there is integration this belongs to 6th step
                                dispatch(OnboardingActions.setStep(OnboardingSteps.FINAL_POINTS));
                                dispatch(OnboardingActions.setSubStep(FinalizationSubsteps.PENDING_FINALIZATION));
                            } else {
                                dispatch(OnboardingActions.setStep(OnboardingSteps.FINAL_POINTS));
                                dispatch(OnboardingActions.setSubStep(FinalizationSubsteps.PENDING_FINALIZATION));
                                dispatch(OnboardingActions.setInternalSubStep(
                                    FinalizationSubsteps.PENDING_BANK_ONBOARDING,
                                ));
                            }
                        } else {
                            dispatch(OnboardingActions.setStep(OnboardingSteps.COMPANY_REGISTRATION));
                            dispatch(OnboardingActions.setSubStep(CompanyRegistrationInternalSubSteps.IBAN_BIC_FORM));
                            dispatch(OnboardingActions.setInternalSubStep(
                                CompanyRegistrationInternalSubSteps.IBAN_BIC_FORM,
                            ));
                        }
                    }
                }
            } else {
                // Handle for non Hiway PRO
                // eslint-disable-next-line no-lonely-if
                if (insurance?.status === InsuranceStatus.SIGNED) {
                    dispatch(OnboardingActions.setSubStep(FinalizationSubsteps.ACCESS_PLATFORM));
                    dispatch(OnboardingActions.setInternalSubStep(
                        FinalizationSubsteps.ACCESS_PLATFORM,
                    ));
                } else {
                    dispatch(OnboardingActions.setSubStep(FinalizationSubsteps.INSURANCE));
                    dispatch(OnboardingActions.setInternalSubStep(
                        FinalizationSubsteps.INSURANCE,
                    ));
                }
            }
        }

        dispatch(OnboardingActions.setStep(key, true));
    };

    if (!activeStepKey) {
        return null;
    }

    // Check if capital deposit specific steps should be added
    if (hasIntegration) {
        if (!progress[OnboardingSteps.CAPITAL_DEPOSIT].isCompleted && !isUserCareRole) {
            if (
                (
                    company?.status !== FULL_COMPANY_STATUSES.PENDING_FINAL_SIGS
                    && company?.status !== FULL_COMPANY_STATUSES.REGISTRATION_COMPLETED
                    && company?.status !== 'READY_FOR_LAUNCH'
                    && loggedInUser?.status !== USER_STATUSES.ACTIVE
                )
                || activeSubStepKey === CapitalDepositInternalSubSteps.BANK_KYC_VALIDATION
                || (
                    deposit?.status !== CapitalDepositStatus.WAITING_FOR_PROOF_OF_REGISTRATION_UPLOAD
                    && company?.status === FULL_COMPANY_STATUSES.REGISTRATION_COMPLETED
                )
            ) {
                OnboardingStepsConfig[OnboardingSteps.CAPITAL_DEPOSIT].subSteps = capitalDepositSubsteps;
            }
        }

        if (
            company?.status === FULL_COMPANY_STATUSES.PENDING_FINAL_SIGS
            || company?.status === FULL_COMPANY_STATUSES.REGISTRATION_COMPLETED
            || loggedInUser.status === 'ACTIVE'
        ) {
            OnboardingStepsConfig[OnboardingSteps.FINAL_POINTS].subSteps = finalizationSubsteps;
            OnboardingCareStepsConfig[OnboardingSteps.FINAL_POINTS].subSteps = careFinalizationSubsteps;
        }
    }

    const onboardingSteps = isRegistrationWithExistingCompany
        ? OnboardingStepsConfigWithExistingCompany
        : isUserCare(loggedInUser)
            ? OnboardingCareStepsConfig
            : OnboardingStepsConfig;

    const onboardingStepsArray = Object.values(onboardingSteps);

    if (isLoading) {
        return (
            <Box sx={{
                px: isSidebarExpanded ? 3 : 3.45,
                pt: 3,
                mt: isSidebarExpanded ? 0 : -1,
            }}
            >
                <Stepper orientation="vertical">
                    {onboardingStepsArray.map((step, index) => {
                        return (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    flexDirection: 'column',
                                }}
                            >
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    py: 1,
                                }}
                                >
                                    <Skeleton variant="circular" width={24} height={24} />
                                    {hasLabel
                                        ? (
                                            <Box sx={{
                                                pl: '8px',
                                            }}
                                            >
                                                <Trans
                                                    t={t}
                                                    i18nKey={step.labelKey}
                                                />

                                            </Box>
                                        )
                                        : ''}
                                </Box>
                                {onboardingStepsArray.length !== index + 1 && (
                                    <Skeleton
                                        sx={{
                                            ml: !isSidebarExpanded ? '12px' : '11px',
                                        }}
                                        variant="rectangular"
                                        width={2}
                                        height={24}
                                    />
                                )}
                            </Box>
                        );
                    })}
                </Stepper>
            </Box>
        );
    }

    return (
        <Box sx={{
            px: isSidebarExpanded ? 3 : 3.45,
            pt: 3,
            mt: isSidebarExpanded ? 0 : -1,
        }}
        >
            <Stepper orientation="vertical" activeStep={activeStepIndex}>
                {Object.values(onboardingSteps).map(step => {
                    const isCompleted = progress[step.key]?.isCompleted ?? false;
                    const isCurrentlyOpen = step.key === activeStepKey;
                    const isNext = step.key === nextUncompletedStepKey;
                    const hasClickEvent = isCompleted || (!isCompleted && isNext);

                    return (
                        <Step
                            key={step.key}
                            onClick={hasClickEvent ? onClick(step.key) : undefined}
                            sx={
                                hasClickEvent
                                    ? {cursor: 'pointer', position: 'relative'}
                                    : {position: 'relative'}}
                            completed={isCompleted}
                            active={isNext || isCurrentlyOpen}
                            disabled={false}
                        >
                            <Grow
                                in={(
                                    (
                                        isCurrentlyOpen
                                        && (
                                            step.key === activeStepKey
                                            && (!step.subSteps || !activeSubStepKey)
                                        )
                                    ) || (
                                        step.key === activeStepKey
                                        && activeStepKey === OnboardingSteps.COMPANY_SETUP
                                        && activeSubStepKey === CompanySetupSubSteps.DATA_VALIDATED)
                                )}
                                unmountOnExit
                                mountOnEnter
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: 0,
                                        left: -24,
                                        bottom: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: '100%',
                                        color: 'rgba(25, 118, 210, 0.5)',
                                    }}
                                >
                                    <ChevronRightIcon />
                                </Box>
                            </Grow>

                            <StepLabel>
                                {hasLabel
                                    ? (
                                        <Trans
                                            t={t}
                                            i18nKey={step.labelKey}
                                        />
                                    ) : ''}
                            </StepLabel>

                            {!!step.subSteps && (activeSubStepKey !== CompanySetupSubSteps.DATA_VALIDATED) && (
                                <FreelanceStepperSubStep
                                    t={t}
                                    hasLabel={hasLabel}
                                    step={step.key}
                                    subSteps={step.subSteps}
                                    subStepProgress={progress[step.key]?.subStepProgress ?? false}
                                />
                            )}
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
};

FreelanceStepper.propTypes = {
    isRegistrationWithExistingCompany: PropTypes.bool,
    loggedInUser: PropTypes.object.isRequired,
};

FreelanceStepper.defaultProps = {
    isRegistrationWithExistingCompany: false,
};
