import PropTypes from 'prop-types';
import {FormProvider, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {Navigation} from './Navigation';
import {ProgressIndicator} from './ProgressIndicator';
import {RevenueSimulatorActions} from '../../../store/revenue-simulator.action';
import {RevenueSimulatorSelectors} from '../../../store/revenue-simulator.selector';

export const StepWrapper = ({children, hasNavigation, hasProgressIndicator}) => {
    const allValues = useSelector(RevenueSimulatorSelectors.selectAllValues);
    const dispatch = useDispatch();
    const handleSubmit = values => dispatch(RevenueSimulatorActions.submitStep(values));

    const methods = useForm({mode: 'onChange', defaultValues: allValues});

    return (
        <>
            <FormProvider {...methods}>
                {hasProgressIndicator && <ProgressIndicator />}
                <form onSubmit={methods.handleSubmit(handleSubmit)}>{children}</form>
                {hasNavigation && <Navigation />}
            </FormProvider>
        </>
    );
};

StepWrapper.propTypes = {
    hasNavigation: PropTypes.bool,
    hasProgressIndicator: PropTypes.bool,
};

StepWrapper.defaultProps = {
    hasNavigation: true,
    hasProgressIndicator: true,
};
