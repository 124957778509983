import Assignment from '@mui/icons-material/Assignment';
import AssignmentTurnedIn from '@mui/icons-material/AssignmentTurnedIn';
import Cancel from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import Euro from '@mui/icons-material/Euro';
import HourglassTop from '@mui/icons-material/HourglassTop';
import Pending from '@mui/icons-material/Pending';
import Send from '@mui/icons-material/Send';
import {Box, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {DEPOSIT_OF_ACCOUNTS_STATUS} from '../utils/constants';

/* eslint-disable react/jsx-key */
const iconAndColor = {
    [DEPOSIT_OF_ACCOUNTS_STATUS.NOT_STARTED]: [
        <Assignment fontSize="24px" />,
        'v2.light.info.main',
    ],
    [DEPOSIT_OF_ACCOUNTS_STATUS.AWAITING_SIGNATURE]: [
        <HourglassTop fontSize="24px" />,
        'v2.light.warning.main',
    ],
    [DEPOSIT_OF_ACCOUNTS_STATUS.AWAITING_PAYMENT]: [
        <Euro fontSize="24px" />,
        'v2.light.warning.main',
    ],
    [DEPOSIT_OF_ACCOUNTS_STATUS.READY_TO_SEND]: [
        <Pending fontSize="24px" />,
        'v2.blueGray.300',
    ],
    [DEPOSIT_OF_ACCOUNTS_STATUS.SENT_TO_INPI]: [
        <Send fontSize="24px" />,
        'v2.light.info.main',
    ],
    [DEPOSIT_OF_ACCOUNTS_STATUS.REJECTED_BY_INPI]: [
        <Cancel fontSize="24px" />,
        'v2.light.error.main',
    ],
    [DEPOSIT_OF_ACCOUNTS_STATUS.VALIDATED_BY_INPI]: [
        <CheckIcon fontSize="24px" />,
        'v2.light.success.light',
    ],
    [DEPOSIT_OF_ACCOUNTS_STATUS.ACTION_REQUIRED_ON_INPI]: [
        <AssignmentTurnedIn fontSize="24px" />,
        'v2.light.warning.main',
    ],
    [DEPOSIT_OF_ACCOUNTS_STATUS.MISSING]: [
        <Cancel fontSize="24px" />,
        'v2.light.error.main',
    ],
};
/* eslint-disable react/jsx-key */

const FormalityStatusTag = ({status}) => {
    const {t} = useTranslation('companies');
    const [icon, color] = iconAndColor[status];

    return (
        <Box
            sx={{
                padding: '2px 8px',
                borderRadius: '8px',
                backgroundColor: color,
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                gap: 1,
            }}
        >
            {icon}
            <Typography sx={{
                fontWeight: 500,
                fontSize: theme => theme.typography.pxToRem(16),
                lineHeight: theme => theme.typography.pxToRem(25),
            }}
            >
                {t(`formalities.validationStatuses.${status}`)}
            </Typography>
        </Box>
    );
};

FormalityStatusTag.propTypes = {
    status: PropTypes.string.isRequired,
};

export default FormalityStatusTag;
