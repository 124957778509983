import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Button, Chip, Paper, Tab, Typography} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import HiwayContent from './HiwayContent';
import OthersContent from './OthersContent';
import {useQuery} from '../../../../hooks/use-url';
import {LoggedInUserSelectors} from '../../../user/modules/logged-in-user';
import {BridgeActions} from '../../modules/bridge-api/store/bridge-api.action';
import {BANK_TYPES} from '../../modules/bridge-api/utils/constants';
import {BankActions} from '../../store/bank.action';
import {BankSelector} from '../../store/bank.selector';
import {LANDING_PAGE_MODE} from '../../utils/constants';
import HiwayBankPromoPage from '../HiwayBankPromoPage';

const TABS = {
    hiway: '1',
    others: '2',
};

const TAB_MAP_TO_CATEGORY = {
    hiway: TABS.hiway,
    others: TABS.others,
};

const ConnectProPage = ({type}) => {
    const {t} = useTranslation('bank');
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const integrations = useSelector(BankSelector.selectIntegrations);
    const hasHiway = integrations.find(integration => integration.type === BANK_TYPES.hiway);

    const paramTab = useQuery('tab');

    const [tab, setTab] = React.useState(
        paramTab
            ? TAB_MAP_TO_CATEGORY[paramTab]
            : TABS.hiway,
    );

    const handleChange = (_, newValue) => {
        setTab(newValue);
    };

    // It has space only for hiway
    if (integrations?.length === 4 && !hasHiway) {
        return (
            <HiwayBankPromoPage
                mode={type}
            />
        );
    }

    if (hasHiway && integrations?.length < 5) {
        return (
            <OtherBlock isMobileSize={isMobileSize} />
        );
    }

    if (integrations?.length === 5) {
        return null;
    }

    return (
        <Box sx={{
            px: isMobileSize ? 2 : 0,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
        }}
        >
            <Box sx={{
                mt: isMobileSize ? 1 : 0,
            }}
            >
                <Title>{t('connectProPage.title')}</Title>
                <SubTitle color="v2.light.text.disabled">{t('connectProPage.subtitle')}</SubTitle>
            </Box>

            <TabContext
                value={tab}
                sx={isMobileSize ? {} : {
                    'mx': 3,
                    'px': 3,
                }}
            >
                <Box sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                }}
                >
                    <TabList
                        centered={true}
                        onChange={handleChange}
                    >
                        <Tab
                            label={(
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                                >
                                    {isMobileSize
                                        ? t('connectProPage.hiway.tabTitleMobile')
                                        : t('connectProPage.hiway.tabTitle')
                                    }
                                    <Chip
                                        sx={{
                                            textTransform: 'none',
                                            fontSize: '12px',
                                            lineHeight: '12px',
                                        }}
                                        size="small"
                                        label={t('connectProPage.recommended')}
                                        color="secondary"
                                    />
                                </Box>
                            )}
                            value={TABS.hiway}
                        />
                        <Tab
                            label={isMobileSize
                                ? t('connectProPage.others.tabTitleMobile')
                                : t('connectProPage.others.tabTitle')
                            }
                            value={TABS.others}
                        />
                    </TabList>
                </Box>

                <TabPanel sx={{p: 0}} value={TABS.hiway}>
                    <HiwayBankPromoPage
                        mode={type}
                    />
                </TabPanel>

                <TabPanel sx={{p: 0}} value={TABS.others}>
                    <OtherBlock isMobileSize={isMobileSize} />
                </TabPanel>
            </TabContext>
        </Box>
    );
};

ConnectProPage.propTypes = {
    type: PropTypes.oneOf(Object.values(LANDING_PAGE_MODE)),
};

ConnectProPage.defaultProps = {
    type: LANDING_PAGE_MODE.GENERIC,
};

const OtherBlock = ({isMobileSize}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const hasBridgeAccess = loggedInUser.hasBridgeAccess;

    const onClickOthers = () => {
        if (hasBridgeAccess) {
            dispatch(BridgeActions.connectToBridge());
        }
    };

    const onConnectWithHiway = () => {
        dispatch(BankActions.createBankAccount());
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
        }}
        >
            <Content
                title={t('connectProPage.others.title')}
                subtitle={t('connectProPage.others.subtitle')}
                isMobileSize={isMobileSize}
            >
                <>
                    <OthersContent />
                    <Button
                        disabled={!hasBridgeAccess}
                        onClick={onClickOthers}
                        color="secondary"
                        startIcon={<AddIcon />}
                        variant="outlined"
                        sx={{alignSelf: isMobileSize ? 'stretch' : 'flex-start'}}
                    >
                        {t(`connectProPage.others.buttonTitle`)}
                    </Button>
                </>
            </Content>

            <Content
                title={t('connectProPage.hiway.title')}
                subtitle={t('connectProPage.hiway.subtitle')}
                isMobileSize={isMobileSize}
            >
                <>
                    <HiwayContent />
                    <Button
                        onClick={onConnectWithHiway}
                        color="secondary"
                        endIcon={<ArrowForwardIcon />}
                        variant="contained"
                        sx={{alignSelf: isMobileSize ? 'stretch' : 'flex-start'}}
                    >
                        {t(`connectProPage.hiway.buttonTitle`)}
                    </Button>
                </>
            </Content>
        </Box>
    );
};

OtherBlock.propTypes = {
    isMobileSize: PropTypes.bool.isRequired,
};

const Title = ({children, isMobileSize}) => (
    <Typography
        variant="h3"
        sx={{
            fontSize: isMobileSize ? '21px !important' : '34px !important',
            lineHeight: isMobileSize ? '26px !important' : '40px !important',
            letterSpacing: isMobileSize ? '0.25px' : '-0.2px',
            pb: 1,
        }}
    >
        {children}
    </Typography>
);

Title.propTypes = {
    isMobileSize: PropTypes.bool.isRequired,
};

const SubTitle = ({children, color, isMobileSize}) => (
    <Typography
        sx={{
            color,
            fontSize: isMobileSize ? '14px !important' : '21px !important',
            lineHeight: isMobileSize ? '20px !important' : '30px !important',
            letterSpacing: isMobileSize ? '0.17px' : '0.25px',
        }}
    >
        {children}
    </Typography>
);

SubTitle.propTypes = {
    color: PropTypes.string,
    isMobileSize: PropTypes.bool.isRequired,
};

SubTitle.defaultProps = {
    color: 'inherit',
};

const Content = ({children, title, subtitle, isMobileSize}) => (
    <Paper
        elevation={0}
        sx={{
            py: isMobileSize ? 3 : 4,
            px: isMobileSize ? 3 : 5,
            borderRadius: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
        }}
    >
        <Box>
            <Title>{title}</Title>
            {subtitle && (
                <SubTitle>{subtitle}</SubTitle>
            )}
        </Box>
        {children}
    </Paper>
);

Content.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    isMobileSize: PropTypes.bool.isRequired,
};

Content.defaultProps = {
    subtitle: '',
};

export default ConnectProPage;
