import {Box, SvgIcon, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

export const Amount = ({amount, smallText, description}) => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Box sx={{display: 'flex', alignItems: 'baseline', columnGap: 1, whiteSpace: 'nowrap', flexWrap: 'wrap', justifyContent: 'center'}}>
                <Box
                    sx={{
                        fontSize: theme => theme.typography.pxToRem(40),
                        letterSpacing: '0.01em',
                        fontWeight: 600,
                        color: 'text_v2.secondary',
                        fontFamily: 'Barlow',
                        display: 'flex',
                        alignItems: 'baseline',
                    }}
                >
                    <NumberFormat
                        thousandSeparator={' '}
                        decimalScale={2}
                        value={amount}
                        displayType="text"
                    />
                    <Box component="span" sx={{fontSize: theme => theme.typography.pxToRem(32), fontWeight: 500}}>
                        &nbsp;€
                    </Box>
                </Box>
                {smallText && (
                    <Box
                        sx={{
                            color: 'v2.blueGray.300',
                            letterSpacing: theme => theme.typography.pxToRem(0.4),
                            mb: 1,
                        }}
                    >
                        {smallText}
                    </Box>
                )}
            </Box>
            {description && <Box sx={{textAlign: 'center'}}>{description}</Box>}
        </Box>
    );
};

Amount.propTypes = {
    amount: PropTypes.number.isRequired,
    smallText: PropTypes.string,
    description: PropTypes.string,
};

Amount.defaultProps = {
    smallText: '',
    description: '',
};

export const AmountsContainer = ({children}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);
    return (
        <Box
            sx={{
                display: 'flex',
                gap: 2,
                flexDirection: isMobileSize ? 'column' : 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            {children}
        </Box>
    );
};

export const Or = () => {
    return <Box sx={{color: 'v2.blueGray.300'}}>ou</Box>;
};

export const Dot = () => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    return isMobileSize ? null : (
        <SvgIcon
            sx={{color: 'v2.blueGray.300', fontSize: theme => theme.typography.pxToRem(6)}}
            viewBox="0 0 7 6"
            fill="none"
        >
            <circle cx="3.5" cy="3" r="3" fill="#90A4AE" />
        </SvgIcon>
    );
};
