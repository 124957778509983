import {Auth} from 'aws-amplify';
import {all, call, fork, put, select, take, takeLatest} from 'redux-saga/effects';
import {
    SUBMIT_LOGIN_FORM,
    clearPasswordError,
    clearUsernameError,
    loginSuccess,
    saveRedirectLocation,
    storeIsLoadingLoginForm,
    storePasswordErrorCode, storeUsernameErrorCode,
} from './loginForm.actions';
import {loginFormErrorCodes} from './loginForm.constants';
import {selectRedirectLocation} from './loginForm.selectors';
import {bankFreelancerLoginAuthFlow} from '../../../../features/bank/store/bank.loader.saga';
import {loadFreelancerCompanies} from '../../../../features/freelancer/modules/companies/store/companies.saga';
import {loadFreelancerAccount} from '../../../../features/freelancer/store/freelancer.saga';
import {runAuthSSETokens} from '../../../../features/server-side-events/store/sse.saga';
import {loadLoggedInUserAccount} from '../../../../features/user/modules/logged-in-user';
import {push, replace} from '../../../../lib/router/connected-router-saga';
import {Toast} from '../../../../lib/toast';
import {COOKIE_NAMES, getCookie} from '../../../../utils/cookie';
import {isUserCareOrFreelancer} from '../../../../utils/user-roles';
import {getConfig} from '../../../config';
import {STORE_LOGGED_IN_USER, getLoggedInUser} from '../../user/user.actions';
import {USER_STATUSES} from '../../user/user.constants';
import {storeChallengeName, storeUser} from '../auth.actions';
import {NEW_PASSWORD_FORM_SUCCESS} from '../newPasswordForm/newPasswordForm.actions';

const clearLoginFormErrorsSaga = function* clearLoginFormErrorsSaga() {
    yield put(clearUsernameError());
    yield put(clearPasswordError());
};

const submitLoginFormSaga = function* submitLoginFormSaga(action) {
    try {
        yield put(storeIsLoadingLoginForm(true));

        yield call(clearLoginFormErrorsSaga);

        const {payload} = action;
        const {username, password} = payload;

        const user = yield call([Auth, Auth.signIn], username, password);

        yield put(storeUser(user));

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            yield put(storeChallengeName(user.challengeName));

            yield put(push(getConfig().ROUTE_PATHS.NEW_PASSWORD));

            yield take(NEW_PASSWORD_FORM_SUCCESS);
        }

        yield put(loginSuccess());

        yield put(getLoggedInUser());

        const {payload: userAccount} = yield take(STORE_LOGGED_IN_USER);

        yield call(loadLoggedInUserAccount);

        if (isUserCareOrFreelancer(userAccount)) {
            yield all([
                call(loadFreelancerAccount, {freelancerId: userAccount.id}),
                call(loadFreelancerCompanies, {freelancerId: userAccount.id}),
            ]);
        }

        if (userAccount.status === USER_STATUSES.CONTRACT_PENDING) {
            yield put(replace('/freelance-onboarding'));
        } else {
            const location = yield select(selectRedirectLocation);

            yield put(saveRedirectLocation(null));

            yield call(bankFreelancerLoginAuthFlow);

            const redirectTo = location?.pathname || getConfig().ROUTE_PATHS.DASHBOARD;
            const search = location?.search || '';

            yield fork(runAuthSSETokens);

            yield put(replace(redirectTo + search));
        }

        const cookieToken = getCookie(COOKIE_NAMES.MOBILE_SESSION_COOKIE);
        if (!cookieToken) {
            // Leave this here!
            const session = yield call([Auth, Auth.currentSession]);
            // eslint-disable-next-line no-console
            console.log(session.getIdToken().getJwtToken());
        }
    } catch (error) {
        // eslint-disable-next-line
        console.error({error});

        switch (error.code) {
            case 'UserNotFoundException':
                yield put(storeUsernameErrorCode(loginFormErrorCodes.USER_DOES_NOT_EXIST));

                break;

            case 'NotAuthorizedException': {
                if (error?.message?.includes('User is disabled.')) {
                    Toast.error('accountDisabled');

                    break;
                }

                if (error?.message?.includes('Temporary password has expired')) {
                    Toast.error('passwordExpired');

                    yield put(storePasswordErrorCode(loginFormErrorCodes.PASSWORD_EXPIRED));
                    break;
                }

                yield put(storePasswordErrorCode(loginFormErrorCodes.INVALID_PASSWORD));

                break;
            }

            default:
                Toast.error('anErrorOccurred');

                break;
        }
    } finally {
        yield put(storeIsLoadingLoginForm(false));
    }
};

export const watchLoginFormSagas = function* watchLoginFormSagas() {
    yield all([takeLatest(SUBMIT_LOGIN_FORM, submitLoginFormSaga)]);
};
