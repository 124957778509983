export const employmentSituationOptions = [
    'salarié(e) en ESN',
    'salarié(e) interne',
    'salarié(e) en portage',
    'freelance en ME',
    'freelance en SASU',
    'freelance en EURL',
    'étudiant(e)',
    'sans emploi',
].map(label => ({label, value: label}));
