import {all, call, fork, put, select, takeEvery} from 'redux-saga/effects';
import {DocumentActions} from './document.action';
import {DocumentActionTypes} from './document.action-type';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {uploadCompanyDocumentRequest} from '../../../v1/app/api/providers/company/company.provider';
import {generateDocumentsRequest} from '../../../v1/app/api/providers/documents/documents.provider';
import {isMobileSafari, isSafari} from '../../../v1/app/app.helpers';
import {getCompanyInformationData} from '../../../v1/app/company/setupCompany/companyInformation/companyInformation.selectors';
import {FreelancerSelectors} from '../../freelancer';
import {loadFreelancerCompanies} from '../../freelancer/modules/companies/store/companies.saga';
import {LoadingActions, LoadingTypes} from '../../loading';
import {DocumentApi} from '../api/document.api';
import {loadDocuments} from '../modules/database/store/database.saga';
import {DatabaseSelectors} from '../modules/database/store/database.selector';
import {DocumentContexts} from '../modules/database/utils/constants';
import {signingSaga} from '../modules/signing/store/signing.saga';

const getDocumentFlow = function* ({freelancerId, companyId, documentId, isDownloading}) {
    try {
        const {signedUrl} = yield call(DocumentApi.getSignedUrlForDocumentRequest, {
            freelancerId,
            companyId,
            documentId,
            isDownloading,
        });

        window.open(signedUrl, '_parent');
    } catch (error) {
        Debug.error('document', 'Error: ', {error});
    }
};

export const getDocumentSignedUrlFlow = function* ({freelancerId, companyId, documentId, isDownloading}) {
    try {
        return yield call(DocumentApi.getSignedUrlForDocumentRequest, {
            freelancerId,
            companyId,
            documentId,
            isDownloading,
        });
    } catch (error) {
        Debug.error('document', 'Error: ', {error});
    }
};

const getDepositDocumentFlow = function* ({freelancerId, companyId, type}) {
    try {
        const document = yield select(DatabaseSelectors.createDepositDocumentByTypeSelector(type));

        if (document) {
            yield call(getDocumentFlow, {
                freelancerId,
                companyId,
                isDownloading: true,
                documentId: document.id,
            });
        } else {
            const {data} = yield call(DocumentApi.getDepositDocumentsRequest, {
                freelancerId,
                companyId,
            });

            const documentByType = data.find(documents => documents.doc_type === type);
            yield call(getDocumentFlow, {
                freelancerId,
                companyId,
                isDownloading: true,
                documentId: documentByType.id,
            });

            yield put(DocumentActions.storeDepositDocuments(data));
        }
    } catch (error) {
        Debug.error('document', 'Error: ', {error});
    }
};

export const uploadDocumentFlow = function* ({freelancerId, companyId, file, type, category, id, subType}) {
    try {
        yield call(uploadCompanyDocumentRequest, freelancerId, companyId, file, type, category, id, subType);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});

        // KBIS UPLOAD 409 - This is covered on three separate places, use this comment content to find them in code
        if (error?.response?.status === 409) {
            if (error?.response.data?.error === 'Could not handle Kbis document upload, company name missing') {
                Toast.warning('kbisUploadCompanyNameMissing');
                return;
            }
            if (error?.response.data?.error === 'Could not handle Kbis document upload, activityStartDate missing') {
                Toast.warning('kbisUploadStartDateMissing');
                return;
            }
            if (error?.response.data?.error === 'Kbis document can not be uploaded until company reaches REGISTRATION_COMPLETED status during onboarding.') {
                Toast.warning('kbisUploadWrongCompanyStatus');
                return;
            }
        }

        if (error?.response?.status === 413) {
            // TODO Handle file too large

            return;
        }

        throw error;
    }
};

export const getKbisDocumentFlow = function* ({freelancerId, companyId, downloading = true}) {
    try {
        const {data} = yield call(DocumentApi.getKbisDocumentsRequest, {
            freelancerId,
            companyId,
        });

        yield call(getDocumentFlow, {
            freelancerId,
            companyId,
            isDownloading: downloading,
            documentId: Array.isArray(data) ? data[0].id : data.id,
        });
    } catch (error) {
        Debug.error('document', 'Error: ', {error});
    }
};

// Workers
const getDocumentWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_DOCUMENT, true));

    const {documentId, isDownloading} = payload;

    const freelancerAccount = yield select(FreelancerSelectors.selectAccount);

    yield call(getDocumentFlow, {
        freelancerId: freelancerAccount.id,
        companyId: freelancerAccount.defaultCompanyId,
        documentId,
        isDownloading,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_DOCUMENT, false));
};

const getDocumentFullDataWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_DOCUMENT, true));

    const {documentId, isDownloading, freelancerId, companyId} = payload;

    yield call(getDocumentFlow, {
        freelancerId,
        companyId,
        documentId,
        isDownloading,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_DOCUMENT, false));
};

const getDocumentQontoWorker = function* () {
    yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_QONTO_DOCUMENT, true));

    const freelancerAccount = yield select(FreelancerSelectors.selectAccount);

    yield call(getDepositDocumentFlow, {
        freelancerId: freelancerAccount.id,
        companyId: freelancerAccount.defaultCompanyId,
        type: 'TUTORIAL_QONTO',
    });

    yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_QONTO_DOCUMENT, false));
};

const getDocumentStatusesWorker = function* () {
    yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_STATUSES_DOCUMENT, true));

    const freelancerAccount = yield select(FreelancerSelectors.selectAccount);
    const company = yield select(getCompanyInformationData);

    yield call(getDepositDocumentFlow, {
        freelancerId: freelancerAccount.id,
        companyId: freelancerAccount.defaultCompanyId,
        type: company?.bank === 'Qonto' ? 'CAPITAL_DEPOSIT_STATUS' : 'REGISTRATION_STATUS',
    });

    yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_STATUSES_DOCUMENT, false));
};

const setDocumentWorker = function* ({payload}) {
    const {file, type, category} = payload;

    yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_DOCUMENT, true));

    const freelancerAccount = yield select(FreelancerSelectors.selectAccount);

    yield call(uploadDocumentFlow, {
        freelancerId: freelancerAccount.id,
        companyId: freelancerAccount.defaultCompanyId,
        file,
        type,
        category,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.UPLOAD_DOCUMENT, false));
};

const getDocumentKbisWorker = function* () {
    yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_DOCUMENT, true));

    const freelancerAccount = yield select(FreelancerSelectors.selectAccount);

    yield call(getKbisDocumentFlow, {
        freelancerId: freelancerAccount.id,
        companyId: freelancerAccount.defaultCompanyId,
    });

    yield put(LoadingActions.setLoading(LoadingTypes.DOWNLOAD_DOCUMENT, false));
};

const regenerateSelectedBatchDocumentsWorker = function* ({payload}) {
    const {freelancerId, companyId, batchNumber} = payload;
    yield put(LoadingActions.setLoading(LoadingTypes.GENERATING_DOCUMENTS, true));
    yield call(generateDocumentsRequest, freelancerId, companyId, batchNumber);
    yield call(loadFreelancerCompanies, {freelancerId});
    yield call(loadDocuments, {
        freelancerId,
        companyId,
        context: 2 === batchNumber ? DocumentContexts.STAGE_TWO : DocumentContexts.SIGNABLE,
    });
};

const getClusterDocumentFlow = function* (payload) {
    try {
        let childWindow;

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow = window.open('', '_blank');
        }

        const {signedUrl} = yield call(DocumentApi.getClusterDocument, {
            freelancerId: payload?.freelancerId,
            documentId: payload?.documentId,
            isDownloading: payload?.isDownloading,
        });

        if (!signedUrl) {
            // noinspection ExceptionCaughtLocallyJS
            throw new Error('The document URL is missing.');
        }

        if ((isSafari || isMobileSafari) && !payload.isDownload) {
            childWindow.location = signedUrl;

            return;
        }

        if ((isSafari || isMobileSafari) && payload.isDownload) {
            // TODO:HIGH: It's ugly but it works.
            fetch(signedUrl).then(response => {
                return response.blob();
            }).then(blob => {
                const matchedGroups = signedUrl.match(/filename[^;=\n]*%3D(%22(.*)%22[^;\n]*)/);
                const filename = matchedGroups[2];

                window.saveAs(blob, decodeURI(filename));
            });

            return;
        }

        window.open(signedUrl, '_blank');
    } catch (error) {
        Debug.error('document', 'Error: ', {error});
    }
};

const getClusterDocumentWorker = function* ({payload}) {
    yield call(getClusterDocumentFlow, payload);
};

export const documentSaga = function* () {
    yield all([
        fork(signingSaga),

        takeEvery(DocumentActionTypes.GET_DOCUMENT, getDocumentWorker),
        takeEvery(DocumentActionTypes.GET_DOCUMENT_FULL_DATA, getDocumentFullDataWorker),
        takeEvery(DocumentActionTypes.GET_QONTO, getDocumentQontoWorker),
        takeEvery(DocumentActionTypes.GET_STATUSES, getDocumentStatusesWorker),
        takeEvery(DocumentActionTypes.UPLOAD_DOCUMENT, setDocumentWorker),
        takeEvery(DocumentActionTypes.GET_KBIS, getDocumentKbisWorker),
        takeEvery(DocumentActionTypes.REGENERATE_SELECTED_BATCH_DOCUMENTS, regenerateSelectedBatchDocumentsWorker),
        takeEvery(DocumentActionTypes.GET_CLUSTER_DOCUMENT, getClusterDocumentWorker),
    ]);
};
