import {Box, Slide, useMediaQuery} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CreditCategorizationPage from './credit/CreditCategorizationPage';
import DebitCategorizationPage from './debit/DebitCategorizationPage';
import CategorisationHeader from './header/CategorisationHeader';
import InfoSidebar from './info-sidebar/InfoSidebar';
import MobileFakeNewScreenWrapper from './mobile/MobileFakeNewScreenWrapper';
import SubscriptionSidebar from './subscription/SubscriptionSidebar';
import DrawerWrapper from '../../../../../../../components/drawer-wrapper/DrawerWrapper';
import ExpandableSidebar from '../../../../../../../components/expandable-sidebar/ExpandableSidebar';
import {USER_ROLES} from '../../../../../../../utils/user-roles';
import {UiActions} from '../../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../../ui/utils/constants';
import {LoggedInUserSelectors} from '../../../../../../user/modules/logged-in-user';
import {TransactionsActions} from '../../../store/transactions.action';
import {TransactionsSelector} from '../../../store/transactions.selector';
import {CATEGORIZATION_SIDEBAR_TYPE, TransactionSide} from '../../../util/constants';
import {checkIsArticleMissingData} from '../utils/functions';

const CategorisationPage = ({isAdminInterface, user}) => {
    const dispatch = useDispatch();
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const loggedInUser = useSelector(LoggedInUserSelectors.selectLoggedInUser);
    const categorizationInfo = useSelector(TransactionsSelector.selectCategorizationScreenInfo);
    const details = useSelector(TransactionsSelector.createSelectTransactionById(categorizationInfo?.transactionId));

    const sidebarInfo = useSelector(TransactionsSelector.selectCategorizationSidebar);
    const transactionNonCategorizedCount = useSelector(TransactionsSelector.selectTransactionNotCategorizedCount);
    const totalOverview = useSelector(TransactionsSelector.selectTransactionTotalOverview);

    // Used for limit/offset if there is a need for additional requests to get more uncategorized
    const [defaultNumberOfUncategorized] = useState(transactionNonCategorizedCount);
    const [isHeaderIn, setIsHeaderIn] = useState(true);

    // TODO Move this into redux
    // const overlayInfo = useSelector(TransactionsSelector.selectCategorizationOverlay);
    const [isShowAllCategories, setIsShowAllCategories] = useState(false);
    const [isArticleViewOpen, setIsArticleViewOpen] = useState(false);
    const [isInvoiceMatchingOpen, setIsInvoiceMatchingOpen] = useState(false);
    const [isReimbursementOpen, setIsReimbursementOpen] = useState(false);
    const [isExternalOpen, setIsExternalOpen] = useState(false);
    const [isFakeReimbursementOpen, setIsFakeReimbursementOpen] = useState(false);
    const [isValidatingVATOpen, setIsValidatingVATOpen] = useState(false);

    const hasFullScreenOpened = isShowAllCategories
        || isInvoiceMatchingOpen
        || isArticleViewOpen
        || isReimbursementOpen
        || isExternalOpen
        || isFakeReimbursementOpen
        || isValidatingVATOpen;

    const isDebit = details?.side !== TransactionSide.CREDIT;
    const isCategorized = details?.isCategorized;

    const setIsSidebarOpened = (value = false) => {
        if (sidebarInfo === value) {
            dispatch(TransactionsActions.setCategorizationSidebar(null));
            setTimeout(() => {
                dispatch(TransactionsActions.setCategorizationSidebar(value));
            }, 20);
        } else {
            dispatch(TransactionsActions.setCategorizationSidebar(value));
        }
    };

    // Used to re-count how many in the session are categorized
    useEffect(() => {
        dispatch(TransactionsActions.countCategorizedInSession());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCategorized]);

    useEffect(() => {
        dispatch(TransactionsActions.getTransaction({
            id: details?.id,
            accountId: details?.bankAccountId,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details?.id]);

    useEffect(() => {
        if (hasFullScreenOpened && sidebarInfo) {
            dispatch(TransactionsActions.setCategorizationSidebar(null));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasFullScreenOpened]);

    useEffect(() => {
        if (Number(totalOverview?.totalUncategorizedTransactions) < 0) {
            dispatch(TransactionsActions.getTotalOverview(isAdminInterface ? user?.id : undefined));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalOverview?.totalUncategorizedTransactions]);

    // Scroll to bottom for admin
    useEffect(() => {
        if (loggedInUser?.role === USER_ROLES.ADMINISTRATOR) {
            window.scrollTo(0, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!categorizationInfo || !details) {
        return null;
    }

    const onGoBack = () => {
        if (sidebarInfo) {
            setIsSidebarOpened(false);
        }

        if (isShowAllCategories) {
            setIsShowAllCategories(false);
        } else if (isArticleViewOpen) {
            // Show modal to ask user is he sure if there are some missing data
            const invalidData = checkIsArticleMissingData(details?.articles);

            if (invalidData) {
                dispatch(UiActions.setActiveModal(ModalsKeys.CATEGORISATION_INVALID_ARTICLES, true));
            } else {
                setIsArticleViewOpen(false);
            }
        } else if (isFakeReimbursementOpen) {
            setIsFakeReimbursementOpen(false);
        } else if (isExternalOpen) {
            // Open modal to confirm if user wants to cancel creation
            dispatch(UiActions.setModalData(ModalsKeys.CATEGORISATION_CLOSE_EXTERNAL_INVOICE, {
                setIsExternalOpen,
            }));
            dispatch(UiActions.setActiveModal(ModalsKeys.CATEGORISATION_CLOSE_EXTERNAL_INVOICE, true));
        } else if (isInvoiceMatchingOpen) {
            setIsInvoiceMatchingOpen(false);
        } else if (isReimbursementOpen) {
            setIsReimbursementOpen(false);
        } else if (isValidatingVATOpen) {
            setIsValidatingVATOpen(false);
        } else {
            setIsHeaderIn(false);
            setTimeout(() => {
                dispatch(TransactionsActions.setCategorizationScreenInfo(null));
                dispatch(TransactionsActions.addSpecialToCategorisationList(false));
            }, 100);
        }
    };

    if (isMobileSize) {
        if (sidebarInfo === CATEGORIZATION_SIDEBAR_TYPE.SUBSCRIPTION_TRANSACTIONS) {
            return (
                <MobileFakeNewScreenWrapper
                    onClose={() => setIsSidebarOpened(false)}
                    onBack={() => {
                        setIsSidebarOpened(CATEGORIZATION_SIDEBAR_TYPE.SUBSCRIPTION);
                    }}
                >
                    <SubscriptionSidebar details={details} isTransactionsOpenedDefault={true} />
                </MobileFakeNewScreenWrapper>
            );
        }

        if (sidebarInfo === CATEGORIZATION_SIDEBAR_TYPE.SUBSCRIPTION) {
            return (
                <MobileFakeNewScreenWrapper
                    onClose={() => setIsSidebarOpened(false)}
                    onBack={() => setIsSidebarOpened(false)}
                >
                    <SubscriptionSidebar details={details} />
                </MobileFakeNewScreenWrapper>
            );
        }
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: sidebarInfo && !isMobileSize ? 'calc(100vw - 400px)' : '100vw',
            transition: 'width 0.4s ease-in-out',
            justifyContent: hasFullScreenOpened ? 'flex-start' : 'space-between',
        }}
        >
            <Slide in={isHeaderIn} direction="left">
                <div>
                    <CategorisationHeader
                        details={details}
                        isInfoOpened={sidebarInfo === CATEGORIZATION_SIDEBAR_TYPE.INFO}
                        hasFullScreenOpened={hasFullScreenOpened}
                        setIsInfoOpened={() => setIsSidebarOpened(CATEGORIZATION_SIDEBAR_TYPE.INFO)}
                        onBack={onGoBack}
                    />
                </div>
            </Slide>

            {isDebit ? (
                <DebitCategorizationPage
                    isShowAllCategories={isShowAllCategories}
                    setIsShowAllCategories={setIsShowAllCategories}
                    isArticleViewOpen={isArticleViewOpen}
                    setIsArticleViewOpen={setIsArticleViewOpen}
                    isSubscriptionOpened={sidebarInfo === CATEGORIZATION_SIDEBAR_TYPE.SUBSCRIPTION}
                    setIsSubscriptionOpened={setIsSidebarOpened}
                    defaultNumberOfUncategorized={defaultNumberOfUncategorized}
                    isValidatingVATOpen={isValidatingVATOpen}
                    onViewValidatedVAT={() => setIsValidatingVATOpen(true)}
                    onCompleteVATValidation={() => setIsValidatingVATOpen(false)}
                />
            ) : (
                <CreditCategorizationPage
                    isShowAllCategories={isShowAllCategories}
                    setIsShowAllCategories={setIsShowAllCategories}
                    isInvoiceMatchingOpen={isInvoiceMatchingOpen}
                    setIsInvoiceMatchingOpen={setIsInvoiceMatchingOpen}
                    isReimbursementOpen={isReimbursementOpen}
                    setIsReimbursementOpen={setIsReimbursementOpen}
                    isExternalOpen={isExternalOpen}
                    setIsExternalOpen={setIsExternalOpen}
                    isFakeReimbursementOpen={isFakeReimbursementOpen}
                    setIsFakeReimbursementOpen={setIsFakeReimbursementOpen}
                    defaultNumberOfUncategorized={defaultNumberOfUncategorized}
                />
            )}

            {!isMobileSize && sidebarInfo ? (
                <Box sx={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                }}
                >
                    <ExpandableSidebar
                        isOpen={sidebarInfo}
                        onClose={() => setIsSidebarOpened(null)}
                        isMobileSize={isMobileSize}
                    >
                        {sidebarInfo === CATEGORIZATION_SIDEBAR_TYPE.SUBSCRIPTION && (
                            <SubscriptionSidebar details={details} />
                        )}
                        {sidebarInfo === CATEGORIZATION_SIDEBAR_TYPE.SUBSCRIPTION_TRANSACTIONS && (
                            <SubscriptionSidebar details={details} isTransactionsOpenedDefault={true} />
                        )}
                        {sidebarInfo === CATEGORIZATION_SIDEBAR_TYPE.INFO && (
                            <InfoSidebar details={details} />
                        )}
                    </ExpandableSidebar>
                </Box>
            ) : (
                <DrawerWrapper
                    isOpen={sidebarInfo}
                    onClose={() => setIsSidebarOpened(null)}
                    containerSx={{
                        pb: 5,
                    }}
                >
                    {sidebarInfo === CATEGORIZATION_SIDEBAR_TYPE.INFO && (
                        <InfoSidebar details={details} />
                    )}
                </DrawerWrapper>
            )}
        </Box>
    );
};

CategorisationPage.propTypes = {
    isAdminInterface: PropTypes.bool,
    user: PropTypes.object,
};

CategorisationPage.defaultProps = {
    isAdminInterface: false,
    user: null,
};

export default CategorisationPage;
