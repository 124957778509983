import {createSelector} from 'reselect';

const selectCategories = state => state.bank.transactions.categories;

const selectCategoriesObject = state => state.bank.transactions.categoriesObject;
const selectSources = state => state.bank.transactions.sources;
const selectSourcesObject = state => state.bank.transactions.sourcesObject;
const selectTransactions = state => state.bank.transactions.transactions;
const selectTransactionIds = state => state.bank.transactions.transactions?.ids ?? [];
const selectTransactionCount = state => state.bank.transactions.transactions?.count ?? 0;
const selectTransactionMaxItems = state => state.bank.transactions.transactions?.maxItems ?? 0;
const selectHasTransactions = state => state.bank.transactions.transactions?.hasTransactions ?? false;
const selectTransactionNotCategorizedCount = state => state.bank.transactions.transactions?.nonCategorizedCount ?? 0;
const selectTransactionObject = state => state.bank.transactions.transactions?.transactionsObject ?? {};
const selectFileLoader = state => state.bank.transactions.transactionFileLoader;
const selectReplacedTransaction = state => state.bank.transactions.replacedTransaction;
const selectTransactionTotalOverview = state => state.bank?.transactions.totalOverview;
const selectCategorizationScreenInfo = state => state.bank?.transactions.categorizationScreenInfo;
const selectCategorizationList = state => state.bank?.transactions.categorizationList;
const selectHasSpecialInCategorisationList = state => state.bank?.transactions.hasSpecialInList;
const selectTotalCategorizedInSession = state => state.bank?.transactions.totalCategorizedInSession;
const selectMostUsedDebitCategories = state => state.bank?.transactions.mostUsedCategories?.debit;
const selectMostUsedCreditCategories = state => state.bank?.transactions.mostUsedCategories?.credit;
const selectCategorizationSidebar = state => state.bank?.transactions.categorizationSidebar;
const selectCategorizationOverlay = state => state.bank?.transactions.categorizationOverlay;
const selectMatchingSubcription = state => state.bank?.transactions.matchingSubscription;
const selectEligibleMatchingSubcription = state => state.bank?.transactions.matchingEligibleSubscription;
const selectShouldRefreshTransactionsList = state => state.bank?.transactions.shouldRefreshTransactionList

const createSelectTransactionById = transactionId => createSelector(
    [selectTransactionObject],
    data => data[transactionId],
);

const createModalFileLoaderSelector = transactionId => createSelector(
    [selectFileLoader],
    data => (transactionId ? data.filter(el => el.transactionId === transactionId && el.inProgress) : []),
);

const createModalFileLoaderSelectorByEventId = eventId => createSelector(
    [selectFileLoader],
    data => (data.find(el => el?.eventId === eventId)),
);

const selectCategoriesWithSubscriptionSupport = state => {
    const categoryList = state.bank.transactions.categories;
    if (!categoryList) {
        return [];
    }

    return categoryList.map(categoryParent => {
        let hasCategory = false;
        const mccGroupItems = categoryParent.mccGroupItems.map(category => {
            const hasSupport = category.subscriptionCategorizationSupport;

            if (hasSupport) {
                hasCategory = true;
                return category;
            }

            return null;
        }).filter(Boolean);

        return hasCategory ? {...categoryParent, mccGroupItems} : null;
    }).filter(Boolean);
};

const selectCategoriesWithArticlesSupport = state => {
    const categoryList = state.bank.transactions.categories;
    if (!categoryList) {
        return [];
    }

    return categoryList.map(categoryParent => {
        let hasCategory = false;
        const mccGroupItems = categoryParent.mccGroupItems.map(category => {
            // For v1 it can not support both subscription and multiple articles
            const hasSubscription = category.subscriptionCategorizationSupport;
            const hasSupport = category.multipleArticles;

            if (hasSupport && !hasSubscription) {
                hasCategory = true;
                return category;
            }

            return null;
        }).filter(Boolean);

        return hasCategory ? {...categoryParent, mccGroupItems} : null;
    }).filter(Boolean);
};

export const TransactionsSelector = {
    selectCategories,
    selectCategoriesWithSubscriptionSupport,
    selectCategoriesWithArticlesSupport,
    selectCategoriesObject,
    selectSources,
    selectSourcesObject,
    selectTransactions,
    selectTransactionIds,
    selectTransactionCount,
    selectTransactionMaxItems,
    selectHasTransactions,
    selectTransactionNotCategorizedCount,
    selectTransactionObject,
    selectFileLoader,
    selectReplacedTransaction,
    createSelectTransactionById,
    createModalFileLoaderSelector,
    createModalFileLoaderSelectorByEventId,
    selectTransactionTotalOverview,
    selectCategorizationScreenInfo,
    selectCategorizationList,
    selectTotalCategorizedInSession,
    selectMostUsedDebitCategories,
    selectMostUsedCreditCategories,
    selectCategorizationSidebar,
    selectMatchingSubcription,
    selectEligibleMatchingSubcription,
    selectHasSpecialInCategorisationList,
    selectCategorizationOverlay,
    selectShouldRefreshTransactionsList,
};
