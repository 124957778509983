import {combineReducers} from 'redux';
import {TransactionsActionTypes} from './transactions.action-type';
import {LOCATION_CHANGED} from '../../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {createReducer} from '../../../../../v1/app/createReducer';

export const transactionsReducer = combineReducers({
    categories: createReducer([], TransactionsActionTypes.STORE_CATEGORY_LIST, false),
    categoriesObject: createReducer({}, TransactionsActionTypes.STORE_CATEGORY_OBJECT, false),
    sources: createReducer([], TransactionsActionTypes.STORE_SOURCES_LIST, false),
    sourcesObject: createReducer({}, TransactionsActionTypes.STORE_SOURCES_OBJECT, false),
    replacedTransaction: createReducer({}, TransactionsActionTypes.REPLACE_TRANSACTION, true),
    totalOverview: createReducer({}, TransactionsActionTypes.STORE_TOTAL_OVERVEIW, false),
    categorizationScreenInfo: createReducer(null, TransactionsActionTypes.SET_CATEGORIZATION_SCREEN_INFO, true),
    categorizationList: createReducer(null, TransactionsActionTypes.SET_CATEGORIZATION_LIST, true),
    hasSpecialInList: createReducer(false, TransactionsActionTypes.ADD_SPECIAL_TO_CATEGORISATION_LIST, true),
    totalCategorizedInSession: createReducer(0, TransactionsActionTypes.SET_TOTAL_CATEGORIZED_IN_SESSION, true),
    categorizationSidebar: createReducer(null, TransactionsActionTypes.SET_CATEGORIZATION_SIDEBAR, true),
    categorizationOverlay: createReducer(null, TransactionsActionTypes.SET_CATEGORIZATION_OVERLAY, true),
    matchingSubscription: createReducer(null, TransactionsActionTypes.STORE_TRANSACTIONS_MATCHING_SUBSCRIPTION, true),
    shouldRefreshTransactionList: createReducer(false, TransactionsActionTypes.REFRESH_TRANSACTIONS_LIST, true),
    matchingEligibleSubscription: createReducer(
        null,
        TransactionsActionTypes.STORE_ELIGIBLE_TRANSACTIONS_MATCHING_SUBSCRIPTION,
        true,
    ),
    mostUsedCategories: createReducer(
        {debit: null, credit: null},
        TransactionsActionTypes.STORE_MOST_USED_CATEGORIES,
        false,
    ),

    transactions: (state = null, action) => {
        if (action.type === TransactionsActionTypes.STORE_TRANSACTION_LIST) {
            return action.payload;
        } else if (action.type === TransactionsActionTypes.STORE_TRANSACTION_IN_OBJECT) {
            if (state?.transactionsObject?.[action.payload.id]) {
                const transaction = {...state.transactionsObject[action.payload.id]};

                return {
                    ...state,
                    transactionsObject: {
                        ...state.transactionsObject,
                        [transaction.id]: {
                            ...transaction,
                            ...action.payload,
                            articles: action.payload?.articles,
                        },
                    },
                };
            }
            return {
                ...state,
                transactionsObject: {
                    ...state.transactionsObject,
                    [action.payload.id]: action.payload,
                },
            };
        } else if (action.type === TransactionsActionTypes.STORE_NON_CATEGORIZED_AMOUNT) {
            return {
                ...state,
                nonCategorizedCount: action.payload,
            };
        } else if (action.type === LOCATION_CHANGED) {
            if (action.payload.location.pathname === RoutePaths.BANK_TRANSACTION_LIST) {
                return state;
            }

            return null;
        }

        return state;
    },

    transactionFileLoader: (state = [], action) => {
        if (action.type === TransactionsActionTypes.UPLOAD_TRANSACTION_FILE_LOADER) {
            const eventId = action.payload.eventId;

            const hasElement = state.findIndex(element => element.eventId === eventId);

            if (hasElement !== -1) {
                const newState = [...state];
                newState.splice(hasElement, 1, action.payload);
                return newState;
            }

            return [
                ...state,
                action.payload,
            ];
        } else if (action.type === LOCATION_CHANGED) {
            return [];
        }

        return state;
    },
});
