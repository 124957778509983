import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Alert, Box, Button, CircularProgress, Grow, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import DOAFile from './DOAFile/DOAFile';
import SectionStatus from './SectionStatus';
import {selectRouterLocation} from '../../../../../../lib/router/connected-router-saga';
import UploadDocuments from '../../../../../../v1/components/ui-kit/UploadDocuments';
import CategorisationSection
    from '../../../../../bank/modules/account-balance/components/categorisation/components/header/CategorisationSection';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {UiActions} from '../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../ui/utils/constants';
import {AccountingActions} from '../../../accounting/store/accounting.action';
import {AccountingSelector} from '../../../accounting/store/accounting.selector';
import {FILE_FORMAT, PAST_YEAR} from '../../../accounting/utils/constants';
import {DEPOSIT_OF_ACCOUNTS_STATUS, FORMALITY_SECTION_STATUS} from '../../utils/constants';

const FlowStepUploadFinalFec = ({isActive, isFocused, onOpenBalanceSheetDetails}) => {
    const {t} = useTranslation('companies');
    const params = useParams();
    const {companyId} = params;
    const dispatch = useDispatch();
    const location = useSelector(selectRouterLocation);
    const search = location?.query;
    const year = Number(search?.['year']) ?? PAST_YEAR;

    const [files, setFiles] = useState([]);
    const [hasToReplace, setHasToReplace] = useState(false);

    const annualAccounts = useSelector(AccountingSelector.selectFinancialStatements)?.[year] ?? {};
    const areDocumentsGenerated = annualAccounts.generatedDocuments?.length > 0;

    const hasGotFinancialStatements = !!annualAccounts?.year;
    const hasUploaded = !!annualAccounts?.finalFec && !hasToReplace;

    const isDeletingPossible = annualAccounts?.status === DEPOSIT_OF_ACCOUNTS_STATUS.NOT_STARTED;

    useEffect(() => {
        if (!hasGotFinancialStatements) {
            dispatch(AccountingActions.getFinancialStatements({
                companyId,
                year,
            }));
        }
    }, [companyId, dispatch, hasGotFinancialStatements, year]);

    const isLoading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.GET_FINANCIAL_STATEMENTS),
    );

    const isUploadingOrDeleting = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.UPLOAD_FINAL_FEC_FILE),
    );

    const allDocumentsGeneratingInProgress = useSelector(AccountingSelector.selectAllDocumentsGenerationProgress);

    /* const isDownloading = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.DOWNLOAD_FEC_FILE),
    ); */

    const fileLoader = useSelector(AccountingSelector.selectFileLoader);

    useEffect(() => {
        const {eventId, inProgress} = fileLoader?.[0] ?? {};

        if (!!eventId && !inProgress && hasToReplace) {
            setFiles([]);
            if (hasToReplace) {
                // Exit from replace mode after file uploading
                setHasToReplace(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileLoader]);

    const onAddFile = files => {
        setFiles(files);
        dispatch(AccountingActions.uploadFinalFECFile({
            companyId,
            year,
            file: files[0],
        }));
    };

    const onRemoveFile = () => {
        setFiles([]);
    };

    const handleDownload = () => {
        dispatch(AccountingActions.downloadFinalFECFile({
            companyId,
            year,
            type: FILE_FORMAT.CSV,
        }));
    };

    const handleReplace = () => {
        setFiles([]);
        setHasToReplace(true);
    };

    const handleDelete = () => {
        if (isDeletingPossible && annualAccounts?.id) {
            setFiles([]);
            dispatch(AccountingActions.deleteFinalFECFile({
                companyId,
                annualAccountId: annualAccounts.id,
            }));
        }
    };

    if (isLoading) {
        return (
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
                width: '100vw',
                justifyContent: 'space-between',
            }}
            >
                <Box sx={{
                    width: '600px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    alignSelf: 'center',
                    my: 4,
                }}
                >
                    <Box sx={{
                        display: 'flex',
                        gap: 2,
                        alignSelf: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '450px',
                    }}
                    >
                        <CircularProgress />
                    </Box>
                </Box>
            </Box>
        );
    }

    return (
        <Grow in={true}>
            <div>
                <CategorisationSection
                    title={t('accounting.uploadFEC.sections.upload')}
                    status={isActive ? (isFocused && FORMALITY_SECTION_STATUS.REQUIRED) : FORMALITY_SECTION_STATUS.WAITING_FOR_REPORT_UPLOAD}
                    SectionStatusComponent={SectionStatus}
                    isActive={isActive}
                    isFocused={isActive && isFocused}
                    statusTranslationSource="companies"
                    statusTranslationPath="accounting.uploadFec.statuses"
                    sx={{width: '100%', maxWidth: '600px'}}
                >
                    {isActive && (
                        <>
                            <Typography sx={{
                                fontSize: theme => theme.typography.pxToRem(16),
                                lineHeight: theme => theme.typography.pxToRem(22),
                                fontWeight: 600,
                            }}
                            >
                                {t('accounting.uploadFEC.flowTitle', {year})}
                            </Typography>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                width: '100%',
                            }}
                            >
                                {!hasUploaded ? (
                                    <>
                                        <Alert
                                            severity="info"
                                            sx={{
                                                'color': 'text_v2.secondary',
                                                'borderRadius': '12px',
                                                '.MuiAlert-icon': {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                },
                                            }}
                                            icon={(
                                                <InfoOutlinedIcon
                                                    sx={{
                                                        fontSize: 'inherit',
                                                        color: 'v2.light.info.main',
                                                    }}
                                                />
                                        )}
                                        >
                                            {t('accounting.uploadFEC.alert')}
                                        </Alert>

                                        <UploadDocuments
                                            files={files}
                                            onAddFiles={onAddFile}
                                            onDeleteFile={onRemoveFile}
                                            fileLimit={1}
                                            buttonText={t('accounting.uploadFEC.fileUploadButton')}
                                            acceptFiles="text/csv, text/plain"
                                            hasMaxFileInfoForced
                                            readableFileExtension={t('accounting.uploadFEC.extention')}
                                            dropZoneText=""
                                            isDisabled={isLoading || isUploadingOrDeleting}
                                            isUploadInProgress={isUploadingOrDeleting}
                                        />
                                    </>
                                ) : (
                                    <DOAFile
                                        fileName={`${year}.csv`}
                                        onDownload={handleDownload}
                                        onDelete={handleDelete}
                                        onReplace={handleReplace}
                                        isDeletingPossible={isDeletingPossible}
                                        isDeleting={isUploadingOrDeleting}
                                    />
                                )}
                                {!isUploadingOrDeleting && hasUploaded && (
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                        <Typography sx={{fontWeight: 600}}>
                                            {t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.calculatedDataTitle')}
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                backgroundColor: areDocumentsGenerated ? 'v2.light.success.shades4' : 'gray_v2.50',
                                                padding: '8px 16px',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                borderRadius: '8px',
                                                gap: 1,
                                            }}
                                        >
                                            <Typography>
                                                {t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.calculatedDataMessage')}
                                            </Typography>
                                            <Button
                                                variant="text"
                                                endIcon={<ArrowForwardIcon />}
                                                sx={{py: 0}}
                                                onClick={() => {
                                                    dispatch(AccountingActions.getBalanceSheetDetails({companyId, year}));
                                                    onOpenBalanceSheetDetails();
                                                }}
                                            >
                                                {t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.viewAmounts')}
                                            </Button>
                                        </Box>
                                        {!areDocumentsGenerated && (
                                            <Button
                                                variant="contained"
                                                startIcon={allDocumentsGeneratingInProgress ? <CircularProgress size={24} /> : <DocumentScannerIcon />}
                                                disabled={allDocumentsGeneratingInProgress}
                                                onClick={() => {
                                                    dispatch(UiActions.setActiveModal(ModalsKeys.DEPOSIT_OF_ACCOUNTS_GENERATE_DOCUMENTS, true));
                                                }}
                                            >
                                                {t('formalities.depositOfAccounts.flowSteps.uploadFecAndDocuments.buttonGenerateDocuments')}
                                            </Button>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </>
                    )}
                </CategorisationSection>
            </div>
        </Grow>
    );
};

FlowStepUploadFinalFec.propTypes = {
    isActive: PropTypes.bool.isRequired,
    isFocused: PropTypes.bool.isRequired,
    onOpenBalanceSheetDetails: PropTypes.func.isRequired,
};

export default FlowStepUploadFinalFec;
