import {Autocomplete, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import {Controller} from 'react-hook-form';
import {useMemoizedCreateSelector} from '../../../../../hooks/use-memoized-create-selector';
import {UserSelectSelectors} from '../store/user-select.selector';

export const UserSelect = ({control, name, label, ...rest}) => {
    const users = useMemoizedCreateSelector(UserSelectSelectors.createSelectOptions);

    return (
        <Controller
            control={control}
            name={name}
            render={({field}) => (
                <Autocomplete
                    key={field?.value ?? 'autocomplete'}
                    {...field}
                    {...rest}
                    onChange={(event, value) => {
                        field.onChange(value);
                    }}
                    getOptionLabel={option => option.name}
                    options={users}
                    renderInput={params => {
                        return (
                            <TextField
                                error={rest.error}
                                helperText={rest.helperText}
                                {...params}
                                variant="filled"
                                label={label}
                                fullWidth
                            />
                        );
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id} value={option.id}>
                                <div>
                                    {option.name}
                                </div>
                            </li>
                        );
                    }}
                    handleHomeEndKeys
                    clearOnBlur
                    disablePortal
                    selectOnFocus
                />
            )}
        />
    );
};

UserSelect.propTypes = {
    control: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};
