import {yupResolver} from '@hookform/resolvers/yup';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {LoadingButton} from '@mui/lab';
import {
    Box, Collapse,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid, Paper,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';
import {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import {useDispatch, useSelector} from 'react-redux';
import {AdditionalAdministratorFields} from './AdditionalAdministratorFields';
import {AdditionalCareFields, AdditionalTopCareFields} from './AdditionalCareFields';
import {AdditionalFreelancerFields, AdditionalTopFreelancerFields} from './AdditionalFreelancerFields';
import Yup from '../../../../../lib/yup/yup';
import {UserRoles} from '../../../../../utils/user-roles';
import {LoadingTypes, useLoadingState} from '../../../../loading';
import {SidebarSelectors} from '../../../../sidebar/store/sidebar.selector';
import {CreateUserFormActions} from '../store/create-user-form.action';

const createValidationSchema = t => Yup.object().shape({
    role: Yup.string().required().label(t('role')),
    firstName: Yup.string().required().label(t('firstName')),
    lastName: Yup.string().required().label(t('lastName')),
    email: Yup.string().required().email().label(t('email')),
    phone: Yup.string().required().phone().label(t('phone')),
    coach: Yup.object().shape({
        id: Yup.string().uuid().required(),
        name: Yup.string().required(),
    }).when('role', {
        is: role => role !== UserRoles.ADMINISTRATOR,
        then: Yup.object().required().nullable(),
        otherwise: Yup.object().nullable(),
    })
        .label('Coach'),
    taxSystem: Yup.string().when('role', {
        is: role => role === UserRoles.CARE,
        then: Yup.string().required(),
    }),
    jobType: Yup.string().when('role', {
        is: role => role === UserRoles.CARE,
        then: Yup.string().required().label(t('jobType')),
    }),
    contactSource: Yup.string().label(t('contactSource')),
    startContractType: Yup.string().label(t('startContractType')),
    lastDayOfWork: Yup.string().nullable().label(t('lastDayOfWork')),
    contractStatus: Yup.string().label(t('contractStatus')),
    estimatedRegistrationDate: Yup.string().nullable().label(t('estimatedRegistrationDate')),
    registrationDate: Yup.string().nullable().label(t('registrationDate')),
    city: Yup.string().max(24).nullable().label(t('city')),
    note: Yup.string().max(1000).label(t('note')),
    confirmAdministratorCreation: Yup.string().when('role', {
        is: role => role === UserRoles.ADMINISTRATOR,
        then: Yup.string().required().matches(/ADMIN/).label(t('confirmAdministratorCreation')),
    }),
});

export const CreateUserForm = () => {
    const {t} = useTranslation('createUser');
    const dispatch = useDispatch();

    const sidebarRole = useSelector(SidebarSelectors.selectSelectedRole);

    const {
        control,
        handleSubmit: createSubmitHandler,
        formState: {errors},
        watch,
        setValue,
    } = useForm({
        resolver: yupResolver(createValidationSchema(t)),
        defaultValues: {
            role: !Array.isArray(sidebarRole) ? sidebarRole : UserRoles.FREELANCER,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            coach: null,
            taxSystem: '',
            jobType: '',
            contactSource: '',
            startContractType: '',
            lastDayOfWork: null,
            contractStatus: '',
            estimatedRegistrationDate: null,
            registrationDate: null,
            city: null,
            note: '',
            confirmAdministratorCreation: '',
        },
        mode: 'onTouched',
    });

    const role = watch('role');
    const startContractType = watch('startContractType');

    useEffect(() => {
        setValue('taxSystem', '');
        setValue('jobType', '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role]);

    const handleSubmit = data => {
        dispatch(CreateUserFormActions.submit(data));
    };

    const isLoading = useLoadingState(LoadingTypes.CREATE_USER_FORM);

    const defaultFieldProps = {
        fullWidth: true,
        variant: 'filled',
        required: true,
        disabled: isLoading,
    };

    return (
        <form onSubmit={createSubmitHandler(handleSubmit)} noValidate>
            <Paper elevation={2} radius={16} sx={{px: 5, py: 4}}>
                <FormLabel variant="black">
                    {t('role')}
                </FormLabel>

                <Box>
                    <FormControl>
                        <Controller
                            control={control}
                            name="role"
                            render={({field}) => (
                                <RadioGroup {...field} row>
                                    {Object.values(UserRoles).map(role => (
                                        <FormControlLabel
                                            key={role}
                                            value={role}
                                            control={<Radio />}
                                            label={t(`roles.${role}`)}
                                            disabled={isLoading}
                                        />
                                    ))}
                                </RadioGroup>
                            )}
                        />
                    </FormControl>
                </Box>

                <Divider variant="dashed" sx={{my: 4}} />

                <FormLabel variant="black" sx={{mb: 2, display: 'block'}}>
                    {t('userInformation')}
                </FormLabel>

                <Grid container spacing={3}>
                    <Grid data-cy="first-name" item xs={12} lg={6}>
                        <Controller
                            control={control}
                            name="firstName"
                            render={({field}) => (
                                <TextField
                                    label={t('firstName')}
                                    error={!!errors.firstName}
                                    helperText={errors.firstName?.message}
                                    {...field}
                                    {...defaultFieldProps}
                                />
                            )}
                        />
                    </Grid>

                    <Grid data-cy="last-name" item xs={12} lg={6}>
                        <Controller
                            control={control}
                            name="lastName"
                            render={({field}) => (
                                <TextField
                                    label={t('lastName')}
                                    error={!!errors.lastName}
                                    helperText={errors.lastName?.message}
                                    {...field}
                                    {...defaultFieldProps}
                                />
                            )}
                        />
                    </Grid>

                    <Grid data-cy="email" item xs={12} lg={6}>
                        <Controller
                            control={control}
                            name="email"
                            render={({field}) => (
                                <TextField
                                    label={t('email')}
                                    error={!!errors.email}
                                    helperText={errors.email?.message}
                                    {...field}
                                    {...defaultFieldProps}
                                />
                            )}
                        />
                    </Grid>

                    <Grid data-cy="phone" item xs={12} lg={6}>
                        <Controller
                            control={control}
                            name="phone"
                            render={({field}) => (
                                <NumberFormat
                                    {...field}
                                    type="tel"
                                    label={t('phone')}
                                    customInput={TextField}
                                    error={!!errors.phone}
                                    helperText={errors.phone?.message}
                                    format="## ## ## ## ##"
                                    allowEmptyFormatting
                                    mask="#"
                                    {...defaultFieldProps}
                                />
                            )}
                        />
                    </Grid>

                    {role === UserRoles.FREELANCER && (
                        <AdditionalTopFreelancerFields
                            control={control}
                            isLoading={isLoading}
                            startContractType={startContractType}
                            errors={errors}
                        />
                    )}

                    {role === UserRoles.CARE && (
                        <AdditionalTopCareFields
                            control={control}
                            isLoading={isLoading}
                            startContractType={startContractType}
                            errors={errors}
                        />
                    )}
                </Grid>

                <Collapse in={role === UserRoles.FREELANCER} mountOnEnter unmountOnExit>
                    <AdditionalFreelancerFields
                        control={control}
                        isLoading={isLoading}
                        startContractType={startContractType}
                        errors={errors}
                    />
                </Collapse>

                <Collapse in={role === UserRoles.CARE} mountOnEnter unmountOnExit>
                    <AdditionalCareFields
                        control={control}
                        isLoading={isLoading}
                        startContractType={startContractType}
                        errors={errors}
                    />
                </Collapse>

                <Collapse in={role === UserRoles.ADMINISTRATOR} mountOnEnter unmountOnExit>
                    <AdditionalAdministratorFields control={control} isLoading={isLoading} errors={errors} />
                </Collapse>
            </Paper>

            <Box sx={{mt: 3, display: 'flex', justifyContent: 'flex-end'}}>
                <LoadingButton
                    variant="contained"
                    type="submit"
                    color="secondary"
                    size="large"
                    endIcon={<ArrowForwardIcon />}
                    // disabled={!isValid}
                    loading={isLoading}
                    loadingPosition="end"
                >
                    {t('submitButton')}
                </LoadingButton>
            </Box>
        </form>
    );
};
