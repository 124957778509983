import {Box} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import workshopInitialMobile from '../../../../../assets/svg/workshop-initial-mobile.svg';
import workshopPage from '../../../../../assets/svg/workshopPage.svg';

// TODO This should be done with stepper component
export const WorkshopStepper = ({isFinished}) => {
    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    if (isMobileSize) {
        return (
            <img style={{width: '100%'}} src={workshopInitialMobile} alt="workshop initial" />
        );
    }

    return (
        <Box sx={{width: '100%', height: '100%'}}>
            {!isFinished && <img style={{width: '100%'}} src={workshopPage} alt="Workshop pending" />}
            {isFinished && <img style={{width: '100%'}} src={workshopPage} alt="Workshop final" />}
        </Box>
    );
};

WorkshopStepper.propTypes = {
    isFinished: PropTypes.bool.isRequired,
};
