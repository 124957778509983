import {Slide, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {WorkShopBanner} from './WorkshopBanner';
import {WorkshopStepper} from './WorkshopStepper';
import workshopStartPage from '../../../../../assets/svg/workshopStartPage.svg';
import {FreelancerOnboardingWorkshops} from '../../../../../assets/wrapped-svg/freelancer-onboarding-workshops';
import {PageHeader} from '../../../../../components/page-header/PageHeader';
import {useMemoizedCreateSelector} from '../../../../../hooks/use-memoized-create-selector';
import Banner from '../../../../../layout/ContainerWithBigImage/Banner';
import {ContainerHeaderStep2} from '../../../../../layout/ContainerWithBigImage/modules/ContainerHeaderStep2';
import {importLocaleBundle} from '../../../../../lib/i18next';
import {AnimationActions} from '../../../../animations/store/animations.action';
import {AnimationsSelectors} from '../../../../animations/store/animations.selector';
import {ANIMATION_TYPE} from '../../../../animations/utils/constants';
import {LoadingTypes, useLoadingState} from '../../../../loading';
import {FreelancerSelectors} from '../../../store/freelancer.selector';
import {CompaniesSelectors} from '../../companies';
import {OnboardingActions} from '../../onboarding/store/onboarding.action';
import {OnboardingSelectors} from '../../onboarding/store/onboarding.selectors';
import {WorkshopsActions} from '../store/workshops.action';
import {WorkshopsInternalSubSteps} from '../utils/constants';

importLocaleBundle('freelancerOnboarding');

export const WorkshopsStep = () => {
    const internalSubStep = useSelector(OnboardingSelectors.selectInternalSubStep);
    const {t} = useTranslation('freelancerOnboarding');
    const dispatch = useDispatch();

    const freelancer = useSelector(FreelancerSelectors.selectAccount);
    const company = useMemoizedCreateSelector(
        CompaniesSelectors.createCompanyByIdSelector,
        freelancer.defaultCompanyId,
    );

    const isGoingToCompanySetup = useLoadingState(LoadingTypes.GO_TO_COMPANY_SETUP);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const isAnimationActive = useSelector(AnimationsSelectors.selectIsAnimationActive);

    const onEndedAnimation = () => {
        dispatch(AnimationActions.animationEnded(ANIMATION_TYPE.MAIN));
    };

    const onInitialNextClick = () => {
        dispatch(AnimationActions.storeNextDispatch(
            OnboardingActions.setInternalSubStep(WorkshopsInternalSubSteps.COMPLETE_COACHING),
        ));
        dispatch(AnimationActions.setIsSubAnimationActive(false));
    };

    const onNextClick = () => {
        dispatch(AnimationActions.storeNextDispatch(
            WorkshopsActions.goToCompanySetup(),
        ));
        dispatch(AnimationActions.setIsSubAnimationActive(false));
    };

    if (internalSubStep !== WorkshopsInternalSubSteps.INITIAL_SCREEN) {
        const isFinished = company.status !== 'PENDING_ENABLE_SETUP';
        return (
            <WorkShopBanner
                headline={t('workshop.completeCoaching')}
                header={t('workshop.finalHeader')}
                headlineText={t('workshop.coachingHeadlineText')}
                nextText={t('workshop.coachingNextText')}
                nextButtonText={t('workshop.coachingNextButton')}
                onNextClick={onNextClick}
                content={<WorkshopStepper isFinished={isFinished} />}
                isFinished={isFinished}
                NextButtonProps={{
                    isLoading: isGoingToCompanySetup,
                    loadingPosition: 'end',
                    disabled: !isFinished,
                }}
            />
        );
    }

    return (
        <Box display="grid" gridTemplateRows="min-content 1fr">
            {!isMobileSize && (
            <PageHeader>
                {t('workshops')}
            </PageHeader>
            )}
            <Slide in={isAnimationActive} direction="left" onEntered={onEndedAnimation} onExited={onEndedAnimation}>
                <div>
                    <Banner
                        header={(
                            <ContainerHeaderStep2
                                mainText={t('workshopHeaderBlack')}
                                colorText={t('workshopHeaderRed')}
                            />
                        )}
                        text={t('workshopText')}
                        Illustration={!isMobileSize
                            ? (
                                <Box sx={{width: '325px', height: '464px', ml: 15, position: 'relative'}}>
                                    <img src={workshopStartPage} alt="Workshop" />
                                </Box>
                            )
                            : <FreelancerOnboardingWorkshops sx={{width: '171px', height: '217px'}} />
                        }
                        nextText={t('workShopNextText')}
                        nextButtonText={t('workshopNextButtonText')}
                        onNextClick={onInitialNextClick}
                    />
                </div>
            </Slide>
        </Box>
    );
};
