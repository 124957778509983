import {useSelector} from 'react-redux';

import {selectCurrentCognitoUser} from '../../app/auth/auth.selectors';

const RoleBasedVisibility = ({children, requiredRoles}) => {
    const currentCognitoUser = useSelector(selectCurrentCognitoUser);

    if (!currentCognitoUser || !requiredRoles.includes(currentCognitoUser.role)) {
        return null;
    }

    return children;
};

export default RoleBasedVisibility;
