import {Alert, Divider, TextField} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {Controller} from 'react-hook-form';
import {Trans, useTranslation} from 'react-i18next';

export const AdditionalAdministratorFields = function ({control, isLoading, errors}) {
    const {t} = useTranslation('createUser');

    return (
        <>
            <Divider variant="dashed" sx={{my: 4}} />

            <Alert severity="warning" sx={{mb: 1}}>
                <Trans t={t} i18nKey="createAdministratorNote" components={{bold: <strong />}} />
            </Alert>

            <Controller
                control={control}
                name="confirmAdministratorCreation"
                render={({field}) => (
                    <TextField
                        {...field}
                        variant="filled"
                        label={t('confirmAdministratorCreation')}
                        disabled={isLoading}
                        error={!!errors.confirmAdministratorCreation}
                        helperText={errors.confirmAdministratorCreation?.message}
                        required
                        fullWidth
                    />
                )}
            />
        </>
    );
};

AdditionalAdministratorFields.propTypes = {
    control: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
};

AdditionalAdministratorFields.defaultProps = {
    isLoading: false,
};
