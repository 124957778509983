import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const SubSectionTitle = ({children, sx}) => {
    return (
        <Box
            sx={{
                fontSize: theme => theme.typography.pxToRem(21),
                letterSpacing: theme => theme.typography.pxToRem(0.25),
                fontWeight: 'bold',
                mb: 2,
                ...sx,
            }}
        >
            {children}
        </Box>
    );
};

SubSectionTitle.propTypes = {
    sx: PropTypes.object,
};

SubSectionTitle.defaultProps = {
    sx: undefined,
};
