import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import React, {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {Amount, AmountsContainer, Dot, Or} from './Amount';
import {DescriptionBlock} from './DescriptionBlock';
import {Tab, TabsBlock} from './TabsBlock';
import img1 from '../../../../../../assets/png/revenue-simulator-results-income.png';
import img4 from '../../../../../../assets/png/revenue-simulator-results-investments.png';
import img3 from '../../../../../../assets/png/revenue-simulator-results-social-benefits.png';
import img2 from '../../../../../../assets/png/revenue-simulator-results-spouse.png';
import {EurAmount} from '../../../../../../components/eur-amount/EurAmount';
import {useSimulationData} from '../../utils/useSimulationData';
import {Section} from '../common/Section';
import {SubSection} from '../common/SubSection';

export const IncomeSection = () => {
    const simulation = useSimulationData();
    const input = simulation.simulationInput;
    const output = simulation.simulationOutput;
    const {t} = useTranslation('revenueSimulator');

    const revenueIncrease = [
        output.income.annualIncomeBeforeTaxes - input.professionalInfo.monthlyNetIncome * 12,
        output.income.annualIncomeAfterTaxes - input.professionalInfo.monthlyNetIncome * 12,
    ];

    const [futureIncomeTab, setFutureIncomeTab] = useState(0);
    const numberOfParts = input.personalInfo.numberOfAdults + input.personalInfo.numberOfDependents;

    return (
        <Section Icon={RocketLaunchIcon} title={t('income.title')}>
            <SubSection title={t('income.yourFutureIncome.title')}>
                <TabsBlock onTabChange={setFutureIncomeTab}>
                    <Tab title={t('income.beforeTaxes')}>
                        <AmountsContainer>
                            <Amount amount={output.income.annualIncomeBeforeTaxes} smallText={t('income.perYear')} />
                            <Dot />
                            <Amount amount={output.income.monthlyIncomeBeforeTaxes} smallText={t('income.perMonth')} />
                        </AmountsContainer>
                    </Tab>
                    <Tab title={t('income.afterTaxes')} isDisabled={numberOfParts >= 2}>
                        <AmountsContainer>
                            <Amount amount={output.income.annualIncomeAfterTaxes} smallText={t('income.perYear')} />
                            <Dot />
                            <Amount amount={output.income.monthlyIncomeAfterTaxes} smallText={t('income.perMonth')} />
                        </AmountsContainer>
                    </Tab>
                </TabsBlock>
                <DescriptionBlock image={img1}>
                    <Trans
                        t={t}
                        i18nKey="income.yourFutureIncome.description"
                        components={{amount: <EurAmount amount={revenueIncrease[futureIncomeTab]} />}}
                    />
                </DescriptionBlock>
            </SubSection>
            {output.householdIncome && numberOfParts >= 2 && (
                <SubSection title={t('income.yourHomeTax.title')}>
                    <TabsBlock>
                        <Tab title={t('income.beforeTaxes')}>
                            <AmountsContainer>
                                <Amount
                                    amount={output.householdIncome.annualIncomeBeforeTaxes}
                                    smallText={t('income.perYear')}
                                />
                                <Dot />
                                <Amount
                                    amount={output.householdIncome.monthlyIncomeBeforeTaxes}
                                    smallText={t('income.perMonth')}
                                />
                            </AmountsContainer>
                        </Tab>
                        <Tab title={t('income.afterTaxes')}>
                            <AmountsContainer>
                                <Amount
                                    amount={output.householdIncome.annualIncomeAfterTaxes}
                                    smallText={t('income.perYear')}
                                />
                                <Dot />
                                <Amount
                                    amount={output.householdIncome.monthlyIncomeAfterTaxes}
                                    smallText={t('income.perMonth')}
                                />
                            </AmountsContainer>
                        </Tab>
                    </TabsBlock>
                    <DescriptionBlock image={img2}>
                        <Trans t={t} i18nKey="income.yourHomeTax.description" />
                    </DescriptionBlock>
                </SubSection>
            )}
            <SubSection title={t('income.yourSocialBenefits.title')}>
                <TabsBlock>
                    <Tab title={t('income.yourSocialBenefits.firstYear.title')}>
                        <AmountsContainer>
                            <Amount
                                amount={output.socialBenefits.firstYear}
                                smallText={t('income.yourSocialBenefits.firstYear.smallText')}
                                description={t('income.yourSocialBenefits.firstYear.description')}
                            />
                        </AmountsContainer>
                    </Tab>
                    <Tab title={t('income.yourSocialBenefits.inCaseOfClosure.title')}>
                        <AmountsContainer>
                            <Amount
                                amount={output.socialBenefits.inCaseOfClosure}
                                smallText={t('income.yourSocialBenefits.inCaseOfClosure.smallText')}
                                description={t('income.yourSocialBenefits.inCaseOfClosure.description')}
                            />
                        </AmountsContainer>
                    </Tab>
                </TabsBlock>
                <DescriptionBlock image={img3}>
                    <Trans t={t} i18nKey="income.yourSocialBenefits.description" />
                </DescriptionBlock>
            </SubSection>
            {input.investments.monthlyRetirementSavingsPlanAmount && (
                <SubSection title={t('income.yourInvestments.title')}>
                    <TabsBlock>
                        <Tab title={t('income.yourInvestments.wise')}>
                            <AmountsContainer>
                                <Amount
                                    amount={output.investments.wise.retirementCapital}
                                    description={t('income.retirementCapital')}
                                />
                                <Or />
                                <Amount
                                    amount={output.investments.wise.monthlyGains}
                                    smallText={t('income.perMonth')}
                                    description={t('income.monthlyGains')}
                                />
                            </AmountsContainer>
                        </Tab>
                        <Tab title={t('income.yourInvestments.balanced')}>
                            <AmountsContainer>
                                <Amount
                                    amount={output.investments.balanced.retirementCapital}
                                    description={t('income.retirementCapital')}
                                />
                                <Or />
                                <Amount
                                    amount={output.investments.balanced.monthlyGains}
                                    smallText={t('income.perMonth')}
                                    description={t('income.monthlyGains')}
                                />
                            </AmountsContainer>
                        </Tab>
                        <Tab title={t('income.yourInvestments.dynamic')}>
                            <AmountsContainer>
                                <Amount
                                    amount={output.investments.dynamic.retirementCapital}
                                    description={t('income.retirementCapital')}
                                />
                                <Or />
                                <Amount
                                    amount={output.investments.dynamic.monthlyGains}
                                    smallText={t('income.perMonth')}
                                    description={t('income.monthlyGains')}
                                />
                            </AmountsContainer>
                        </Tab>
                    </TabsBlock>
                    <DescriptionBlock image={img4}>
                        <Trans t={t} i18nKey="income.yourInvestments.description" />
                    </DescriptionBlock>
                </SubSection>
            )}
        </Section>
    );
};
