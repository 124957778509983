import {createSelector} from 'reselect';

const selectActiveModals = state => state.ui.activeModals;
const selectModalData = state => state.ui.modalData;
const selectIsMaintenanceActive = state => state.ui.isMaintenanceActive;

const createIsModalActiveSelector = modalKey => createSelector(
    [selectActiveModals],
    activeModals => !!activeModals[modalKey],
);

const createModalDataSelector = modalKey => createSelector(
    [selectModalData],
    modalData => modalData[modalKey],
);

// Returns true if at least one modal is open
const createHasActiveModal = () => createSelector(
    [selectActiveModals],
    activeModals => {
        return Object.values(activeModals).some(value => value === true);
    },
);

export const UiSelectors = {
    selectActiveModals,
    selectIsMaintenanceActive,
    createIsModalActiveSelector,
    createHasActiveModal,
    createModalDataSelector,
};
