import {Box, useMediaQuery, useTheme} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Bar, BarChart, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import {useChartDimensions} from './utils';
import {useSimulationData} from '../../utils/useSimulationData';

const Legend = ({isMobileSize}) => {
    const {t} = useTranslation('revenueSimulator');
    const {pxToRem} = useTheme().typography;
    const circleStyles = {width: pxToRem(8), height: pxToRem(8), borderRadius: '50%'};
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                position: isMobileSize ? 'static' : 'absolute',
                top: pxToRem(-42),
                right: 0,
                fontSize: pxToRem(12),
                mb: isMobileSize ? 4 : 0,
            }}
        >
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <Box sx={{...circleStyles, backgroundColor: 'v2.blueGray.300'}} />
                <Box>{t('roadmap.legend.currentSituation')}</Box>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                <Box sx={{...circleStyles, backgroundColor: 'v2.amber.300'}} />
                <Box>{t('roadmap.legend.withHiway')}</Box>
            </Box>
        </Box>
    );
};

Legend.propTypes = {
    isMobileSize: PropTypes.bool.isRequired,
};

export const RoadmapChart = () => {
    const graph = useSimulationData()?.simulationOutput.compensationRoadmap.graph;
    const {barWidth, marginLeft, xAxisPaddingLeft, xAxisPaddingRight} = useChartDimensions();
    const isMobileSize = useMediaQuery('(max-width: 960px)');
    const data = useMemo(
        () => Array(13)
            .fill()
            .map((_, i) => ({
                month: i,
                withHiway: graph.withHiway[i],
                withoutHiway: graph.withoutHiway[i],
            })),
        [graph],
    );

    const theme = useTheme();
    const barRadius = [4, 4, 0, 0];
    const tick = {
        fontSize: theme.typography.pxToRem(12),
        fontFamily: 'Red Hat Text',
    };
    const axisLine = {
        stroke: '#e0e0e0',
    };
    return (
        <>
            {isMobileSize && <Legend isMobileSize />}
            <Box position="relative" width="100%" paddingBottom="352px">
                {!isMobileSize && <Legend isMobileSize={false} />}
                <Box position="absolute" left={0} top={0} right={0} bottom={0}>
                    <ResponsiveContainer width="100%" height={360}>
                        <BarChart
                            data={data}
                            margin={{
                                top: 5,
                                right: 0,
                                left: marginLeft,
                                bottom: 5,
                            }}
                            barSize={barWidth}
                            barGap={-barWidth}
                        >
                            <XAxis
                                dataKey="month"
                                tick={tick}
                                axisLine={axisLine}
                                tickLine={false}
                                padding={{left: xAxisPaddingLeft, right: xAxisPaddingRight}}
                                scale="point"
                            />
                            <YAxis
                                tickFormatter={value => (value === 0 ? 0 : value + '€')}
                                tick={tick}
                                axisLine={axisLine}
                                tickLine={axisLine}
                                tickSize={8}
                                tickMargin={8}
                            />
                            <Bar dataKey="withHiway" fill={theme.palette.v2.amber[300]} radius={barRadius} />
                            <Bar dataKey="withoutHiway" fill={theme.palette.v2.blueGray[300]} radius={barRadius} />
                        </BarChart>
                    </ResponsiveContainer>
                </Box>
            </Box>
        </>
    );
};
