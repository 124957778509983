import AttachmentIcon from '@mui/icons-material/Attachment';
import CameraAltTwoToneIcon from '@mui/icons-material/CameraAltTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Box, CircularProgress, FormControlLabel, Switch, Typography} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {v4 as uuidv4} from 'uuid';
import DebitBankQr from './DebitQrCode';
import {TransactionFile} from './TransactionFile';
import {
    BANK_DROPZONE_FILE_SIZES,
    BANK_DROPZONE_MAX_FILES,
    BankDropZone,
} from '../../../../../../../../components/dropzone/BankDropZone';
import {ConditionalTooltip} from '../../../../../../../../components/tooltip/ConditionalTooltip';
import {LoadingSelectors, LoadingTypes} from '../../../../../../../loading';
import {UiActions} from '../../../../../../../ui/store/ui.action';
import {ModalsKeys} from '../../../../../../../ui/utils/constants';
import {TransfersActions} from '../../../../../transfers/store/transfers.actions';
import {SIDEBAR_TYPES} from '../../../../../transfers/utils/constants';
import {TransactionsActions} from '../../../../store/transactions.action';
import {TransactionsSelector} from '../../../../store/transactions.selector';
import {emptyFunction} from '../../../../util/constants';
import TransactionDetailsNoFileModal from '../../../transaction-details/TransactionDetailsNoFileModal';

const TransactionFileSection = ({
    details,
    isEditable,
    isRejectedOrCancelled,
    saveTransaction,
}) => {
    const dispatch = useDispatch();
    const {t} = useTranslation('bank');

    const [fileMenuOpen, setIsFileMenuOpen] = useState(null);
    const [isQROpen, setIsQROpen] = useState(false);
    const [error, setError] = useState([]);

    // If admin is accessing
    const params = useParams();
    const {freelancerId, companyId} = params;

    const fileList = details?.documents;

    const isEditableWithFilesSelected = isEditable && (!fileList || fileList?.length === 0);

    const fileUploadProcessingList = useSelector(TransactionsSelector.createModalFileLoaderSelector(details?.id));
    const isFileUploading = useSelector(LoadingSelectors.createLoadingSelectorByType(
        LoadingTypes.UPLOAD_BANK_FILE,
    )) || false;

    const onFileSelect = useCallback(file => {
        if (details?.id) {
            if (details.type === SIDEBAR_TYPES.editRecurringTransfer) {
                dispatch(TransfersActions.uploadTransferFile({
                    id: details.id,
                    file,
                    accountId: details?.bankAccountId,
                }));
            } else {
                dispatch(TransactionsActions.uploadTransactionFile({
                    id: details.id,
                    file,
                    accountId: details?.bankAccountId,
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details?.id, dispatch]);

    const openNoFileModal = () => {
        const articles = details.articles.map(article => {
            return {
                amount: article?.amount,
                description: article?.description,
                mccKey: article?.mccKey,
                tva: article?.tva,
                tvaAmount: article?.tvaAmount,
            };
        });

        dispatch(UiActions.setModalData(ModalsKeys.BANK_NO_FILE, {
            action: () => {
                saveTransaction(articles, true);
            },
        }));
        dispatch(UiActions.setActiveModal(ModalsKeys.BANK_NO_FILE, true));
    };

    const removeNoFile = () => {
        const articles = details.articles.map(article => {
            return {
                amount: article?.amount,
                description: article?.description,
                mccKey: article?.mccKey,
                tva: article?.tva,
                tvaAmount: article?.tvaAmount,
            };
        });

        saveTransaction(articles, false);
    };

    const deleteErrorFile = fileId => {
        const index = error.findIndex(file => file.id === fileId);
        setError(errorList => {
            const newErrorList = [...errorList];
            newErrorList.splice(index);
            return newErrorList;
        });
    };

    const onRefuseToShareProofDocumentsClick = event => {
        if (event.target.checked) {
            dispatch(UiActions.setModalData(ModalsKeys.CATEGORIZATION_PROOF_OF_DOCUMENTS, {details}));
            dispatch(UiActions.setActiveModal(ModalsKeys.CATEGORIZATION_PROOF_OF_DOCUMENTS, true));
        } else {
            // TODO If needed add modal for chosing to update for current, all, future...
            dispatch(TransactionsActions.refuseShareProofOfDocuments({shouldRefuse: false, id: details?.id}));
        }
    };

    const handleReject = errorsArray => {
        if (errorsArray?.[0]?.errors[0].code && errorsArray?.[0]?.file?.name) {
            setError(error => {
                return [...error, {
                    id: uuidv4(),
                    type: 'ERROR',
                    errorCode: errorsArray?.[0]?.errors[0].code,
                    name: errorsArray?.[0]?.file?.name,
                }];
            });
        }
    };

    if (isQROpen) {
        return (
            <DebitBankQr
                id={details.id}
                type="transaction"
                onClose={() => setIsQROpen(false)}
            />
        );
    }

    const hasPickerSection = !details?.ignoreProofDocument
        && !details?.refuseToShareProofDocuments;

    const hasFiles = fileList?.length > 0;

    return (
        <>
            <>
                {details?.ignoreProofDocument && (
                    <Box
                        sx={{
                            'fontSize': theme => theme.typography.pxToRem(13),
                            'display': 'flex',
                            'justifyContent': 'space-between',
                            'alignItems': 'center',
                            'cursor': 'pointer',
                            'textTransform': 'none',
                            'p': 1,
                            'backgroundColor': '#78909C',
                            'color': 'white',
                            'borderRadius': '8px',
                        }}
                    >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}
                        >
                            <DoNotDisturbAltIcon />
                            <Box component="span" sx={{wordBreak: 'break-word'}}>
                                {t('translationDetails.noInvoice')}
                            </Box>
                        </Box>
                        <CloseIcon
                            sx={{cursor: isEditableWithFilesSelected ? 'pointer' : 'default'}}
                            onClick={isEditableWithFilesSelected ? removeNoFile : emptyFunction}
                        />
                    </Box>
                )}

                {/* TODO: fix fileList after opening this page if we have files */}
                {/* in this case it's an array of strings and we don't display them */}
                {fileList && details && fileList.map(file => {
                    if (!file || typeof file === 'string') {
                        return null;
                    }
                    return (
                        <TransactionFile
                            key={file.id}
                            file={file}
                            parentId={details.id}
                            type={details.type === SIDEBAR_TYPES.editRecurringTransfer ? 'TRANSFERS' : 'TRANSACTION'}
                            isEditable={isEditable || isRejectedOrCancelled}
                            isFileMenuOpen={fileMenuOpen}
                            setIsFileMenuOpen={setIsFileMenuOpen}
                            companyId={companyId}
                            freelancerId={freelancerId}
                            accountId={details?.bankAccountId}
                            isInCategorisation
                        />
                    );
                })}

                {error && details && error.map(file => {
                    if (!file || typeof file === 'string') {
                        return null;
                    }
                    return (
                        <TransactionFile
                            key={file.id}
                            file={file}
                            parentId={details.id}
                            type={file.type}
                            isEditable={isEditable || isRejectedOrCancelled}
                            isFileMenuOpen={fileMenuOpen}
                            setIsFileMenuOpen={setIsFileMenuOpen}
                            companyId={companyId}
                            freelancerId={freelancerId}
                            deleteNotUploadedFile={deleteErrorFile}
                            accountId={details?.bankAccountId}
                            isInCategorisation
                        />
                    );
                })}

                {fileUploadProcessingList.map(process => {
                    return (
                        <Box
                            key={process.eventId}
                            sx={{
                                'mb': 2,
                                'fontSize': theme => theme.typography.pxToRem(13),
                                'display': 'flex',
                                'justifyContent': 'space-between',
                                'alignItems': 'center',
                                'cursor': isEditable ? 'pointer' : 'auto',
                                'p': 1,
                                'backgroundColor': 'primary_v2.dark',
                                'color': 'white',
                                'borderRadius': '8px',
                            }}
                        >
                            <CircularProgress size={20} sx={{mr: 2, color: 'white'}} />
                            {process.eventId}
                        </Box>
                    );
                })}
            </>

            <TransactionDetailsNoFileModal />

            {hasPickerSection && !isRejectedOrCancelled && (
                <>
                    {hasFiles && (
                        <Typography sx={{
                            mt: 1,
                            mb: -1,
                            color: 'v2.blueGray.300',
                            fontSize: theme => theme.typography.pxToRem(12),
                            fontWeight: 500,
                            textTransform: 'uppercase',
                        }}
                        >
                            {t('categorisation.files.addDocument')}
                        </Typography>
                    )}
                    <Box sx={{
                        display: 'flex',
                        gap: 1,
                        alignItems: 'stretch',
                        justifyContent: 'space-between',
                    }}
                    >
                        <MenuItem
                            isEditable={isEditable}
                            Icon={CameraAltTwoToneIcon}
                            title={t('categorisation.files.scan')}
                            onClick={isEditable ? () => setIsQROpen(true) : () => {}}
                        />

                        <BankDropZone
                            fileTypes="image/jpeg, image/png, image/jpg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            maxFiles={BANK_DROPZONE_MAX_FILES.TRANSACTION} // 50
                            maxSize={BANK_DROPZONE_FILE_SIZES.TRANSACTION} // 10 Mb
                            onDropRejectedHandler={handleReject}
                            onChange={isEditable ? onFileSelect : emptyFunction}
                            isFileUploading={isFileUploading}
                            isDisabled={!isEditable}
                            isInCategorisation
                        >
                            <MenuItem
                                isEditable={isEditable}
                                isLoading={isFileUploading}
                                Icon={AttachmentIcon}
                                title={t('categorisation.files.attach')}
                            />
                        </BankDropZone>

                        <MenuItem
                            isNoFile
                            isEditable={isEditableWithFilesSelected}
                            Icon={DisabledByDefaultIcon}
                            title={t('categorisation.files.donthave')}
                            onClick={isEditableWithFilesSelected ? () => openNoFileModal() : () => {}}
                        />
                    </Box>
                </>
            )}

            <Box sx={{
                backgroundColor: 'gray_v2.50',
                borderRadius: '8px',
                height: '48px',
                py: 0.5,
                pl: 1.5,
                pr: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
            >
                <FormControlLabel
                    disabled={!isEditableWithFilesSelected || details?.ignoreProofDocument}
                    control={(
                        <Switch
                            checked={details?.refuseToShareProofDocuments}
                            onChange={onRefuseToShareProofDocumentsClick}
                        />
                    )}
                    label={t('categorisation.files.optionalLabel')}
                />

                <Box sx={{
                    height: '40px',
                    width: '40px',
                    backgroundColor: 'v2.light.action.hover',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
                >
                    <ConditionalTooltip
                        tooltip={t('categorisation.files.optionalTooltip')}
                    >
                        <HelpOutlineIcon sx={{
                            color: 'v2.blueGray.300',
                        }}
                        />
                    </ConditionalTooltip>
                </Box>
            </Box>
        </>
    );
};

TransactionFileSection.propTypes = {
    details: PropTypes.object.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isRejectedOrCancelled: PropTypes.bool.isRequired,
    saveTransaction: PropTypes.func.isRequired,
};

const MenuItem = ({Icon, title, onClick, isNoFile, isEditable, isLoading}) => {
    return (
        <Box
            sx={{
                p: 1,
                display: 'flex',
                gap: 0.75,
                alignItems: 'center',
                flexDirection: 'column',
                backgroundColor: isEditable
                    ? (isNoFile ? 'v2.light.secondary.shades8' : 'v2.light.primary.shades8')
                    : 'v2.light.other.disabled',
                borderRadius: 2,
                flex: '1 0 0',
                cursor: isEditable ? 'pointer' : 'default',
            }}
            onClick={onClick}
        >
            {isLoading ? (
                <CircularProgress size={24} />
            ) : (
                <Icon sx={{
                    color: isEditable
                        ? (isNoFile ? 'v2.light.error.main' : 'primary_v2.main')
                        : 'v2.light.text.disabled',
                    fontSize: '24px',
                }}
                />
            )}
            <Typography
                component="span"
                sx={{
                    fontSize: theme => theme.typography.pxToRem(12),
                    lineHeight: theme => theme.typography.pxToRem(18),
                    letterSpacing: '0.46px',
                    fontWeight: 500,
                    color: isEditable
                        ? (isNoFile ? 'error_v2.main' : 'v2.blueGray.600')
                        : 'v2.light.text.disabled',
                    textAlign: 'center',
                    flex: '1 0 0',
                    textTransform: 'uppercase',
                }}
            >
                {title}
            </Typography>
        </Box>
    );
};

MenuItem.propTypes = {
    Icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    isNoFile: PropTypes.bool,
    isEditable: PropTypes.bool,
    isLoading: PropTypes.bool,
};

MenuItem.defaultProps = {
    isNoFile: false,
    isEditable: true,
    isLoading: false,
    onClick: null,
};

export default TransactionFileSection;
