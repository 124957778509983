import EditIcon from '@mui/icons-material/Edit';
import {Alert, Grid, Modal, Typography, useMediaQuery} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React, {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import signaturePlaceholderImage from '../../../assets/png/signature-placeholder.png';
import {ButtonAction} from '../../../components/buttons/ButtonAction';
import CloseButton from '../../../components/buttons/CloseButton';
import {DropZone} from '../../../components/dropzone/DropZone';
import {ModalWrapper} from '../../../components/modal-wrapper/ModalWrapper';
import {useMemoizedCreateSelector} from '../../../hooks/use-memoized-create-selector';
import {importLocaleBundle} from '../../../lib/i18next';
import {useLoadingState} from '../../loading';
import {UiActions} from '../../ui/store/ui.action';
import {UiSelectors} from '../../ui/store/ui.selector';
import {ModalsKeys} from '../../ui/utils/constants';
import {SignatureActions} from '../store/signature.action';
import {SignatureSelectors} from '../store/signature.selector';

importLocaleBundle('signature');

const modalKey = ModalsKeys.UPLOAD_SIGNATURE;

export const UploadSignatureModal = () => {
    const dispatch = useDispatch();

    const isOpen = useMemoizedCreateSelector(UiSelectors.createIsModalActiveSelector, modalKey);
    const signatureUrl = useSelector(SignatureSelectors.selectUrl);

    const isLoading = useLoadingState(modalKey);

    const isMobileSize = useMediaQuery(`(max-width:960px)`);

    const [files, setFiles] = useState([]);

    const {t} = useTranslation('signature');

    const onChange = useCallback(newFiles => {
        setFiles(newFiles);

        if (newFiles.length) {
            dispatch(SignatureActions.uploadSignature(newFiles[0]));
        }
    }, [dispatch]);

    const handleClose = useCallback(() => {
        dispatch(UiActions.setActiveModal(modalKey, false));
    }, [dispatch]);

    if (!isMobileSize) {
        return (
            <ModalWrapper
                modalKey={modalKey}
                title={(
                    <Typography variant="h4">
                        {t('uploadSignatureModal.title')}
                    </Typography>
                )}
                buttons={({handleClose}) => (
                    <ButtonAction
                        startIcon={<EditIcon />}
                        disabled={isLoading || !signatureUrl}
                        color="secondary"
                        onClick={handleClose}
                        sx={{mt: 5}}
                    >
                        {t('uploadSignatureModal.continueButton')}
                    </ButtonAction>
                )}
                containerSx={{
                    width: '900px',
                }}
                buttonsSx={{
                    flexDirection: 'row',
                }}
            >
                <>
                    <Typography sx={{my: 2, fontSize: '1rem'}} color="text.disabled" variant="subtitle2">
                        {t('uploadSignatureModal.subTitle')}
                    </Typography>

                    <Box display="grid" gridTemplateColumns="minmax(0, 1fr) 335px" gap={3}>
                        <Box sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                            <Alert severity="info" sx={{mb: 1, borderRadius: '12px'}}>
                                {t('uploadSignatureModal.infoText')}
                            </Alert>
                            <DropZone
                                setIsOpen={true}
                                maxFiles={1}
                                fileTypes=".jpg, .png, .jpeg"
                                onChange={onChange}
                                isFileDeleteAvailable={false}
                                isUploadInProgress={isLoading}
                                isInfoTextVisible={true}
                                files={files}
                            />
                        </Box>

                        <Box
                            sx={{
                                width: '335px',
                                height: '335px',
                                border: '1px dashed #E0E0E0',
                                borderRadius: 3,
                                background: '#F5F5F5',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: 6,
                            }}
                        >
                            <img
                                style={{maxHeight: '335px'}}
                                src={signatureUrl ?? signaturePlaceholderImage}
                                width="100%"
                                alt="Signature"
                            />
                        </Box>
                    </Box>
                </>
            </ModalWrapper>
        );
    }

    return (
        <Modal open={isOpen} onClose={handleClose}>
            <Paper sx={{height: '100%', overflowY: 'auto'}}>
                <Grid
                    container
                    flexDirection="column"
                    alignContent="center"
                    sx={{p: 2, textAlign: 'left'}}
                >
                    <CloseButton
                        onClose={handleClose}
                        buttonStyles={{
                            position: 'absolute',
                            right: '24px',
                            top: '24px',
                        }}
                    />
                    <Grid item>
                        <Typography variant="h4">
                            {t('uploadSignatureModal.title')}
                        </Typography>
                    </Grid>

                    <Grid item sx={{pb: 3}}>
                        <Typography>
                            {t('uploadSignatureModal.subTitle')}
                        </Typography>
                    </Grid>

                    <Grid item sx={{p: 7, width: '100%', height: '335px', border: '1px dashed #E0E0E0', borderRadius: '12px', background: '#F5F5F5'}}>
                        <img
                            style={{maxHeight: '221px'}}
                            src={signatureUrl ?? signaturePlaceholderImage}
                            width="100%"
                            alt="Signature"
                        />
                    </Grid>

                    <Grid item>
                        <Box sx={{mt: 1}}>
                            <Grid container fullWidth>
                                <Grid item sx={{display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '100%'}}>
                                    <DropZone
                                        setIsOpen={true}
                                        maxFiles={1}
                                        fileTypes=".jpg, .png, .jpeg"
                                        onChange={onChange}
                                        isFileDeleteAvailable={false}
                                        isUploadInProgress={isLoading}
                                        isInfoTextVisible={true}
                                        files={files}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    {signatureUrl && (
                    <Grid item sx={{mt: 3}}>
                        <ButtonAction
                            startIcon={<EditIcon />}
                            disabled={!signatureUrl}
                            fullWidth
                            sx={{backgroundColor: 'v2.light.secondary.main'}}
                            onClick={handleClose}
                        >
                            {t('uploadSignatureModal.continueButton')}
                        </ButtonAction>
                    </Grid>
                    )}
                </Grid>
            </Paper>
        </Modal>
    );
};
