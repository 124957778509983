import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';
import {OnboardingHelper} from './onboarding-helper.saga';
import {OnboardingLoader} from './onboarding-loader.saga';
import {OnboardingProgress} from './onboarding-progress.saga';
import {OnboardingActions} from './onboarding.action';
import {OnboardingActionTypes} from './onboarding.action-type';
import {OnboardingSelectors} from './onboarding.selectors';
import {push, selectRouterLocation} from '../../../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../../../lib/router/route-paths';
import {isUserCare} from '../../../../../utils/user-roles';
import {getCompanySaga} from '../../../../../v1/app/company/company.sagas';
import {
    FULL_COMPANY_STATUSES,
} from '../../../../../v1/app/company/setupCompany/setupCompany.constants';
import {getIsRegistrationWithExistingCompany} from '../../../../../v1/utils';
import {BankActions} from '../../../../bank/store/bank.action';
import {getBankIntegrationsFlow} from '../../../../bank/store/bank.loader.saga';
import {BankSelector} from '../../../../bank/store/bank.selector';
import {DocumentApi} from '../../../../document/api/document.api';
import {DatabaseActions} from '../../../../document/modules/database/store/database.action';
import {loadDocuments} from '../../../../document/modules/database/store/database.saga';
import {DatabaseSelectors} from '../../../../document/modules/database/store/database.selector';
import {DocumentContexts} from '../../../../document/modules/database/utils/constants';
import {DocumentActions} from '../../../../document/store/document.action';
import {InsuranceActions} from '../../../../insurance/store/insurance.action';
import {InsuranceSelectors} from '../../../../insurance/store/insurance.selector';
import {LoadingActions, LoadingSelectors, LoadingTypes} from '../../../../loading';
import {loadSignature} from '../../../../signature/store/signature.saga';
import {LoggedInUserActions, LoggedInUserSelectors} from '../../../../user/modules/logged-in-user';
import {FreelancerSelectors} from '../../../store/freelancer.selector';
import {
    CapitalDepositInternalSubSteps,
    FinalizationSubsteps,
} from '../../capital-deposit/utils/constants';
import {CompaniesActions, CompaniesSelectors} from '../../companies';
import {getFreelancerCompaniesFlow, loadFreelancerCompanies} from '../../companies/store/companies.saga';
import {CompanyRegistrationInternalSubSteps} from '../../company-registration/utils/constants';
import {CompanySetupInternalSubSteps, CompanySetupSubSteps} from '../../company-setup/utils/constants';
import {WorkshopsInternalSubSteps} from '../../workshops/utils/constants';
import {OnboardingApi} from '../api/onboarding.api';
import {OnboardingSteps} from '../utils/onboadingStepsConstant';


const determineOnboardingStepAndSubStep = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);

    if (!loggedInUser) {
        return;
    }

    const progress = yield select(OnboardingSelectors.selectProgress);
    const freelancer = yield select(FreelancerSelectors.selectAccount);
    const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer?.defaultCompanyId));

    // They are not used all the time
    let deposit;
    let hasIntegration;
    let bankIntegrationDataList;
    let individualBankAccountHolders;
    let kycStatus;

    const registrationType = company?.enterpriseInformation?.registration_type;
    const registrationWithExistingCompany = getIsRegistrationWithExistingCompany(registrationType);

    yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, true));

    // Step 1
    if (OnboardingHelper.shouldUserLandOnWelcomeScreen(loggedInUser)) {
        yield call(OnboardingHelper.handleUserWelcomeScreen, {loggedInUser, progress});

        yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
        return;
    }

    if (OnboardingHelper.shouldUserLandOnSigningBatch1(loggedInUser)) {
        yield call(OnboardingHelper.handleUserSignedBatch1, {progress});

        yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
        return;
    }

    // Step 2
    if (OnboardingHelper.shouldUserLandOnWorkshopScreen({loggedInUser, company})) {
        yield call(OnboardingHelper.handleUserWorkshopScreen, {progress});

        yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
        return;
    }

    // Steps 3, 4 and 5
    // Company creation, capital deposit, company registration
    if (OnboardingHelper.isUserPendingCompany(loggedInUser)) {
        yield call(OnboardingProgress.setUserFinishedWorkshop, {progress});

        ({
            bankIntegrationDataList,
            deposit,
            hasIntegration,
            individualBankAccountHolders,
            kycStatus,
        } = yield call(OnboardingLoader.loadIntegrationKycAndDepositData, {loggedInUser}));

        let context;
        switch (company.status) {
            case FULL_COMPANY_STATUSES.PENDING_FINAL_SIGS:
                context = DocumentContexts.STAGE_TWO;
                break;
            case FULL_COMPANY_STATUSES.REGISTRATION_COMPLETED:
                context = DocumentContexts.DATABASE;
                break;
            default:
                context = DocumentContexts.SIGNABLE;
        }

        yield call(loadDocuments, {
            freelancerId: freelancer.id,
            companyId: company.id,
            context,
        });

        // Step 3
        yield call(
            OnboardingProgress.determineCompanySetupSubstep,
            {progress, company, freelancer},
        );

        // Step 4
        // Capital deposit step
        // If company is in required statuses,
        // and it is not registration with existing company go to capital deposit step
        if (
            OnboardingHelper.shouldUserLandOnCapitalDeposit({
                registrationWithExistingCompany,
                company,
                deposit,
            })
        ) {
            if (isUserCare(loggedInUser)) {
                yield call(OnboardingHelper.handleCareUserCompanyRegistration, {progress});

                yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
                return;
            }

            const {capitalDepositResult} = yield call(OnboardingHelper.handleUserCapitalDepositScreen, {
                freelancer,
                company,
                bankIntegrationDataList,
                kycStatus,
                progress,
                deposit,
                individualBankAccountHolders,
            });

            // Step 5
            // Company registration step
            if (capitalDepositResult?.data?.length > 0) {
                yield call(OnboardingHelper.handleUserCompanyRegistration, {progress, capitalDepositResult});

                yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
                return;
            }

            return;
        }

        // Step 6
        // Finalization screen
        // Care user pending screens
        if (OnboardingHelper.isCompanyRegistrationCompleted(company)) {
            yield call(OnboardingHelper.handleCompanyRegistrationCompleted, {
                progress,
                hasIntegration,
                freelancer,
                company,
                bankIntegrationDataList,
                loggedInUser,
            });
        }

        if (OnboardingHelper.isCompanyPendingFinalSigs(company)) {
            yield call(loadDocuments, {
                freelancerId: freelancer.id,
                companyId: company.id,
                context: DocumentContexts.SIGNABLE,
            });

            yield call(OnboardingHelper.handleCompanyPendingFinalSigs, {
                hasIntegration,
                progress,
            });
        }

        yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));

        return;
    }

    if (OnboardingHelper.isUserActive(loggedInUser)) {
        ({
            hasIntegration,
            individualBankAccountHolders,
        } = yield call(OnboardingLoader.loadIntegrationKycAndDepositData, {loggedInUser}));

        yield call(OnboardingHelper.handleUserActive, {
            progress,
            hasIntegration,
            individualBankAccountHolders,
            freelancer,
            company,
            registrationWithExistingCompany,
            loggedInUser,
        });

        yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
    }
};

// TODO This should not exist! This logic belongs on the buttons or in determineOnboardingStepAndSubstep!
const handleStepChangeWorker = function* ({payload: activeStep, redirectToOnboarding}) {
    const progress = yield select(OnboardingSelectors.selectProgress);
    const isOnboardingDataLoading = yield select(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.ONBOARDING),
    );
    const location = yield select(selectRouterLocation);

    if (isOnboardingDataLoading) {
        return;
    }

    const integrations = yield select(BankSelector.selectIntegrations);
    const hasIntegration = !!integrations?.[0];

    if (redirectToOnboarding && location.pathname !== RoutePaths.ONBOARDING) {
        yield put(push(RoutePaths.ONBOARDING));
    }

    if (activeStep === OnboardingSteps.WORKSHOPS) {
        if (progress[OnboardingSteps.WORKSHOPS].isCompleted) {
            yield put(OnboardingActions.setInternalSubStep(WorkshopsInternalSubSteps.FINAL_SCREEN));
        } else {
            yield put(OnboardingActions.setInternalSubStep(WorkshopsInternalSubSteps.INITIAL_SCREEN));
        }

        progress[OnboardingSteps.CONTRACT_SIGNING] = {
            ...progress[OnboardingSteps.CONTRACT_SIGNING],
            isCompleted: true,
        };

        yield put(OnboardingActions.setProgress(progress));
    } else if (activeStep === OnboardingSteps.COMPANY_SETUP) {
        // When a user is on sub step 3 for example, and clicks on "Create company" step title,
        // we should display initial screen
        // yield put(OnboardingActions.setSubStep(null));

        if (progress[OnboardingSteps.COMPANY_SETUP].isCompleted) {
            yield put(OnboardingActions.setInternalSubStep(CompanySetupInternalSubSteps.DATA_VALIDATED));
            yield put(OnboardingActions.setSubStep(CompanySetupSubSteps.DATA_VALIDATED));

            // Fetch bank integration data
            const bankIntegrationDataList = yield call(getBankIntegrationsFlow);

            // Store bank integration data
            yield put(BankActions.storeIntegrationData(bankIntegrationDataList));
        } else {
            const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
            const company = yield select(CompaniesSelectors.createCompanyByIdSelector(loggedInUser.defaultCompanyId));

            const registrationType = company?.enterpriseInformation?.registration_type;
            const registrationWithExistingCompany = getIsRegistrationWithExistingCompany(registrationType);

            // If documents are generated user can not go to other steps
            if (
                !registrationWithExistingCompany
                && company?.status === FULL_COMPANY_STATUSES.PENDING_INIT_SIGS) {
                yield delay(80);
                yield all([
                    put(OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_GENERATION)),
                ]);
            }

            // If user is pending document verification force him to that step
            if (
                !registrationWithExistingCompany
                && company?.status === FULL_COMPANY_STATUSES.READY_FOR_REVIEW) {
                yield delay(80);
                yield all([
                    put(OnboardingActions.setSubStep(CompanySetupSubSteps.DOCUMENT_GENERATION)),
                    put(OnboardingActions.setInternalSubStep(
                        CompanySetupInternalSubSteps.AWAITING_DOCUMENT_VERIFICATION,
                    )),
                ]);
            }

            // If user has verified documents but not confirmed force him to that screen
            if (
                !registrationWithExistingCompany
                && company?.status === FULL_COMPANY_STATUSES.DATA_VALIDATED) {
                yield delay(80);
                yield all([
                    put(OnboardingActions.setSubStep(CompanySetupSubSteps.DATA_VALIDATED)),
                    put(OnboardingActions.setInternalSubStep(CompanySetupInternalSubSteps.DATA_VALIDATED)),
                ]);
            }
        }
    } else if (activeStep === OnboardingSteps.CAPITAL_DEPOSIT) {
        yield put(OnboardingActions.setProgress({
            ...progress,
            [OnboardingSteps.CONTRACT_SIGNING]: {
                ...progress[OnboardingSteps.CONTRACT_SIGNING],
                isCompleted: true,
            },
            [OnboardingSteps.WORKSHOPS]: {
                ...progress[OnboardingSteps.WORKSHOPS],
                isCompleted: true,
            },
            [OnboardingSteps.COMPANY_SETUP]: {
                ...progress[OnboardingSteps.COMPANY_SETUP],
                isCompleted: true,
                subStepProgress: {},
            },
        }));

        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer.defaultCompanyId));

        const documents = yield select(DatabaseSelectors.selectDepositDocuments);
        if (!documents || documents.length === 0) {
            const result = yield call(DocumentApi.getDepositDocumentsRequest,
                {
                    freelancerId: freelancer.id,
                    companyId: company.id,
                });

            // Store deposit documents
            yield put(DocumentActions.storeDepositDocuments(result.data));
        }

        if (progress[OnboardingSteps.CAPITAL_DEPOSIT].isCompleted) {
            yield call(OnboardingLoader.loadCompanyInformation,
                {freelancerId: freelancer.id, companyId: company.id});
            yield put(OnboardingActions.setInternalSubStep(
                CapitalDepositInternalSubSteps.DEPOSIT_DOCUMENTATION_UPLOAD,
            ));
            yield put(OnboardingActions.setSubStep(CapitalDepositInternalSubSteps.DEPOSIT_DOCUMENTATION_UPLOAD));
        }
    } else if (activeStep === OnboardingSteps.COMPANY_REGISTRATION) {
        const freelancer = yield select(FreelancerSelectors.selectAccount);
        const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer.defaultCompanyId));

        if (company.status === 'REGISTRATION_COMPLETED') {
            yield put(OnboardingActions.setInternalSubStep(
                CompanyRegistrationInternalSubSteps.IBAN_BIC_FORM,
            ));
            yield put(OnboardingActions.setSubStep(CompanyRegistrationInternalSubSteps.IBAN_BIC_FORM));
        }

        if (company.status === 'PENDING_FINAL_SIGS' || company.status === 'ACTIVE') {
            if (hasIntegration) {
                yield put(OnboardingActions.setInternalSubStep(
                    CompanyRegistrationInternalSubSteps.IBAN_BIC_FORM,
                ));
                yield put(OnboardingActions.setSubStep(CompanyRegistrationInternalSubSteps.IBAN_BIC_FORM));
            } else {
                yield put(OnboardingActions.setInternalSubStep(
                    CompanyRegistrationInternalSubSteps.FILE_LIST,
                ));
                yield put(OnboardingActions.setSubStep(CompanyRegistrationInternalSubSteps.FILE_LIST));
            }
        }
    } else if (activeStep === OnboardingSteps.FINAL_POINTS) {
        const freelancer = yield select(LoggedInUserSelectors.selectLoggedInUser);
        const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer.defaultCompanyId));
        const insurance = yield select(InsuranceSelectors.selectInsurance);
        const isInsuranceLoading = yield select(LoadingSelectors.createLoadingSelectorByType(
            LoadingTypes.GET_INSURANCE,
        ));
        const isUserCareRole = isUserCare(freelancer);

        if (!isUserCareRole && !insurance && !isInsuranceLoading) {
            yield put(InsuranceActions.getInsurance({freelancerId: freelancer.id, companyId: company.id}));
        }

        // Get fresh company and user data if it is not
        if (company?.status && company.status !== 'ACTIVE') {
            yield call(loadFreelancerCompanies, {freelancerId: freelancer.id});
        }

        if (freelancer?.status && freelancer.status !== 'ACTIVE') {
            yield put(LoggedInUserActions.loadUser());
        }

        progress[OnboardingSteps.COMPANY_REGISTRATION] = {
            ...progress[OnboardingSteps.COMPANY_REGISTRATION],
            isCompleted: true,
        };
        progress[OnboardingSteps.CAPITAL_DEPOSIT] = {
            ...progress[OnboardingSteps.CAPITAL_DEPOSIT],
            isCompleted: true,
        };
        progress[OnboardingSteps.COMPANY_SETUP] = {
            ...progress[OnboardingSteps.COMPANY_SETUP],
            isCompleted: true,
        };

        if (company?.status && company?.status === FULL_COMPANY_STATUSES.PENDING_FINAL_SIGS) {
            progress[OnboardingSteps.FINAL_POINTS].subStepProgress = {
                [FinalizationSubsteps.PENDING_FINALIZATION]: {
                    isCompleted: true,
                },
            };
        }
        yield put(OnboardingActions.setProgress(progress));

        yield call(checkOnboardingFinalStatus, {freelancerId: freelancer.id, companyId: freelancer.defaultCompanyId});
    }
};

export const checkOnboardingFinalStatus = function* ({freelancerId, companyId}) {
    try {
        const data = yield call(OnboardingApi.checkOnboardingFinalStatus, {freelancerId, companyId});

        yield put(OnboardingActions.setOnboardingFinalStatus(data?.on_boarding_finalized_status));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});
    }
};

const resetCompanyFlow = function* ({freelancerId, companyId}) {
    try {
        const company = yield call(OnboardingApi.resetCompanySetupFlow, {
            freelancerId,
            companyId,
        });

        const companyEntities = yield select(CompaniesSelectors.selectEntities);

        const params = {freelancerId, companyId};
        yield all([
            put(CompaniesActions.storeEntities({
                ...companyEntities,
                [company.id]: company,
            })),
            put(DatabaseActions.storeDocuments({})),
            call(OnboardingLoader.loadPersonalInformation, params),
            call(OnboardingLoader.loadNonConvictionInformation, params),
            call(OnboardingLoader.loadCompanyInformation, params),
            call(OnboardingLoader.loadDocumentInformation, params),
            call(getCompanySaga, {
                payload: params,
            }),
            call(loadDocuments, {
                freelancerId,
                companyId: company.id,
                context: DocumentContexts.SIGNABLE,
            }),
        ]);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error({error});
    }
};

const activateCompanyFlow = function* ({freelancerId, companyId}) {
    try {
        yield call(OnboardingApi.activateCompany, {freelancerId, companyId});
    } catch (error) {
        // no-op
    }
};

const handleInternalSubStepChangeWorker = function* ({payload: activeInternalSubStep}) {
    const freelancer = yield select(FreelancerSelectors.selectAccount);
    const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer.defaultCompanyId));

    if (activeInternalSubStep === CompanyRegistrationInternalSubSteps.FILE_LIST) {
        yield put(DatabaseActions.clearDocuments());
        yield call(loadDocuments, {
            freelancerId: freelancer.id,
            companyId: company.id,
            context: DocumentContexts.STAGE_TWO,
        });
    }
};

const resetCompanySetupFlowWorker = function* () {
    const freelancer = yield select(FreelancerSelectors.selectAccount);
    const company = yield select(CompaniesSelectors.createCompanyByIdSelector(freelancer.defaultCompanyId));

    yield call(resetCompanyFlow, {
        freelancerId: freelancer.id,
        companyId: company.id,
    });

    yield put(OnboardingActions.setSubStep(CompanySetupSubSteps.PERSONAL_INFORMATION));
    yield put(OnboardingActions.setInternalSubStep(null));
};

export const loadOnboardingData = function* () {
    // Check if there is already freelancer sidebar
    const progress = yield select(OnboardingSelectors.selectProgress);
    let hasProgress = false;
    Object.values(progress).forEach(step => {
        if (step.isCompleted) {
            hasProgress = true;
        }
    });

    if (!hasProgress) {
        yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, true));

        yield call(determineOnboardingStepAndSubStep);
        yield call(loadSignature);
    }

    yield put(LoadingActions.setLoading(LoadingTypes.ONBOARDING, false));
};

export const loadCompanyInformationWorker = function* ({payload}) {
    const {freelancerId, companyId} = payload;

    yield call(OnboardingHelper.loadCompanyInformation, {freelancerId, companyId});
};

export const activateCompanyWorker = function* ({payload}) {
    const {freelancerId, companyId} = payload;

    yield call(activateCompanyFlow, {freelancerId, companyId});

    // Load new company information for proper sidebar
    yield call(getFreelancerCompaniesFlow, {freelancerId});

    yield delay(200); // TODO Check if needed
    // There is a listener in saga.js for getting new user and reddirecting to dashboard
    yield put(LoggedInUserActions.loadUser());
};

export const onboardingSaga = function* () {
    yield all([
        takeLatest(OnboardingActionTypes.SET_STEP, handleStepChangeWorker),
        takeLatest(OnboardingActionTypes.SET_INTERNAL_SUB_STEP, handleInternalSubStepChangeWorker),
        takeLatest(OnboardingActionTypes.RESET_COMPANY_SETUP_FLOW, resetCompanySetupFlowWorker),
        takeLatest(OnboardingActionTypes.LOAD_COMPANY, loadCompanyInformationWorker),
        takeLatest(OnboardingActionTypes.ACTIVATE_COMPANY, activateCompanyWorker),
        takeLatest(OnboardingActionTypes.DETERMINE_STEP_SUBSTEP, determineOnboardingStepAndSubStep),
    ]);
};
