import {Box, CircularProgress, Container} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {ContactSection} from './components/ContactSection';
import {IncomeSection} from './components/income/IncomeSection';
import {InputsSummary} from './components/InputsSummary';
import {LinkSection} from './components/LinkSection';
import {RoadmapSection} from './components/roadmap/RoadmapSection';
import {SimulationExpired} from './components/SimulationExpired';
import {Title} from './components/Title';
import {RevenueSimulatorActions} from '../../store/revenue-simulator.action';
import {RevenueSimulatorSelectors} from '../../store/revenue-simulator.selector';

export const RevenueSimulatorResults = ({isPublic}) => {
    const {t} = useTranslation('revenueSimulator');
    const {simulationId} = useParams();
    const dispatch = useDispatch();
    const simulation = useSelector(RevenueSimulatorSelectors.selectSimulation);

    useEffect(() => {
        dispatch(RevenueSimulatorActions.getResults(simulationId));
    }, [dispatch, simulationId]);

    if (!simulation) {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', mt: '40vh'}}>
                <CircularProgress />
            </Box>
        );
    }

    if (isPublic && simulation.isExpired) {
        return <SimulationExpired />;
    }

    const {firstName, lastName} = simulation.simulation.simulationInput;

    return isPublic ? (
        <Container maxWidth="xl" sx={{p: 3}}>
            <Title> {t('resultsFreelancerTitle', {firstName, lastName})}</Title>
            <InputsSummary />
            <IncomeSection />
            <RoadmapSection />
            <ContactSection />
        </Container>
    ) : (
        <>
            <Title isPublic={false}>{t('resultsAdminTitle')}</Title>
            <IncomeSection />
            <RoadmapSection />
            <LinkSection />
        </>
    );
};

RevenueSimulatorResults.propTypes = {
    isPublic: PropTypes.bool,
};

RevenueSimulatorResults.defaultProps = {
    isPublic: false,
};
