import {SseActionTypes} from './sse.action-types';

const storeBankSSEStatus = status => ({
    type: SseActionTypes.STORE_BANK_SSE_STATUS,
    payload: status,
});

const storeBankSSEEventSource = status => ({
    type: SseActionTypes.STORE_BANK_SSE_EVENT_SOURCE,
    payload: status,
});

const reconnectBankSSE = () => ({
    type: SseActionTypes.GET_BANK_TOKEN_AND_RECONNECT,
});


const storeInvoicingSSEStatus = status => ({
    type: SseActionTypes.STORE_INVOICE_SSE_STATUS,
    payload: status,
});

const storeInvoicingSSEEventSource = status => ({
    type: SseActionTypes.STORE_INVOICE_SSE_EVENT_SOURCE,
    payload: status,
});

const reconnectInvoicingSSE = () => ({
    type: SseActionTypes.GET_INVOICE_TOKEN_AND_RECONNECT,
});


const disconnectTokens = () => ({
    type: SseActionTypes.DISCONNECT_TOKENS,
});

export const SseActions = {
    storeBankSSEStatus,
    storeBankSSEEventSource,
    reconnectBankSSE,

    storeInvoicingSSEStatus,
    storeInvoicingSSEEventSource,
    reconnectInvoicingSSE,

    disconnectTokens,
};
