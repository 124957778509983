export const COOKIE_NAMES = {
    MOBILE_SESSION_COOKIE: 'mobileSessionCookie',
};

// eslint-disable-next-line
const escape = s => { return s.replace(/([.*+?\^$(){}|\[\]\/\\])/g, '\\$1'); };
export const getCookie = name => {
    const match = document.cookie.match(RegExp('(?:^|;\\s*)' + escape(name) + '=([^;]*)'));
    return match ? match[1] : null;
};

export const deleteCookie = name => {
    document.cookie = `${name}=; Max-Age=0;`;
};
