import {AccountingActionTypes} from './accounting.action-type';

export const AccountingActions = {
    downloadFECFile: params => ({
        type: AccountingActionTypes.DOWNLOAD_FEC_FILE,
        payload: params,
    }),
    updateFECFileDownloadLoader: params => ({
        type: AccountingActionTypes.DOWNLOAD_FEC_FILE_LOADER,
        payload: params,
    }),
    updateFinalFECFileUploadLoader: params => ({
        type: AccountingActionTypes.UPLOAD_FINAL_FEC_FILE_LOADER,
        payload: params,
    }),
    getJournalAccounts: data => ({
        type: AccountingActionTypes.GET_JOURNAL_ACCOUNTS,
        payload: data,
    }),
    storeJournalAccounts: data => ({
        type: AccountingActionTypes.STORE_JOURNAL_ACCOUNTS,
        payload: data,
    }),
    updateJournalAccounts: data => ({
        type: AccountingActionTypes.UPDATE_JOURNAL_ACCOUNTS,
        payload: data,
    }),
    getFinancialStatements: params => ({
        type: AccountingActionTypes.GET_FINANCIAL_STATEMENTS,
        payload: params,
    }),
    storeFinancialStatements: data => ({
        type: AccountingActionTypes.STORE_FINANCIAL_STATEMENTS,
        payload: data,
    }),
    downloadFinalFECFile: params => ({
        type: AccountingActionTypes.DOWNLOAD_FINAL_FEC_FILE,
        payload: params,
    }),
    uploadFinalFECFile: params => ({
        type: AccountingActionTypes.UPLOAD_FINAL_FEC_FILE,
        payload: params,
    }),
    toggleDepositOfAccountsMode: params => ({
        type: AccountingActionTypes.TOGGLE_DEPOSIT_OF_ACCOUNTS_MODE,
        payload: params,
    }),
    deleteFinalFECFile: params => ({
        type: AccountingActionTypes.DELETE_FINAL_FEC_FILE,
        payload: params,
    }),
    getBalanceSheetDetails: params => ({
        type: AccountingActionTypes.GET_BALANCE_SHEET_DETAILS,
        payload: params,
    }),
    storeBalanceSheetDetails: params => ({
        type: AccountingActionTypes.STORE_BALANCE_SHEET_DETAILS,
        payload: params,
    }),
    uploadBalanceSheet: params => ({
        type: AccountingActionTypes.UPLOAD_BALANCE_SHEET,
        payload: params,
    }),
    updateBalanceSheetDownloadLoader: params => ({
        type: AccountingActionTypes.STORE_LATEST_BALANCE_SHEET_DETAILS,
        payload: params,
    }),
    deleteLatestBalanceSheet: params => ({
        type: AccountingActionTypes.DELETE_LATEST_BALANCE_SHEET,
        payload: params,
    }),
    generateDOADocuments: params => ({
        type: AccountingActionTypes.GENERATE_DOA_DOCUMENTS,
        payload: params,
    }),
    regenerateAllDocuments: params => ({
        type: AccountingActionTypes.REGENERATE_ALL_DOCUMENTS,
        payload: params,
    }),
    regenerateSingleDocument: params => ({
        type: AccountingActionTypes.REGENERATE_SINGLE_DOCUMENT,
        payload: params,
    }),
    storeAllDocumentsGenerationProgress: params => ({
        type: AccountingActionTypes.STORE_ALL_DOCUMENTS_GENERATING_PROGRESS,
        payload: params,
    }),
    storeDocumentGenerationProgress: params => ({
        type: AccountingActionTypes.STORE_DOCUMENT_GENERATING_PROGRESS,
        payload: params,
    }),
    sendFormalitiesToINPI: params => ({
        type: AccountingActionTypes.SEND_FORMALITIES_TO_INPI,
        payload: params,
    }),
    updateINPISubmissionDetails: params => ({
        type: AccountingActionTypes.STORE_INPI_SUBMISSION_DETAILS,
        payload: params,
    }),
};
