import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import * as actions from './userList.actions';
import {UsersListActions} from './userList.actions';
import {selectRouterLocation} from '../../../lib/router/connected-router-saga';
import hiwayLocalStorage from '../../../v1/config/storage';
import {getUsersRequest} from '../api/user.api';

// TODO Move to const file after v1 -> v2
const usersSortValuesEnum = {
    fullName: 'name',
    email: 'email',
    fullCoachName: 'coach',
    role: 'role',
    status: 'status',
};
const storageKey = `users-savedRowsPerPage`;

const getUsersFlow = function* ({params}) {
    try {
        yield put(UsersListActions.setIsLoadingUserList(true));

        const users = yield call(getUsersRequest, params);

        yield put(UsersListActions.storeUsers(users?.data));
        yield put(UsersListActions.storeUsersNumber(users?.total));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    } finally {
        yield put(UsersListActions.setIsLoadingUserList(false));
    }
};

const getUsersWorker = function* () {
    const location = yield select(selectRouterLocation);

    const search = location?.query;

    const limit = search?.['users-rowsPerPage'] ?? (hiwayLocalStorage.has(storageKey)
        ? parseInt(hiwayLocalStorage.get(storageKey), 10)
        : 10);

    const hasSearchFilter = search?.['users-tabFilter'];

    const params = {
        limit: limit,
        offset: search?.['users-page'] ? parseInt(search['users-page'], 10) * limit : 0,
        sortBy: search?.['users-sortBy'] ? usersSortValuesEnum[search['users-sortBy']] : undefined,
        sortOrder: search?.['users-sortBy']
            ? search?.['users-sortDirection'] ? search?.['users-sortDirection'].toUpperCase() : 'DESC'
            : undefined,
        filterQuery: hasSearchFilter ? ({
            // coach_id: search?.['users-coachId'],
            // status: search?.['users-status'] && search?.['users-status'] !== USER_STATUSES.DEACTIVATED
            //     ? search?.['users-status']
            //     : undefined,
            // active: search?.['users-status'] === USER_STATUSES.DEACTIVATED ? false : undefined,
            role: search?.['users-tabFilter'] ? search?.['users-tabFilter'] : undefined,
        }) : undefined,
        searchQuery: search?.['users-searchTerm'],
        include: ['companies', 'accountManager'],
    };

    yield call(getUsersFlow, {params});
};

const getUsersSearchWorker = function* ({payload}) {
    const location = yield select(selectRouterLocation);

    const search = location?.query;

    const limit = search?.['users-rowsPerPage'] ?? (hiwayLocalStorage.has(storageKey)
        ? parseInt(hiwayLocalStorage.get(storageKey), 10)
        : 10);

    const params = {
        limit: limit,
        offset: search?.['users-page'] ? parseInt(search['users-page'], 10) * limit : 0,
        sortBy: search?.['users-sortBy'] ? usersSortValuesEnum[search['users-sortBy']] : undefined,
        sortOrder: search?.['users-sortBy']
            ? search?.['users-sortDirection'] ? search?.['users-sortDirection'].toUpperCase() : 'DESC'
            : undefined,
        searchQuery: payload,
        include: ['companies', 'accountManager'],
    };

    yield call(getUsersFlow, {params});
};

export const watchUserListSagas = function* watchUserListSagas() {
    yield all([
        takeLatest(actions.GET_USERS, getUsersWorker),
        takeLatest(actions.GET_USERS_SEARCH, getUsersSearchWorker),
    ]);
};
