import MessageIcon from '@mui/icons-material/Message';
import Button from '@mui/material/Button';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {CTASection} from './common/CTASection';
import {useSimulationData} from '../utils/useSimulationData';

export const ContactSection = () => {
    const simulation = useSimulationData();
    const {t} = useTranslation('revenueSimulator');

    return (
        <CTASection
            title={t('contactSection.title')}
            heading={t('contactSection.heading')}
            description={t('contactSection.description')}
            button={(
                <a href={`mailto:${simulation.coachEmail}`}>
                    <Button variant="contained" startIcon={<MessageIcon />} sx={{px: 2, py: 1, lineHeight: 1}}>
                        {t('contactSection.buttonText')}
                    </Button>
                </a>
            )}
        />
    );
};
