import {Skeleton as JoySkeleton} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useContext} from 'react';

const SkeletonLoadingContext = React.createContext({isLoading: false});

export const LoadingSkeletonProvider = props => {
    const parentContext = useContext(SkeletonLoadingContext);
    const isLoading = props.isLoading ?? parentContext.isLoading;
    return <SkeletonLoadingContext.Provider value={{isLoading}}>{props.children}</SkeletonLoadingContext.Provider>;
};

LoadingSkeletonProvider.propTypes = {
    isLoading: PropTypes.bool,
};

LoadingSkeletonProvider.defaultProps = {
    isLoading: undefined,
};

export const LoadingSkeleton = ({isLoading: isLoadingProp, placeholder, children, sx, ...props}) => {
    const context = useContext(SkeletonLoadingContext);
    const isLoading = isLoadingProp ?? context.isLoading;
    return (
        <JoySkeleton variant="inline" loading={isLoading} sx={{position: 'relative', ...sx}} {...props}>
            {isLoading && placeholder ? placeholder : children}
        </JoySkeleton>
    );
};

LoadingSkeleton.propTypes = {
    isLoading: PropTypes.bool,
    placeholder: PropTypes.node,
    variant: PropTypes.oneOf(['circular', 'inline', 'overlay', 'rectangular', 'text']),
    sx: PropTypes.object,
};

LoadingSkeleton.defaultProps = {
    isLoading: undefined,
    placeholder: undefined,
    variant: 'inline',
    sx: {},
};
