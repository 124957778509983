import {hiwayApiInstance} from '../../../lib/hiway-api-instance';

const putSignature = ({freelancerId, file}) => {
    const formData = new FormData();

    formData.append('file', file);

    return hiwayApiInstance
        .put(`/v1/freelancers/${freelancerId}/signature`, formData, {
            header: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(result => result.data);
};

const getSignature = ({freelancerId}) => {
    return hiwayApiInstance
        .get(`/v1/freelancers/${freelancerId}/signature`)
        .then(result => result.data);
};

export const SignatureApi = {
    putSignature,
    getSignature,
};
