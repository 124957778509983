import {
    faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Box, Input} from '@mui/joy';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {SidebarActions} from '../../store/sidebar.action';

export const Search = () => {
    const {t} = useTranslation('common');
    const dispatch = useDispatch();

    const handleSearch = e => {
        dispatch(SidebarActions.setSearchText(e?.target?.value));
    };

    return (
        <Input
            variant="soft"
            color="neutral"
            size="sm"
            placeholder={t('searchPlaceholder')}
            onChange={handleSearch}
            startDecorator={(
                <Box
                    color="text.icon"
                    fontSize="md"
                >
                    <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        fixedWidth
                    />
                </Box>
            )}
            sx={{
                mb: 2,
                py: 1,
                backgroundColor: 'background.body',
            }}
        />
    );
};
