
export const UserRoles = {
    FREELANCER: 'FREELANCER',
    CARE: 'CARE',
    // HPD-2013 - Hide coach/accountant
    // COACH: 'COACH',
    // ACCOUNTANT: 'ACCOUNTANT',
    ADMINISTRATOR: 'ADMINISTRATOR',
};

// It is the same as UserRoles, it should be standardised at some point
export const USER_ROLES = {
    FREELANCER: 'FREELANCER',
    CARE: 'CARE',
    // COACH: 'COACH', // HPD-2013 - Hide coach/accountant
    // ACCOUNTANT: 'ACCOUNTANT', // HPD-2013 - Hide coach/accountant
    ADMINISTRATOR: 'ADMINISTRATOR',
};

export const FULL_USER_ROLES = {
    FREELANCER: 'FREELANCER',
    COACH: 'COACH',
    ACCOUNTANT: 'ACCOUNTANT',
    ADMINISTRATOR: 'ADMINISTRATOR',
    CARE: 'CARE',
};

export const isUserCareOrFreelancer = user => {
    return user?.role === UserRoles.FREELANCER
        || user?.role === UserRoles.CARE;
};

export const isUserCare = user => {
    return user?.role === UserRoles.CARE;
};

// TODO: change colors adter start using JoyUI everywhere
export const UserRoleColors = {
    FREELANCER: '#E7604D', // 'roleColor.tech'
    CARE: '#C9B2F8', // 'roleColor.care'
    PORTAGE: '#C1EAD4', // 'roleColor.portage'
    ADMINISTRATOR: '#E7604D', // 'roleColor.admin'
};

export const UserRoleContrastColors = {
    FREELANCER: '#FBF3EA', // 'primary.solidColor'
    CARE: '#7B2329', // 'text.icon'
    PORTAGE: '#7B2329', // 'text.icon'
    ADMINISTRATOR: '#FBF3EA', // 'primary.solidColor'
};

export const userRoleLocalStorageKey = 'USER_ROLE_LOCAL_STORAGE';

export const userDefaultColor = UserRoleColors[UserRoles.ADMINISTRATOR];
export const userDefaultContrastColor = UserRoleContrastColors[UserRoles.ADMINISTRATOR];
