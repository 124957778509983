import {Box} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

export const DescriptionBlock = ({image, children}) => {
    return (
        <Box
            sx={{
                flex: 1,
                borderRadius: '8px',
                p: 2,
                backgroundColor: 'v2.gray.50',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Box sx={{width: '128px'}} component="img" src={image} />
            <Box flex={1}>{children}</Box>
        </Box>
    );
};

DescriptionBlock.propTypes = {
    image: PropTypes.string.isRequired,
};
